
<div class="load-catchment-container" *ngIf="!editCatchmentData && !deleteCatchmentData">
    <div class="title">
        <h2>{{'map.loadCatchment' | translate}}</h2>
        <mat-icon (click)="closePopup()">close</mat-icon>
    </div>
    <h4>{{'map.chooseSavedCatchment' | translate}}</h4>
    <div class="search-catchment" [ngClass]="{ 'nosearch': saveSearch.length === 0}" (scroll)="$event.stopPropagation()">
        <search-bar class="searchFilter" [MinCharSearch]=1 (SearchClick)='searchReport($event)' (close)="clearSelectedCatchment()"
        [Placeholder]="'reports.searchCatchment'" (click)="$event.stopPropagation()" [catchmentCloseBtn]="catchmentCloseBtn" [value]="selectedCatchment?.name"></search-bar>
        <div class="search-data" *ngIf="saveSearch.length">
            <ng-container *ngFor="let filter of saveSearch">
                <div class="search-list" (click)="catchmentSelected(filter)" [ngClass]="{'active' : selectedCatchment && selectedCatchment.id == filter.id }">
                    <span class="catchment-title">{{filter.name}}</span>
                    <p class="d-flex lc-datetime">
                        <span class="date">{{(filter.updated_date? filter.updated_date : filter.added_date) | date: 'MMM d, y'}}</span> 
                        <span class="date">{{(filter.updated_date? filter.updated_date : filter.added_date) | date: 'h:mm:ss'}}</span>
                    </p>
                    <div class="change-title">
                        <div class="edit" (click)="editCatchment(filter)"><i class="edit-icon"></i></div>
                        <div class="edit" (click)="deleteCatchment(filter)"><i class="delete-icon"></i></div>
                        
                        
                    </div>
                </div>
            </ng-container>
            
        </div>

        <div class="search-noFound"  *ngIf="!saveSearch.length">
            <span>{{'map.noSavedCatchmentFound' | translate}}</span>
        </div>

        
        
    </div>
    <div class="save-catchment">
        <button class="cancel-btn" (click)="closePopup()">{{'map.cancel' | translate}}</button>
        <button class="update-btn" [disabled]="!selectedCatchment" (click)="applySavedFilter(selectedCatchment)" >{{'map.load' | translate}}</button>
    </div>
</div>

    <!-- <div class="load-catchment-container edit-catchment-container" *ngIf="editCatchmentData">
        <div class="title">
            <h2>{{'map.editCatchmentName' | translate}}</h2>
            <i (click)="closePopup()"><mat-icon>close</mat-icon></i>
        </div>
        <h4>{{'map.enterNewCatchmentName' | translate}}</h4>
        <div class="edit-catchment">
            <h5>{{'map.name' | translate}}</h5>
            <input id="inputField" class="edit-inputField" #input [(ngModel)]="editCatchmentData.name">
        </div>
        <div class="save-catchment">
            <button class="cancel-btn" (click)="closePopup()">{{'map.cancel' | translate}}</button>
            <button class="update-btn" (click)="updateCatchment()">{{'map.save' | translate}}</button>
        </div>
    </div>
    
    <div class="load-catchment-container delete-catchment-container" *ngIf="deleteCatchmentData">
        <div class="title">
            <h2>{{'map.deleteCatchment' | translate}}</h2>
            <i (click)="closePopup()"><mat-icon>close</mat-icon></i>
        </div>
        <h4>{{'map.deleteCatchmentMsg' | translate}}</h4>
        
        <div class="save-catchment">
            <button class="cancel-btn" (click)="closePopup()">{{'map.cancel' | translate}}</button>
            <button class="update-btn" (click)="removeCatchment()">{{'map.delete' | translate}}</button>
        </div>
    </div> -->


