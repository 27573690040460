import { Component, HostListener, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { WarehouseRentalMenuEnum } from './report-warehouse-rental.enum';
import { WarehouseRentalReportConstant } from './report-warehouse-rental.constants';
import { SharedService } from 'src/app/common/services/shared.service';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { ReportWarehouseRentalService } from './report-warehouse-rental.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportService } from 'src/app/reports/services/report.service';
import { TranslateService } from '@ngx-translate/core';
import { StoreService } from 'src/app/common/services/store.service';
import { UnitTypeEnum } from '../../models/unit-type-enum';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-report-warehouse-rental',
  templateUrl: './report-warehouse-rental.component.html',
  styleUrls: ['./report-warehouse-rental.component.scss']
})
export class ReportWarehouseRentalComponent implements OnInit, OnChanges {
  RYDCHECK=environment.RYDCHECK;
  @Input() params: any;
  UnitTypeEnum=UnitTypeEnum;
  overViewMenu = WarehouseRentalReportConstant.overViewMenu;
  currentScroll: any = '';

  warehousePropertiesColumns = WarehouseRentalReportConstant.warehousePropertiesColumns;
  warehouseUnitsizeColumns = WarehouseRentalReportConstant.warehouseUnitsizeColumns;
  retailUnitsizeExpandColumns = WarehouseRentalReportConstant.retailUnitsizeExpandColumns;
  unitSizeDropdown = WarehouseRentalReportConstant.unitSizeDropdown;
  propertiesDropdown = WarehouseRentalReportConstant.propertiesDropdown;

  chartView = ReportsChartEnum;

  warehouseRentalMenuEnum = WarehouseRentalMenuEnum;
  placeName = '';
  userInfo: any = 0;
  placeCode: string = '';
  CurrencyCode: string = '';
  propertyInfo: any = {};
  isFirstTime = true;
  overViewLoading: boolean = false;
  overViewSummary: any = null;
  overViewSubCategory: any = null;

  retailUnitdata: any = [];
  retailUnitCount: number = 0;
  retailUnitLoading: boolean = false;
  retailRentalRentrateReport: any = null;

  warehouseUnitsizedata: any = [];
  retailUnitsizeCount: number = 0;
  warehouseUnitsizeLoading: boolean = false;

  houserentalChartLoading: boolean = false;
  houserentalChart: any = [];

  resetPaginator: boolean = false;
  currentPageIndex: number = 0;

  retailRentalChartLoading = false;
  overviewOccupancy: any[] = [];
  overviewAverageRate: any[] = [];
  overViewCategory: any = null;
  selectedChartValue: any[] = [];
  retailRentalChartReport: any[] = [];
  moreText: any;
  langName: any;
  loadRentObj: any;
  warehouseRentOption: string = 'warehouseRentOption';
  downloadLoading: any = {
    a: false,
    b: false
  };
  sqm_unit:string="";
  graphLoader: boolean=false;
  constructor(
    private sharedService: SharedService,
    private reportWarehouseRentalService: ReportWarehouseRentalService,
    private alertService: AlertService,
    public reportService: ReportService,
    public translate: TranslateService,
    private storeService: StoreService
  ) {
    this.sqm_unit = 'reports.'+GlobalContants.keySymbolContants.squareMeterUnit;
    this.sharedService.language$.subscribe(lang => {
      this.langName = lang;
      if (lang && !this.isFirstTime) {
        this.getReports();
      }
    })
  }

  menuChangeValue(menu: any) {
    this.currentScroll = menu.name;
    setTimeout(() => {
      this.scrollPoint(menu.name);
    })
  }

  scrollPoint(menu: string) {
    this.sharedService.scrollMenu(menu);
  }


  ngOnInit() {
    this.getReports();
    this.isFirstTime = false;
    this.userInfo = this.sharedService.getStoreValue(GlobalContants.StoreKey.userInfo);
    this.placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    this.CurrencyCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode);
    this.placeName = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceName);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber = 0;
      this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber = 0;

      this.propertyInfo = null;
      this.getReports();
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnload(): void {
    this.storeService.add(this.warehouseRentOption, this.selectedChartValue);
  }

  selectedYear(data: any) {

    if (data.reportType === 'unit') {
      this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber = 0;
      this.reportWarehouseRentalService.warehouseUnitProperties.year = data.year;
      this.retailUnitCount = 0;
      this.getWarehouseProperties();

    } else {
      this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber = 0;
      this.reportWarehouseRentalService.warehouseUnitSizePayload.year = data.year;
      this.retailUnitsizeCount = 0;
      this.getWarehouseUnitSize();
    }
  }

  getReports() {
    this.getOverviewSummary();
    this.getWarehouseUnitSize();
    this.getWarehouseProperties();
    this.getOverviewSubCategory();
    this.getWarehouseRentrateReport();
  }

  getOverviewSummary() {
    this.overViewLoading = true;
    this.reportWarehouseRentalService.getOverviewSummary().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.overViewSummary = this.reportWarehouseRentalService.formatOverViewSummary(res.data);

          } else {
            this.overViewSummary = null;
          }
          this.overViewLoading = false;
        },
        error: (error: any) => {
          this.overViewSummary = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getWarehouseRentrateReport() {
    this.retailRentalChartLoading = true;
    this.reportWarehouseRentalService.getWarehouseRentalRentrateReport().subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            this.retailRentalRentrateReport = this.sharedService.sortObjectByKey(res.data);
            let warehouseRentOption: any = this.storeService.get(this.warehouseRentOption);
            if(this.storeService && warehouseRentOption?.length > 0){
              this.selectedChartValue=warehouseRentOption;
            } else {
              // Select the first three keys by default
              this.selectedChartValue = Object.keys(this.retailRentalRentrateReport).slice(0, 3);
            }

             //transform the data
            Object.keys(this.retailRentalRentrateReport).forEach((key, index) => {
              let data = this.retailRentalRentrateReport[key];
              this.retailRentalRentrateReport[key] = data.map((obj: any) => ({
                  category: obj.year.toString(),
                  value: obj.count,
                  name: key
                }));
              });

              // Update the retail rental chart report
                this.retailRentalChartReport = Object.keys(this.retailRentalRentrateReport)
                  .filter(key => this.selectedChartValue.includes(key))
                  .reduce((obj: any, key: any) => {
                    obj[key] = this.retailRentalRentrateReport[key];
                    return obj;
                  }, {});

          } else {
            this.retailRentalRentrateReport = null;
          }
          this.retailRentalChartLoading = false;
        },
        error: (error: any) => {
          this.retailRentalRentrateReport = null;
          this.retailRentalChartLoading = false;
        }

    }
    );
  }

  getReportKeys(): string[] {
    if (this.retailRentalRentrateReport) {
      return Object.keys(this.retailRentalRentrateReport);
    }
    return [];
  }
  barSortData(data: any){
    let overviewArray: any = Object.entries(data);
    return overviewArray.sort((a: any, b: any) => b[1].category - a[1].category);
  }

  getOverviewSubCategory() {
    this.graphLoader=true;
    this.reportWarehouseRentalService.getOverviewSubCategory().subscribe(
      {
        next: (res: any) => {
          this.graphLoader=false;
          if (res.status === 200) {
            this.overViewCategory = { ...res.data };

            let overviewAverageRateData: any = { ...this.reportService.getPercentageOverview(this.overViewCategory.average_rate) };
            this.overviewAverageRate = this.barSortData(overviewAverageRateData);


            let overviewOccupancyData: any = this.reportService.getPercentageOverview(this.overViewCategory.occupancy);
            this.overviewOccupancy = this.barSortData(overviewOccupancyData);


          } else {
            this.overViewCategory = null;
            this.overviewAverageRate = [];
            this.overviewOccupancy = [];
          }

          this.overViewLoading = false;

        },
        error: (error: any) => {
          this.graphLoader=false;
          this.overViewCategory = null;
          this.overViewLoading = false;
        }
      }
    )
  }

  getWarehouseUnitSize() {
    this.warehouseUnitsizeLoading = true;
    this.reportWarehouseRentalService.getWarehouseUnitSize(this.reportWarehouseRentalService.warehouseUnitSizePayload).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            if(res.data){
              let cols = [
                'ambient_range',
                'air_conditioned_range',
                'frozen_chiller_range',
                'open_yard_range',
              ]

              res.data.map((el:any)=>{
                Object.keys(el).map((e:any)=>{
                  if(cols.includes(e)){
                    el[e] = this.reportService.rangeToNumberFor(el[e]);
                  }
                  if(el.details && el.details.length>0){
                    el.details.map((detail:any)=>{
                      Object.keys(detail).map((key:any)=>{
                        if(cols.includes(key)){
                          detail[key] = this.reportService.rangeToNumberFor(detail[key]);
                        }
                      })
                    })
                  }
                })
              })
            }
            this.warehouseUnitsizedata = res.data;
            if (this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber == 0) {
              this.retailUnitsizeCount = res.count;
              this.overViewMenu.map((e:any)=>{
                if(e.name == WarehouseRentalMenuEnum.retailUnitSize){
                  e['count'] = this.reportService.formatInternationNum(this.retailUnitsizeCount);
                }
              })
            }

          } else {
            this.warehouseUnitsizedata = [];
            this.retailUnitsizeCount = 0;
            if (this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber == 0) {
              this.overViewMenu.map((e:any)=>{
                if(e.name == WarehouseRentalMenuEnum.retailUnitSize){
                  e['count'] = 0;
                }
              })
            }
          }
          this.warehouseUnitsizeLoading = false;
        },
        error: (error: any) => {
          this.retailUnitdata = [];
          this.retailUnitsizeCount = 0;
          this.warehouseUnitsizeLoading = false;
          // if (this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber == 0) {
          //   this.overViewMenu.map((e:any)=>{
          //     if(e.name == WarehouseRentalMenuEnum.retailUnitSize){
          //       e['count'] = 0;
          //     }
          //   })
          // }
        }

      }
    )
  }


  getWarehouseProperties() {
    this.retailUnitLoading = true;
    this.reportWarehouseRentalService.getWarehouseProperties(this.reportWarehouseRentalService.warehouseUnitProperties).subscribe(
      {
        next: (res: any) => {
          if (res.status === 200) {
            //Range string convert to number
            res.data.map((el:any)=>{
              Object.keys(el).map((e:any)=>{
                if(['lease_rate_range'].includes(e)){
                  el[e] = this.reportService.rangeToNumberFor(el[e]);
                }
              })
            })
            this.retailUnitdata = res.data;
            if (this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber == 0) {
              this.retailUnitCount = res.count;
              this.overViewMenu.map((e:any)=>{
                if(e.name == WarehouseRentalMenuEnum.properties){
                  e['count'] = this.reportService.formatInternationNum(this.retailUnitCount);
                }
              })
            }

          } else {
            this.retailUnitdata = [];
            this.retailUnitCount = 0;
            if (this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber == 0) {
              this.overViewMenu.map((e:any)=>{
                if(e.name == WarehouseRentalMenuEnum.properties){
                  e['count'] = 0;
                }
              })
            }
          }
          this.retailUnitLoading = false;
        },
        error: (error: any) => {
          this.retailUnitdata = [];
          this.retailUnitCount = 0;
          this.retailUnitLoading = false;
          if (this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber == 0) {
            this.overViewMenu.map((e:any)=>{
              if(e.name == WarehouseRentalMenuEnum.properties){
                e['count'] = 0;
              }
            })
          }
        }

      }
    )
  }


  downloadExcel(event: any, type: string, key: any) {
    this.downloadRentalReport(type, key);
  }

  downloadRentalReport(type: string, key: any) {
    this.downloadLoading[key] = true;
    this.reportService.downloadReport(type, this.userInfo?.user_id).subscribe(
      {
        next: (res: any) => {
          this.downloadLoading[key] = false;
          if (res.status === 200) {
            const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${res.data}&city_code=${this.placeCode.toLowerCase()}&module_name=warehouse_rental`;
            this.sharedService.downloadExcelUrl(downloadUrl);
            this.alertService.success(this.translate.instant('reports.downloadRequestSubmitted'));

          } else {
            this.alertService.warn(res.message)
          }
        },
        error: (error: any) => {
          this.downloadLoading[key] = false;
        }
      }
    )
  }

  onPageEvent(e: any, report: any) {
    if (e.previousPageIndex !== e.pageIndex) {
      if (report === this.warehouseRentalMenuEnum.properties) {
        this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber = (e.pageSize * e.pageIndex);
        this.getWarehouseProperties();

      } else {

        this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber = (e.pageSize * e.pageIndex);
        this.getWarehouseUnitSize();
      }
    }

  }

  sortData(event: any, report: any) {
    let sort = {
      column: event.active,
      order: event.direction
    }
    if (report === this.warehouseRentalMenuEnum.properties) {
      this.reportWarehouseRentalService.warehouseUnitProperties.pageNumber = 0;
      this.reportWarehouseRentalService.warehouseUnitProperties.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getWarehouseProperties();
    } else {
      this.reportWarehouseRentalService.warehouseUnitSizePayload.pageNumber = 0;
      this.reportWarehouseRentalService.warehouseUnitSizePayload.sort_order = JSON.stringify(sort).replace(/[+]/g, '%2B');
      this.getWarehouseUnitSize();
    }
  }

  dropDownChange(value: any, type: any) {
    if(type === this.warehouseRentalMenuEnum.retailUnitSize){
      if (value === 'Range') {
        this.warehouseUnitsizeColumns = WarehouseRentalReportConstant.warehouseUnitsizeColumns;
        this.retailUnitsizeExpandColumns = WarehouseRentalReportConstant.retailUnitsizeExpandColumns;
      } else {
        this.warehouseUnitsizeColumns = WarehouseRentalReportConstant.retailUnitsizeAverageColumns;
        this.retailUnitsizeExpandColumns = WarehouseRentalReportConstant.retailUnitsizeExpandAverageColumns;
      }
    }else if(type === this.warehouseRentalMenuEnum.properties){
      if (value === 'Range') {
        this.warehousePropertiesColumns = WarehouseRentalReportConstant.warehousePropertiesColumns;
      } else {
        this.warehousePropertiesColumns = WarehouseRentalReportConstant.warehousePropertiesAverageColumns;
      }
    }
  }


  selectChart(e: any, value: any) {

    let index = this.selectedChartValue.indexOf(value)
    if (!e.checked) {
      if(this.selectedChartValue.length === 1){
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else{
          this.selectedChartValue.splice(index, 1);
      }
    } else {
      if (this.selectedChartValue.length > 2) {
        this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
        e.source.checked = false;
        return;
      } else{
      this.selectedChartValue.push(value)
    }
    }

    this.retailRentalChartReport = Object.keys(this.retailRentalRentrateReport)
      .filter(key => this.selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = this.retailRentalRentrateReport[key];
        return obj;
      }, {});
      this.retailRentalChartReport = this.sharedService.sortObjectByKey(this.retailRentalChartReport);
    this.retailRentalChartLoading = false;
    setTimeout(() => {
      let firstEleCount: any = document.querySelector('.setext')?.textContent?.split(',')[0].length;
      if(this.selectedChartValue.length == 2 && firstEleCount > 6){
        this.moreText = `(1) ${this.translate.instant('reports.more')}`;
        } else if(this.selectedChartValue.length == 3 && firstEleCount > 6){
          this.moreText = `(2) ${this.translate.instant('reports.more')}`;
        }else if(this.selectedChartValue.length == 2 && firstEleCount < 6){
          this.moreText = '';
        }else{
          this.moreText = `(1) ${this.translate.instant('reports.more')}`;
        }
    },1)
  }



  // getOpacity(index:number, value: any, type: string): string{
  //   // Adjust the factor and starting value as per your requirement
  //   return this.reportWarehouseRentalService.changeOpacity(index, value, type);
  // }
  // getNumber(nbr: any){
  //   return +nbr;
  // }

  ngOnDestroy() {
    this.storeService.clear(this.warehouseRentOption);
  }

}
