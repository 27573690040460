import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { AlertService } from '../../../../common/components/alert/alert.service';
import { GlobalContants } from '../../../../common/constants/global.contants';
import { SharedService } from '../../../../common/services/shared.service';
import { StoreService } from '../../../../common/services/store.service';

import { ReportService } from '../../../../reports/services/report.service';
import { CatchmentModelComponent } from '../draw-catchment/catchment-model/catchment-model.component';

@Component({
  selector: 'app-load-catchment',
  templateUrl: './load-catchment.component.html',
  styleUrls: ['./load-catchment.component.scss'],
  
})
export class LoadCatchmentComponent implements OnInit {

  @Output() closeLoadCatchment = new EventEmitter();
  
  saveSearch: any = [];
  editCatchmentData: any = null;
  deleteCatchmentData: any = null;  
  selectedCatchment: any = null;  
  activeRoute: Subscription = new Subscription();
  queryParams: any;
  queryParamsFirst: any;
  catchmentCloseBtn: boolean = false

  constructor(
    private reportService: ReportService,
    private activatedRoute: ActivatedRoute,
    public storeService: StoreService,
    private router: Router,
    private sharedService: SharedService,
    private dailog: MatDialog,
    private alertService : AlertService
  ){

  }

  ngOnInit(): void {
    this.searchReport();
    this.getAppliedFilter()
  }

  ngAfterViewInit (): void {
    const overlayDiv:any = document.querySelector('.load-catchment-container');
    overlayDiv.addEventListener('wheel', function (event:any) {
      event.stopPropagation();
    });
  }

  getAppliedFilter() {
    this.activeRoute = this.activatedRoute.queryParams.subscribe((params: any) => {
      this.queryParams = params;
    })
  }

  searchReport(event: any = ''){
    this.reportService.savedFilterList(event).subscribe((data: any) => {
      this.saveSearch = data.data;
      this.reportService.savedCatchmentList = data.data;
    });
  }

  editCatchment(data: any){
   // this.editCatchmentData = data;
   this.closeLoadCatchment.emit(false)
    this.dailog.open(CatchmentModelComponent, {
      disableClose: true,
      panelClass: 'edit-catchment',
      data: {
        type: 'edit-catchment',
        data: data
      }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
       // this.revertLayer()
      }
    })
  }

  deleteCatchment(data: any){
    this.closeLoadCatchment.emit(false)
    this.dailog.open(CatchmentModelComponent, {
      disableClose: true,
      panelClass: 'edit-catchment',
      data: {
        type: 'delete-catchment',
        data: data
      }
    }).afterClosed().subscribe((res: any) => {
      if (res) {
       // this.revertLayer()
      }
    })
  }

  catchmentSelected(data: any){
    this.selectedCatchment = data;
    this.catchmentCloseBtn = true;
  }

  clearSelectedCatchment(){
    this.selectedCatchment = null;
    this.catchmentCloseBtn = false;
  }

  closePopup(){
   this.closeLoadCatchment.emit(false)
  }

  updateCatchment(){
    let payload = {
      filter_type: "filter",
      filter_data: this.editCatchmentData.data,
      filter_name: this.editCatchmentData.name
    }
    this.reportService.updateSavedFilter(payload, this.editCatchmentData).subscribe({
      next: (res: any) => {
        if (res && res?.status == 200) {
          this.alertService.success(res.message)
         
        } else {
          this.alertService.warn(res.message)
          
        }
       this.closeLoadCatchment.emit(false)
      },
      error: (err: any) => {
        this.alertService.error(err.message)
      }
    });
  }

  removeCatchment(){
    this.reportService.deleteFilter(this.deleteCatchmentData.id).subscribe({
      next: (res:any) => {
        if(res) {
          this.alertService.success(res.message);
          this.sharedService.savedFilter = this.sharedService.savedFilter.filter((id) => id != this.deleteCatchmentData.id.id);
          this.closeLoadCatchment.emit(false)
        }
      },
      error: (err: any) => {
        this.alertService.error(err.message)
        this.closeLoadCatchment.emit(false)
       }
    });
  }

  /**
   * This function is used to apply the saved filter in report
   * @param savedFilter store the data related to saved filter which we want to apply.
   */
  applySavedFilter(savedFilter: any) {
    this.storeService.addSessionData("filter", savedFilter)

    // let savedFilterData = savedFilter.data.filter;
    let queryParams: any = {...this.queryParams}
    // this.reportService.reportFilter.forEach((ele: any) => {
    //   if (savedFilterData && savedFilterData.hasOwnProperty(ele.parameter_name))
    //     queryParams[ele.parameter_name] = savedFilterData[ele.parameter_name]
    // });
    // if (savedFilterData['centroid'])
    //   queryParams['centroid'] = savedFilterData['centroid'];
    // if (savedFilterData['zoom'])
    //   queryParams['zoom'] = savedFilterData['zoom'];
    // if (this.queryParams['srid'])
    //   queryParams['srid'] = savedFilterData['srid'];
    if (savedFilter.data['catchment']) {
      queryParams['polygon'] = JSON.stringify(savedFilter.data['catchment'])
    }
    // queryParams = {
    //   "oid": this.queryParams['oid'],
    //   "gid": this.queryParams['gid'],
    //   "rid": this.queryParams['rid'],
    //   ...queryParams
    // }
    this.queryParamsFirst = queryParams
    this.storeService.addSessionData("queryParamsFirst", this.queryParamsFirst)
    // this.selectedSaveFilter = queryParams;
    this.router.navigate(
      [`${this.sharedService.CityLang}/${GlobalContants.Routes.Reports}`],
      { queryParams: queryParams }
    );
   // this.dialogRef?.close();
   this.closeLoadCatchment.emit(false)
  }
}
