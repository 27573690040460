import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import * as pbi from 'powerbi-client';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { StoreService } from 'src/app/common/services/store.service';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-pbi-visual-container',
  templateUrl: './pbi-visual-container.component.html',
  styleUrls: ['./pbi-visual-container.component.scss']
})
export class PbiVisualContainerComponent {

  @Input() itemId: any;
  @Input() main_data: any;
  @Input() embedData: any;
  @Input() params: any;
  @Input() catchmentCount: number = 0
  @Input() propertyUid: any = null;

  visuals: any[] = [];
  visualsGrFilter: any[] = [];
  powerBiReport: any;
  isLoader: boolean = true;

  @ViewChild('visualOne', { static: false }) visualOne!: ElementRef;
  powerbiService: any;
  
  constructor(private reportService: ReportService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['params'] && changes['params'].currentValue && !changes['params'].firstChange) {
      this.getVisualFilter();
    }
    if (changes['embedData'] && changes['embedData'].currentValue && !changes['embedData'].firstChange) {
      if(!this.powerbiService){
        this.powerbiService = new pbi.service.Service(pbi.factories.hpmFactory, pbi.factories.wpmpFactory, pbi.factories.routerFactory);
        this.embedVisuals(this.powerbiService, this.embedData.embedUrl, this.embedData.token);
      }else{
        this.getVisualFilter();
      }
    }
    if (changes['catchmentCount'] && changes['catchmentCount'].currentValue) {
      this.getVisualFilter();
    }
  }

 async getVisualFilter() {
    //Basics filter
    let basicFilter: any = await this.reportService.createFilterJson(this.params, this.catchmentCount, this.propertyUid);
    // Filter out any objects where values are empty or undefined
    // basicFilter = basicFilter.filter((filter: any) => filter.values && filter.values.length > 0);
    this.visualsGrFilter.forEach(visual => {
      visual.setFilters(basicFilter);
    });
  }

  embedVisuals(powerbiService: any, embedUrl: any, accessToken: any) {
    this.visualOne.nativeElement.style.display = 'none';
    // Create configuration for each visual
    let visualConfig:any = {
     
      id: this.main_data.pb_report_id,
      embedUrl: embedUrl,
      tokenType: models.TokenType.Embed,
      accessToken: accessToken,
      pageName:  this.main_data.pb_page_id, // "ff4e7fd69b75a04602f1", // Name of the page
     
      settings: {
        filterPaneEnabled:false,
        panes: {
          pageNavigation: { visible: false },
          filters: { visible: false, expanded: false },
          bookmarks: { visible: false },
          visualizations: { visible: true, expanded: false },
          fields: { visible: true, expanded: false },
          selection: { visible: false },
          syncSlicers: { visible: false },
        }
      }
    };
    // 'visual' for showing visual and 'report' for report data 
    visualConfig['type'] = this.itemId?.visualId ? 'visual' : 'report';
    if(this.itemId){     
      visualConfig['visualName'] = this.itemId.visualId;
    }

    // Embed the visual
    this.powerBiReport = powerbiService.embed(this.visualOne.nativeElement, visualConfig);
    this.visualsGrFilter.push(this.powerBiReport);

    // Set up an event listener for the 'loaded' event
    this.powerBiReport.on('loaded', async () => {
      this.isLoader = false
      this.visualOne.nativeElement.style.display = 'block';
      this.getVisualFilter();
    });

  }


  convertCommaFilter(name: any) {
    if (name) return name.split(',');
  }


}
