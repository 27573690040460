import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as pbi from 'powerbi-client';
import { IReportEmbedConfiguration, models, Page, Report, service, Embed } from 'powerbi-client';
import { Subscription } from 'rxjs';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-power-bi-property-details',
  templateUrl: './power-bi-property-details.component.html',
  styleUrls: ['./power-bi-property-details.component.scss']
})
export class PowerBiPropertyDetailsComponent {

  @Input() property:any;

  public params: any;
  public currentScroll = 0;
  public PowerBIData: any;
  public embedData: any;
  public catchmentCount: number = 0;
  loader: boolean=false;
  currentLang: string = '';
  languageSub$: Subscription = new Subscription();
  
    constructor(
      private sharedService: SharedService,
      public translate: TranslateService,
      public reportService: ReportService,
      private route: ActivatedRoute
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
     this.getPropertyConfig();
    }

    setLang(): void {
      this.languageSub$ = this.sharedService.language$.subscribe(lang => {
        this.currentLang = lang;
      })
    }

    async getPropertyConfig(){
      this.loader=true;
      let propertydata:any = await this.reportService.getPropertyConfigAPI();
      if(this.params['property_category'] && this.property?.property_category && propertydata && propertydata.length > 0){
        let proData:any;
        proData = propertydata.find((e:any)=> (this.property?.property_category.includes(e?.property_category) && this.params['property_category'].includes(e?.property_category)))
        if(proData){
          this.loader=false;
          this.PowerBIData = {...proData};
          this.loadVisualReport(); 
        }else{
          this.PowerBIData = null;
        }
        this.loader=false;
      }else{
        this.PowerBIData = null;
        this.loader=false;
      }
    }
    ngOnInit(): void {
      this.setLang();
      //Get Catchment Count
      this.route.queryParams.subscribe(param => {
        this.params = param;
        // if (param.hasOwnProperty('polygon')) {
        //   this.getCatchmentCount();
        // }
      })
    }
  //Get Catchment for draw catchment
  // getCatchmentCount() {
  //   this.reportService.getCatchmentproperties().subscribe((res: any) => {
  //     if (res.status === 200) {
  //       this.catchmentCount = res.data;
  //     }
  //   });
  // }

    loadVisualReport(){
      this.loader = true;
      this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id)
        .subscribe({
          next: (res: any) =>{
            this.loader = false;
            this.embedData = res.data;
          },
          error: (err)=>{
            this.loader = false;
          }
        })
    }


    menuChangeValue(menu: any) {
      this.currentScroll = menu.name;
      setTimeout(() => {
        this.scrollPoint(menu.name);
      })
    }

    scrollPoint(menu: string) {
      this.sharedService.scrollMenu(menu);
    }

    backToReport(){
      this.reportService.transactionPropertyDetails.next(false);
    }
    ngOnDestroy(): void {
      this.languageSub$?.unsubscribe()
    }
}
