import { Component, ElementRef, Input, SimpleChanges, ViewChild } from '@angular/core';
import { ReportService } from 'src/app/reports/services/report.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportMapService } from 'src/app/reports/services/report-map.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-report-power-bi-full-without-map',
  templateUrl: './report-power-bi-full-without-map.component.html',
  styleUrls: ['./report-power-bi-full-without-map.component.scss']
})
export class ReportPowerBiFullWithoutMapComponent {
  
    @Input() params:any;
    currentScroll=0;
    
    visualLoader: boolean =  false;
    overViewMenu:any=[];
    PowerBIData: any;
    embedData: any;
    catchmentCount: number = 0;
    constructor(
      private sharedService: SharedService,
      public translate: TranslateService,
      public reportService: ReportService,
      private mapService: ReportMapService,
      private route: ActivatedRoute
    ) {
      
      this.overViewMenu = []
      
      // this.PowerBIData.pb_container.map((e:any)=>{
      //   this.overViewMenu.push({
      //     label: 'reports.overview',
      //     name: e.sort_order,
      //     count: null
      //   })
      // })
      
    }
    
    ngOnInit(): void {
      if(this.reportService.getCurrentReport() && this.reportService.getCurrentReport().powerbi_data){
        this.PowerBIData = this.reportService.getCurrentReport().powerbi_data;
        // this.PowerBIData.pb_container.sort((a:any, b:any) =>  (Number(a.sort_order) - Number(b.sort_order)));  
        this.loadVisualReport(); 
      }
      //Get Catchment Count
      this.route.queryParams.subscribe((param:any) => {
        if (param.hasOwnProperty('polygon')) {
          this.getCatchmentCount(param.polygon);
        }
      })
    }
  //Get Catchment for draw catchment
  getCatchmentCount(polygon:any) {
    this.reportService.getCatchmentproperties(polygon).subscribe((res: any) => {
      if (res.status === 200) {
        this.catchmentCount = res.data;
      }
    });
  }
  
    loadVisualReport(){
      this.visualLoader = true;
      this.reportService.powerBiFecthReport(this.PowerBIData.pb_group_id, this.PowerBIData.pb_report_id)
        .subscribe({
          next: (res: any) =>{
            this.embedData = res.data;
            this.visualLoader = false;
          },
          error: (err)=>{
            this.visualLoader = false;
          }
        })
    }
    
    
    menuChangeValue(menu: any) {
      this.currentScroll = menu.name;
      setTimeout(() => {
        this.scrollPoint(menu.name);
      })
    }
    
    scrollPoint(menu: string) {
      this.sharedService.scrollMenu(menu);
    }
    
}
