<app-child-breadcum [property_uid]="property.property_uid" (back)="backToReport()"></app-child-breadcum>
<!-- Back Button -->
<!-- <button (click)="backToReport()" [ngClass]="{ 'pbi-prodetail-backbtn-ar' : currentLang === 'ar'}"
class="pbi-prodetail-backbtn"><mat-icon>keyboard_arrow_left</mat-icon>
<span>{{'reports.backToReport'|translate}}</span> </button> -->

<div class="items" *ngIf="loader">
    <div class="item-card">
        <div class="page-loader d-flex align-items-center justify-content-center">
            <img class="vsloader" style="width: 80px;" src="assets/images/loading.gif" alt="">
        </div>
     </div>
</div>
<div class="items" *ngIf="PowerBIData">
    <div class="item-card">
        <app-pbi-visual-container [params]="params" [embedData]="embedData" [main_data]="PowerBIData"
            [catchmentCount]="catchmentCount" [propertyUid]="property?.property_uid"></app-pbi-visual-container>
    </div>
</div>
<ng-container *ngIf="!PowerBIData && !loader">
    <app-property-details-overview [Property_Uid]="property?.property_uid"></app-property-details-overview>
</ng-container>