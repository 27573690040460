<ng-container *ngIf="data.type === 'reset'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
        data: {
            name: ('map.resetCatchment' | translate),
            msg: ('map.resetCatchmentMsg' | translate),
            saveBtn: ('map.reset' | translate)
        }
    }"></ng-container>
</ng-container>

<ng-container *ngIf="data.type == 'revert'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
    data: {
        name: ('map.revertOriginal' | translate),
        msg: ('map.revertCatchmentMsg' | translate),
        saveBtn: ('map.revert' | translate)
    }
}"></ng-container>
</ng-container>

<ng-container *ngIf="data.type == 'load'">
    <ng-container *ngTemplateOutlet="catchmentModel; context:{
    data: {
        name: ('map.loadCatchment' | translate),
        msg: ('map.loadCatchmentMsg' | translate),
        saveBtn: ('map.continue' | translate)
    }
}"></ng-container>
</ng-container>


<ng-template #catchmentModel let-data="data">
    <div class="container-model">
        <div class="reset-catchment-container">
            <div class="title">
                <h2>{{ data.name }}</h2>
                <i (click)="closePopup(false)"><mat-icon>close</mat-icon></i>
            </div>
            <h4>{{ data.msg }}</h4>

            <div class="save-catchment">
                <button class="cancel-btn" (click)="closePopup(false)">{{'map.cancel' | translate}}</button>
                <button class="update-btn" (click)="closePopup(true)">{{ data.saveBtn }}</button>
            </div>
        </div>
    </div>

</ng-template>

<div class="load-catchment-container edit-catchment-container" *ngIf="data.type == 'edit-catchment'">
    <div class="title">
        <h2>{{'map.editCatchmentName' | translate}}</h2>
        <i (click)="closePopup(false)"><mat-icon>close</mat-icon></i>
    </div>
    <h4>{{'map.enterNewCatchmentName' | translate}}</h4>
    <div class="edit-catchment">
        <h5>{{'map.name' | translate}}</h5>
        <input id="inputField" class="edit-inputField" #input [(ngModel)]="catchmentData.name" maxlength="20">
        <div class="hint"><mat-hint>{{catchmentData.name.length}}/20</mat-hint></div>
    </div>
    <div class="save-catchment">
        <button class="cancel-btn" (click)="closePopup(false)">{{'map.cancel' | translate}}</button>
        <button class="update-btn" (click)="updateCatchment()" [disabled]="checTextValue(catchmentData.name) === ''">{{'map.save' | translate}}</button>
    </div>
</div>

<div class="load-catchment-container delete-catchment-container" *ngIf="data.type == 'delete-catchment'">
    <div class="title">
        <h2>{{'map.deleteCatchment' | translate}}</h2>
        <i (click)="closePopup(false)"><mat-icon>close</mat-icon></i>
    </div>
    <h4>{{'map.deleteCatchmentMsg' | translate}}</h4>
    
    <div class="save-catchment">
        <button class="cancel-btn" (click)="closePopup(false)">{{'map.cancel' | translate}}</button>
        <button class="update-btn" (click)="removeCatchment()">{{'map.delete' | translate}}</button>
    </div>
</div>
