<div class="filter-section">
    <div class="custom-map"  *ngIf="Disabled">Custom map applied</div>
    <label [class.mt-2]="Disabled" for="">{{name | langtranslate}} <span *ngIf="this.filterData.required" class="required">*</span></label>
    <div *ngIf="filterData.type == 'multi_select'">
      <ng-container *ngIf="this.DataList.length > 6">
        <mat-select panelClass="custom-height" multiple class="form-control" [disabled]="Disabled"
         [formControl]="selectMultiForm" #multiSelect>
         <mat-option class="search-sticky">
          <mat-icon>search</mat-icon>
          <ngx-mat-select-search [class.select-search-disable]="!atLeastOneSubscription" [disableInitialFocus]="disableInitialFocus" [formControl]="searchFilter"
            placeholderLabel="{{'reports.search'|translate}}"
            noEntriesFoundLabel="No data found"></ngx-mat-select-search></mat-option>
          <div class="select-all" *ngIf="!searchFilter.value">
            <mat-checkbox [disabled]="!selectAllSubscription" [labelPosition]="'after'" (change)="setAll($event.checked)"
             [checked]="selectMultiForm.value.length == DataList.length"> {{'reports.un_select_all' | translate}} </mat-checkbox>
             <i *ngIf="!selectAllSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
          </div>
            <mat-option *ngFor="let obj of filteredValue | async;  trackBy: findId" [value]="obj.val" [disabled]="!obj?.isSubscription" [class.seoption-disable]="!obj?.isSubscription">
              <span *ngIf="filterData.parameter_name !== 'neigh_name'">{{obj.label | langtranslate}}</span>
              <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{getNeighLabelFromLocal(obj.label[1])}}</span> -->
              <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[lang]?obj.label[lang]:obj.label[1]}}</span>
              <i *ngIf="!obj?.isSubscription" class="filterlock">
                <img src="assets/images/lock-gray.svg" alt="">
              </i>
            </mat-option>
        </mat-select>
        
        <div class="selected-value">
            <mat-chip-set #chipGrid aria-label="selection1">
              <ng-container *ngFor="let val of getSliceItem()">
                  <mat-chip-row (removed)="remove(val)" [disabled]="Disabled || getDisableChip(val)">
                    {{getFilterLable(val)}}
                    <button matChipRemove [attr.aria-label]="'remove ' + val">
                      <mat-icon>cancel</mat-icon>
                    </button>
                  </mat-chip-row>
                </ng-container>
              </mat-chip-set>
        </div>
        <mat-icon
              class="load-more" (click)="loadMoreSelectedVal()"
              *ngIf="selectMultiForm.value.length > 5" [matTooltip]="loadMore? (lang == 2 ? 'أقل' : 'Less'):(lang == 2 ? 'أكثر' : 'More') "
              >compare_arrows</mat-icon>
      </ng-container>
      <div *ngIf="this.DataList.length <= 6">
        <span (click)="selectChips(obj.val)" class="filter-chips" [class.disabled-chip]="Disabled" [class.disable-value]="!obj?.isSubscription"  [class.selected]="checkSelected(obj.val)" *ngFor="let obj of filteredValue | async">
          <span *ngIf="filterData.parameter_name !== 'neigh_name'">{{obj.label | langtranslate}}</span>
          <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{getNeighLabelFromLocal(obj.label[1])}}</span> -->
          <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{ obj.label[1] | neighnametranslate}}</span> -->
          <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[lang]?obj.label[lang]:obj.label[1]}}</span>
          <i *ngIf="!obj?.isSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
        </span>
      </div>
    </div>
    <div *ngIf="filterData.type == 'single_select'">
      <ng-container  *ngIf="this.DataList.length > 6">
      <mat-select panelClass="custom-height" class="form-control" [disabled]="Disabled"
       [formControl]="selectMultiForm">
       <mat-option class="search-sticky"><mat-icon>search</mat-icon><ngx-mat-select-search [formControl]="searchFilter"
          placeholderLabel="Search"
          noEntriesFoundLabel="No data found"></ngx-mat-select-search></mat-option>
          <mat-option *ngFor="let obj of filteredValue | async; trackBy: findId" [value]="obj.val" [disabled]="!obj?.isSubscription" [class.seoption-disable]="!obj?.isSubscription">
            <span *ngIf="filterData.parameter_name !== 'neigh_name'">{{obj.label | langtranslate}}</span>
            <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{getNeighLabelFromLocal(obj.label[1])}}</span> -->
            <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[1] | neighnametranslate}}</span> -->
            <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[lang]?obj.label[lang]:obj.label[1]}}</span>
            <i *ngIf="!obj?.isSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
          </mat-option>
      </mat-select>
      
      <div  class="selected-value" *ngIf="selectMultiForm.value">
        <mat-chip-listbox #chipGrid1 aria-label="selection2">
          <ng-container *ngFor="let val of [selectMultiForm.value]">
              <mat-chip-row (removed)="remove(val)" [disabled]="Disabled || getDisableChip(val)">
                 {{getFilterLable(val)}}
                <button matChipRemove [attr.aria-label]="'remove ' + val">
                  <mat-icon>cancel</mat-icon>
                </button>
              </mat-chip-row>
            </ng-container>
        </mat-chip-listbox>
      </div>
    </ng-container>
    <div *ngIf="this.DataList.length <= 6">
      <span (click)="selectSingleChips(obj.val)" class="filter-chips" 
      [class.selected]="checkSingleSelected(obj.val)" [class.disable-value]="!obj?.isSubscription"
       class="filter-chips" *ngFor="let obj of filteredValue | async">
       <span *ngIf="filterData.parameter_name !== 'neigh_name'">{{obj.label | langtranslate}}</span>
       <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{getNeighLabelFromLocal(obj.label[1])}}</span> -->
       <!-- <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[1] | neighnametranslate}}</span> -->
       <span *ngIf="filterData.parameter_name == 'neigh_name'">{{obj.label[lang]?obj.label[lang]:obj.label[1]}}</span>
      <i *ngIf="!obj?.isSubscription" class="filterlock"><img src="assets/images/lock-gray.svg" alt=""></i>
      </span>
    </div>
    </div>
</div>
