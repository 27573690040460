<div class="report-container retail-mall" *ngIf="!showPropertyDetails">

    <app-report-sub-menu [menuList]="overViewMenu" (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
    <ng-container *ngIf="overViewLoading">
        <app-skeleton-loader [reportType]="chartView.overView"></app-skeleton-loader>
    </ng-container>

    <div class="report-tabsection" id="{{ retailInventoryMenuEnum.overview}}"
        [ngClass]="{ 'scroll': currentScroll === retailInventoryMenuEnum.overview}">
        <div class="report-overview" *ngIf="!overViewLoading && overViewSummary">
            <app-overview-card [card_title]="'reports.totalRetailProperties'" [untillDate]="overViewSummary.untillDate"
                [total_prev]="overViewSummary.prev_total_retail_prop"
                [total_current]="overViewSummary.total_retail_prop"
                [total_percentage]="overViewSummary.totalretailpropVal"></app-overview-card>

            <app-overview-card [card_title]="'reports.grossLeasableArea'" [untillDate]="overViewSummary.untillDate"
                [total_prev]="overViewSummary.prev_gla" [total_current]="overViewSummary.gla"
                [total_percentage]="overViewSummary.totalgla" [uniType]="UnitTypeEnum.sqm"></app-overview-card>

            <app-overview-card [card_title]="'reports.totalShops'" [untillDate]="overViewSummary.untillDate"
                [total_prev]="overViewSummary.prev_total_shops" [total_current]="overViewSummary.total_shops"
                [total_percentage]="overViewSummary.totalshopsVal"></app-overview-card>

            <app-overview-card [card_title]="'reports.occupancy_rate'" [untillDate]="overViewSummary.untillDate"
                [total_current]="overViewSummary.occupacy_rate" [uniType]="UnitTypeEnum.percentage"></app-overview-card>
        </div>

        <div class="property-infod">
            <div class="property-information">
                <h2 class="detail-heading">{{'reports.countOfRetailProperty' | translate}}</h2>

                <ng-container *ngIf="propertyCountLoading">
                    <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
                </ng-container>
                <ng-container *ngIf="!propertyCountLoading">
                  <ng-container  *ngTemplateOutlet="propertyCount.length > 0 ? barChartTemplate : noRecordsTemplate"></ng-container>
                  <ng-template #barChartTemplate>
                    <app-report-charts [data]="propertyCount" [chartType]="chartView.barChart"
                              [name]="'houseVillasbarChart'" [isBorder]="false" [height]="'250px'"></app-report-charts>
                  </ng-template>
                </ng-container>
            </div>

            <div class="property-information">
                <h2 class="detail-heading">{{'reports.glaShare' | translate}} </h2>
                <ng-container *ngIf="glashareLoading">
                    <app-skeleton-loader [reportType]="chartView.pieChart"></app-skeleton-loader>
                </ng-container>

                <ng-container *ngIf="!glashareLoading">
                  <ng-container  *ngTemplateOutlet="propertyCount.length > 0 ? pieChartTemplate : noRecordsTemplate"></ng-container>
                  <ng-template #pieChartTemplate>
                    <app-report-charts class="directionLtr" [isBorder]="false" [height]="'250px'" [data]="glashare"
                        [chartType]="chartView.pieChart"></app-report-charts>
                  </ng-template>
                </ng-container>
            </div>
        </div>


        <div class="property-line-chart" >
            <div class="report-heading">
                <h2 class="detail-heading">{{trendsText | translate}}</h2>

                    <app-dropdown-radio-selection
                    [dropDownOption]="trendsDropdown"
                    (dropDownValue)="trendsdropDownChange($event)"
                    [showInvalueText]="false"
                    ></app-dropdown-radio-selection>



            </div>
            <ng-container *ngTemplateOutlet="trendsData.length > 0 ? lineChartTemplate : noRecordsTemplate"></ng-container>
            <ng-template #lineChartTemplate>
              <app-line-series-chart class="directionLtr" [data]="trendsData"></app-line-series-chart>
            </ng-template>
        </div>

        <div class="property-line-chart">
            <div class="report-heading">
                <h2 class="detail-heading">{{'reports.topNeighborhoodGlaShare' | translate}} {{ neighbourhodinsightText | translate}}</h2>
                <app-dropdown-radio-selection [dropDownOption]="neighInsightDropdownList" (dropDownValue)="neighInsightDropdown($event)" [showInvalueText]="false"></app-dropdown-radio-selection>

            </div>

            <ng-container *ngIf="neighbhoodGlaShareLoading">
                <app-skeleton-loader [reportType]="chartView.horizontalBarChart"></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!neighbhoodGlaShareLoading">
              <ng-container  *ngTemplateOutlet="neighGlashare.length > 0 ? GlaShare : noRecordsTemplate"></ng-container>
              <ng-template #GlaShare>
                <div class="sorted-bar" *ngIf="!neighbhoodGlaShareLoading && neighGlashare.length > 0">
                  <ng-container *ngFor="let category of neighGlashare; let i=index;">
                      <div class="progress d-flex align-items-center" *ngIf="category[1]['calculation'] != 0">
                          <h5 [title]="category[0]">{{category[0]}}</h5>
                          <section>
                              <div class="bar" [ngStyle]="{'width': (category[1]['calculation'] > 0 ? category[1]['calculation']+'%' : '2%'), 'opacity': getOpacity(i, getNumber(category[1]['category']) )}">
                               </div>
                              <small class="directionLtr">{{getNumber(category[1]['category']) | number:'1.0-1'}} {{ neighType === 'gla_share' ? ('reports.sqm_unit' | translate) :  ''}}</small>
                          </section>

                      </div>
                  </ng-container>
              </div>
              </ng-template>
            </ng-container>
          </div>
    </div>

    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailInventoryMenuEnum.properties}"
        id="{{ retailInventoryMenuEnum.properties }}">

        <app-report-year-dropdown [reportType]="'properties'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>

        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.projectsProperties' | translate}}</h3>
                    <span class="totalTransaction">({{ propertiesCount ?
                        reportService.formatInternationNum(propertiesCount)
                        : propertiesCount}})</span>

                </div>
            </div>

            <app-report-datatable [showLoader]="!propertiesLoading"
                [tableColumns]="propertiesColumns" [dataLength]="propertiesCount" [tableData]="propertiesData"
                (pageEvent)="onPageEvent($event, retailInventoryMenuEnum.properties)"
                (sort)="sortData($event, retailInventoryMenuEnum.properties)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_inventory_properties', 'a')" [expandColumn]="false" [downloadLoading]="downloadLoading['a']">
            </app-report-datatable>
        </div>
    </div>

    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailInventoryMenuEnum.departments}"
        id="{{ retailInventoryMenuEnum.departments }}">

        <app-report-year-dropdown [reportType]="'departments'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>

        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.departments' | translate}}</h3>
                    <span class="totalTransaction">({{ departmentsCount ?
                        reportService.formatInternationNum(departmentsCount) :
                        departmentsCount}})</span>

                </div>



            </div>

            <app-report-datatable class="rri-rentunitsize-table"
                [showLoader]="!departmentsLoading"
                [tableColumns]="departmentsColumns"
                [dataLength]="departmentsCount"
                [tableData]="departmentsdata"
                (pageEvent)="onPageEvent($event, retailInventoryMenuEnum.departments)"
                (sort)="sortData($event, retailInventoryMenuEnum.departments)"
                [allowExcelDownload]="true"
                [dropDownOption]="unitSizeDropdown"
                (excelDownload)="downloadExcel($event, 'retail_inventory_departments', 'b')"
                [expandColumn]="true"
                [tableExpandColumns]="departmentExpandColumns"
                (dropDownValue)="departmentDropdownChange($event)" [downloadLoading]="downloadLoading['b']"
                >
            </app-report-datatable>



        </div>
    </div>

    <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailInventoryMenuEnum.tenants}"
        id="{{ retailInventoryMenuEnum.tenants }}">

        <app-report-year-dropdown [reportType]="'tenants'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>

        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.tentants' | translate}}</h3>
                    <span class="totalTransaction">({{ tentantsCount ? reportService.formatInternationNum(tentantsCount)
                        :
                        tentantsCount}})</span>

                </div>



            </div>

            <app-report-datatable [showLoader]="!tentantsLoading"
                [tableColumns]="tentantsColumns" [dataLength]="tentantsCount" [tableData]="tentantsdata"
                (pageEvent)="onPageEvent($event, retailInventoryMenuEnum.tenants)"
                (sort)="sortData($event, retailInventoryMenuEnum.tenants)" [allowExcelDownload]="true"
                (dropDownValue)="tenantDropdownChange($event)"
                (excelDownload)="downloadExcel($event, 'retail_inventory_tenants', 'c')" [dropDownOption]="unitSizeDropdown"
                [expandColumn]="false" [downloadLoading]="downloadLoading['c']">
            </app-report-datatable>



        </div>
    </div>

    <!-- <div class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === retailInventoryMenuEnum.unit}"
        id="{{ retailInventoryMenuEnum.unit }}">

        <app-report-year-dropdown [reportType]="'unit'"
            (selectedYear)="selectedYear($event)"></app-report-year-dropdown>

        <div class="report-model">

            <div class="report-model-header">
                <div class="info">
                    <h3>{{'reports.units' | translate}}</h3>
                    <span class="totalTransaction">({{ unitsCount ? reportService.formatInternationNum(unitsCount) :
                        unitsCount}})</span>
                </div>
            </div>

            <app-report-datatable [showLoader]="!unitsLoading" [tableColumns]="unitColumns"
                [dataLength]="unitsCount" [tableData]="unitsdata"
                (pageEvent)="onPageEvent($event, retailInventoryMenuEnum.unit)"
                (sort)="sortData($event, retailInventoryMenuEnum.unit)" [allowExcelDownload]="true"
                (excelDownload)="downloadExcel($event, 'retail_inventory_units')" [expandColumn]="false">
            </app-report-datatable>

        </div>
    </div> -->
</div>

<ng-template #noRecordsTemplate>
  <div class="noRecordsFound">{{'reports.no_records_found' | translate}}</div>
</ng-template>
