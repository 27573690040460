import {
  Component,
  AfterViewInit,
  Inject,
  NgZone,
  PLATFORM_ID,
  Input,
  SimpleChanges,
  OnChanges,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';

import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import * as am5percent from '@amcharts/amcharts5/percent';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { ReportsChartEnum } from 'src/app/reports/models/enums/reports.enum';
import { SharedService } from 'src/app/common/services/shared.service';
import { TranslateService } from '@ngx-translate/core';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-report-charts',
  templateUrl: './report-charts.component.html',
  styleUrls: ['./report-charts.component.scss'],
})
export class ReportChartsComponent
  implements OnChanges, AfterViewInit, OnDestroy, OnInit
{
  @Input() height = '300px';
  @Input() data: any = [];
  @Input() chartType!: number;
  @Input() legend: any = [];
  @Input() isBorder: boolean = true;
  @Input() name: string = 'chartDiv';
  @Input() index: number = 0;
  @Input() text: string = '';


  lang!: number;

  private root!: am5.Root;
  ispercetage:any;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private zone: NgZone,
    private sharedService: SharedService,
    private translation: TranslateService,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(){
    this.lang = this.sharedService.getCurrentLangID();
    this.getChangedLang();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.root) {
      this.root.dispose();
    }
   this.createChart()
  }

  // Run the function only in the browser
  browserOnly(f: () => void) {
    if (isPlatformBrowser(this.platformId)) {
      this.zone.runOutsideAngular(() => {
        setTimeout(()=> {
          f();
        }, 1000)

      });
    }
  }

  createChart() {

    switch (this.chartType) {
      case ReportsChartEnum.barChart:
        this.barChart();
        break;
      case ReportsChartEnum.pieChart:
        this.pieChart();
        break;
      case ReportsChartEnum.barStackedChart:
        this.barStackedChart();
        break;
      case ReportsChartEnum.multiBarLineGroupChart:
        this.multiBarLineGroupChart();
        break;
      case ReportsChartEnum.barLineGroupChart:
        this.barLineGroupChart();
        break;
      case ReportsChartEnum.normalBarLineChart:
        this.normalBarLineChart();
        break;
      case ReportsChartEnum.varianceIndicatorsChart:
        this.varianceIndicatorsChart();
        break;
      case ReportsChartEnum.smallBarChart:
        this.smallBarChart();
        break;
      case ReportsChartEnum.smallvarianceIndicatorsChart:
        this.smallvarianceIndicatorsChart();
        break;

    }
  }

  ngAfterViewInit() {
    ///this.createChart();
  }

  getChangedLang(){
    this.sharedService.language$.subscribe(res=>{
      if(res){
        this.lang = this.sharedService.getCurrentLangID();
        // this.zone.runOutsideAngular(() => {
        //     this.createChart();
        // });
      }
    });
  }

  // Bar Chart
  barChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'none',
          wheelY: 'none',
          paddingBottom: 20,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
        })
      );

      // Define data
      let data = this.data;
      var numberFormatter = am5.NumberFormatter.new(root, {
        numberFormat: "#.#"
      });
      for (var i = 0; i < data.length; i++) {
        data[i].formattedValue = numberFormatter.format(data[i].value);
      }

      let xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 30,
      });
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      xRenderer.grid.template.setAll({
        location: 1,
        forceHidden: true,
      });

      //Add Tooltip on label
      xAxis.labelsContainer.set("tooltip", am5.Tooltip.new(root, {
        pointerOrientation: "down"
      }));
      xRenderer.labels.template.setAll({
        oversizedBehavior: "truncate",
        textAlign: "center",
        fontSize: 12,
        tooltipText: "{category}",
      });
      xRenderer.labels.template.setup = function(target) {
        target.set("background", am5.Rectangle.new(root, {
          fill: am5.color(0x000000),
          fillOpacity: 0
        }));
      };

      xAxis.onPrivate("cellWidth", function(cellWidth) {
        xRenderer.labels.template.set("maxWidth", cellWidth)
      });

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          // maxDeviation: 0.3,
          extraMax: 0.5,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.0
          }),
          numberFormatter: numberFormatter
        })
      );

      let yRenderer = yAxis.get('renderer');

      yRenderer.grid.template.set('forceHidden', true);
      yRenderer.labels.template.set('forceHidden', true);

      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: 'Income',
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'category',
          tooltip: am5.Tooltip.new(root, {}),
          fill: am5.color(0x3774e8),
          stroke: am5.color(0x3774e8),
        })
      );

      var tooltip = am5.Tooltip.new(root, {});

      tooltip.label.setAll({
        direction: 'rtl',
      });

      series1.columns.template.setAll({
        tooltipText: '{valueY}',
        width: am5.percent(50),
        tooltipY: 0,
        fill: am5.color(0x3774e8),
        tooltip: tooltip,
        cornerRadiusTL: 10,
        cornerRadiusTR: 10
      });


      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.97,
          sprite: am5.Label.new(root, {
            text: "{valueY}",
            fill: am5.color("#3774E8"),
            centerX: am5.p50,
            centerY: 30, // Position above the bar
            populateText: true
          })
        });
      });
      series1.data.setAll(data);

      this.root = root;
    });
  }

  // pie Chart
  pieChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5percent.PieChart.new(root, {
          layout: root.horizontalLayout,
        })
      );

      // Define data
      var data = this.data;
    let itemLength = data?.length;
      // Create series
      var series = chart.series.push(
        am5percent.PieSeries.new(root, {
          name: 'Series',
          valueField: 'value',
          categoryField: 'category',
          legendLabelText: "[bold]{category}[/]",
          legendValueText: "",
          alignLabels: false
        })
      );

      series.set("colors", am5.ColorSet.new(root, {
        colors: [
          am5.color(0xC3D5F8),
          am5.color(0x2372F0),
          am5.color(0xEE7435),
          am5.color(0x57AC52),
          am5.color(0x664FD9),
          am5.color(0x87ACF1),
        ]
      }))
      series.slices.template.set("tooltipText", "[bold]{category}:[/] {valuePercentTotal.formatNumber('#.0')}%");

      series.data.setAll(data);
      // series.labels.template.setAll({
      //   text: "[bold]{valuePercentTotal.formatNumber('0.00')}",
      //   fontSize: 9,
      //   fill: am5.color(0xFFFFFF),
      //   radius: -50
      // });
      series.ticks.template.set('forceHidden', true);

      series.labels.template.set('forceHidden', true);



      // Add legend
      let strLength = data.some((x:any) => x['category'].length > 10);
      let legend;
      if(itemLength > 10){
        legend = chart.children.push(
          am5.Legend.new(root, {
            centerY: am5.percent(30),
            y: am5.percent(45),
            x: am5.percent(60),
            layout: root.verticalLayout,
            // height: am5.percent(80),
            maxHeight: 230,
            verticalScrollbar: am5.Scrollbar.new(root, {
              orientation: 'vertical',
              animationDuration:0,
            }),

          })
        );
      } else {
        legend = chart.children.push(
          am5.Legend.new(root, {
            centerY: am5.percent(30),
            y: am5.percent(45),
            x: am5.percent(strLength ? 58 : 63),
            layout: root.verticalLayout,
            maxHeight: 230
          })
        );
      }
      // legend.itemContainers.template.setAll({
      //   reverseChildren: this.lang === 1 ? false : true,
      // });


      legend.markers.template.setAll({
        width: 11,
        height: 11,
      });
      legend.labels.template.setAll({
        fontSize: 11,
        maxWidth: 170,
        oversizedBehavior: "wrap"

      });
      legend.valueLabels.template.setAll({
        fontSize: 11,
      });

      legend.data.setAll(series.dataItems);

      this.root = root;
    });
  }

  // bar Stacked Chart
  barStackedChart() {

    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          layout: root.verticalLayout,
          arrangeTooltips: false,
          paddingLeft: 0,
          paddingRight: 10
        })
      );

      // Use only absolute numbers
      chart.getNumberFormatter().set("numberFormat", "#.#s");


      let data = this.data;

      let yAxis = chart.yAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9,
            minorGridEnabled: true,
            minGridDistance: 10
          })
        })
      );


      yAxis.data.setAll(data);

      let xAxis = chart.xAxes.push(
        am5xy.ValueAxis.new(root, {
          renderer: am5xy.AxisRendererX.new(root, {
            minGridDistance: 60,
            strokeOpacity: 0.1
          })
        })
      );

      let yRenderer = yAxis.get('renderer');

      yRenderer.grid.template.set('forceHidden', true);
      yRenderer.labels.template.set('fontSize', 11);


      let xRenderer = xAxis.get('renderer');

      xRenderer.grid.template.set('forceHidden', true);
      xRenderer.labels.template.set('forceHidden', true);

      function createSeries(field : any, labelCenterX: any, pointerOrientation: any, rangeValue: any, rowIndex: any) {
        let series = chart.series.push(
          am5xy.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: field,
            categoryYField: "category",
            sequencedInterpolation: true,
            clustered: false,
            tooltip: am5.Tooltip.new(root, {
              pointerOrientation: pointerOrientation,
              labelText: "{categoryY}: {valueX}"
            }),
            fill: rowIndex === '1' ? am5.color(0x3774E8) : am5.color(0xEE7435),
            stroke: rowIndex === '1' ? am5.color(0x3774E8) : am5.color(0xEE7435),
          })
        );

        series.columns.template.setAll({
          height: am5.p100,
          width: am5.p50,

        });

        series.bullets.push(function() {
          return am5.Bullet.new(root, {
            sprite: am5.Label.new(root, {
              centerY: am5.p50,
              text: "{valueX}",
              populateText: true,
              centerX: labelCenterX,
              fontSize: 11
            })
          });
        });

        series.data.setAll(data);
        series.appear();


        return series;
      }



      createSeries("value", am5.p100, "right", -3, '1');
      createSeries("value1", 0, "left", 4, '2');
      let cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
        behavior: "zoomY"
      }));
      cursor.lineY.set("forceHidden", true);
      cursor.lineX.set("forceHidden", true);


      var legend = chart.children.push(am5.Legend.new(root, {
        nameField: "name",
        fillField: "color",
        strokeField: "color",
        centerX: am5.percent(60),
        x: am5.percent(60)
      }));



      legend.data.setAll([{
        name: this.translation.instant('reports.male'),
        color: am5.color(0x3774E8)
      }, {
        name: this.translation.instant('reports.female'),
        color: am5.color(0xEE7435)
      }]);

      legend.markers.template.setAll({
        width: 40,
        height: 8
      });
      legend.labels.template.setAll({
        fontSize: 11,
      });
      legend.valueLabels.template.setAll({
        fontSize: 11,
      });
      //chart.appear(1000, 100);
       this.root = root;
    })

  }

  // multi Bar line Chart
  multiBarLineGroupChart() {}

  // Bar line Group Chart
  barLineGroupChart() {}

  // normal Bar line Chart
  normalBarLineChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: "panX",
          wheelY: "zoomX",
          paddingLeft: 0,
          layout: root.verticalLayout
        })
      );


      chart.zoomOutButton.set("forceHidden", true);

      let data = this.data;


      let xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 60
      });
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: "category",
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {})
        })
      );
      xRenderer.grid.template.setAll({
        location: 1,
        forceHidden: true,
      })

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          extraMax: 0.5,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0.1
          })
        })
      );

      let yRenderer = yAxis.get('renderer');

      yRenderer.grid.template.set('forceHidden', true);
      yRenderer.labels.template.set('forceHidden', true);


      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: "Occupied Area (sqm)",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "value1",
          categoryXField: "category",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name} in {categoryX}: {valueY}"
          }),
          fill: am5.color(0x3774e8),
          stroke: am5.color(0x3774e8),
        })
      );

      // series1.columns.template.setAll({
      //   tooltipY: am5.percent(10),
      //   templateField: "columnSettings"
      // });

      series1.data.setAll(data);

      let series2 = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: "Occupied Ratio (%)",
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: "total",
          categoryXField: "category",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "{name} in {categoryX}: {valueY}"
          }),
          fill: am5.color(0xf58750),
          stroke: am5.color(0xf58750),
          legendValueText: "{valueY.formatNumber('#.00')}"

        })
      );

      // series2.strokes.template.setAll({
      //   strokeWidth: 3,
      //   templateField: "strokeSettings"
      // });


      series2.data.setAll(data);

      series2.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            strokeWidth: 3,
            radius: 5,
            fill: am5.color(0xf58750),
            stroke: am5.color(0xf58750),
          })
        });
      });

      chart.set("cursor", am5xy.XYCursor.new(root, {}));


      let legend = chart.children.push(
        am5.Legend.new(root, {
          centerX: am5.p50,
          x: am5.p50
        })
      );

      legend.markers.template.setAll({
        width: 50,
        height: 11,
      });
      legend.labels.template.setAll({
        fontSize: 11,
      });
      legend.valueLabels.template.setAll({
        fontSize: 11,
      });
      legend.data.setAll(chart.series.values);
      chart.appear(1000, 100);
      series1.appear();

      this.root = root;
    });
  }

  varianceIndicatorsChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart: any = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: "none",
        wheelY: "none",
        layout: root.verticalLayout,
        paddingLeft: 10,
        paddingRight: 10
      }));

      // Data
      let data: any = this.data;

      var numberFormatter = am5.NumberFormatter.new(root, {
        numberFormat: "#.#"
      });
      for (var i = 0; i < data.length; i++) {
        data[i].formattedValue = numberFormatter.format(data[i].value);
      }

      // Populate data
      for (var i = 0; i < (data.length - 1); i++) {
        data[i].valueNext = data[i + 1].value;
      }

      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.35,
        cellEndLocation: 0.9,
        minGridDistance: 30,
        minorGridEnabled: true
      });

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1,
        forceHidden: true
      });

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0.1
        }),
        numberFormatter: numberFormatter
      }));



      let yRenderer = yAxis.get('renderer');
      yRenderer.grid.template.set('forceHidden', true);

      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        fill:am5.color("#3774E8"),
        width: 50
      }));

      series.columns.template.setAll({
        // tooltipText: "[#000000][bold]{category}:{valueY}",
        tooltipText: "[#000000][bold]{valueY}",
        width: 35,
        tooltipY: -4,
        cornerRadiusTL: 8,
        cornerRadiusTR: 8,
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false, // Disable getting color from series
          getStrokeFromSprite: false, // Enable getting stroke color from series
        })
      });

      // Customize tooltip background, border radius, and font size
      let tooltip = series.columns.template.get("tooltip");
      tooltip.get("background").setAll({
        fill: am5.color("#C3D5F8"), // Change to desired background color
        strokeWidth: 0,
        cornerRadiusTL: 10, // Set top-left border radius
        cornerRadiusTR: 10, // Set top-right border radius
        cornerRadiusBL: 10, // Set bottom-left border radius
        cornerRadiusBR: 10  // Set bottom-right border radius
      });

      tooltip.label.setAll({
        fontSize: 12, // Change to desired font size
      });
      // chart.get("colors").set("colors", [
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8')
      // ]);
      // series.columns.template.adapters.add("fill", function (fill: any, target: any) {
      //   return chart.get("colors").getIndex(series.columns.indexOf(target));
      // });
      series.data.setAll(data);
      // Variance indicator series
      let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "valueNext",
        openValueYField: "value",
        categoryXField: "category",
        fill: am5.color(0x3774e8),
        stroke: am5.color(0x3774e8),
        locationY: 0.1,
        locationX: 0.7,
        fontWeight: "300",
        fontSize: 12
      }));

      // Add centerY adapter to series2
    series2.columns.template.setAll({
      centerY: 0 // Set initial centerY value
    });

    series2.columns.template.adapters.add("centerY", function(centerY: any, target: any) {
      var percent = getVariancePercent(target.dataItem);
      return percent <= -85 ? 30 : 0; // Adjust centerY based on the variance percentage
    });

      series2.columns.template.setAll({
        width: 0,
        fill: am5.color("#000000"),
        stroke: am5.color("#000000"),
      });
      series2.data.setAll(data);
      series2.bullets.push(function (root:any, series2:any, dataItem:any) {
        let percent = getVariancePercent(dataItem);

        if (percent === 0) {
          return null; // Hide arrow if no change
        }
        let arrow = am5.Graphics.new(root, {
          rotation: percent < 0 ? 90 : -90,
          centerX: am5.p50,
          centerY: am5.p50,
          dy:0,
          dx: 0,
          fill: am5.color("#000000"),
          stroke: am5.color("#000000"),
            draw: function (display) {
            display.moveTo(0, -3);
            display.lineTo(8, 0);
            display.lineTo(0, 3);
            display.lineTo(0, -3);
          }
        });
        //arrow modifys
        arrow.adapters.add("dy", (dy: any, target: any) => {
          var percent = getVariancePercent(target.dataItem);
          if (percent <= -80) {
            return -30; // Adjust this value to move the arrow further down
          }
          return
        });

        return am5.Bullet.new(root, {
          locationY: 1,
          locationX: 0.5,
          sprite: arrow
        });
      });

      series2.bullets.push( (root:any, series2:any, dataItem:any) => {
        let percent = getVariancePercent(dataItem);
        let label = am5.Label.new(root, {
          text: percent > 0 ? "+" + this.decimalPipe.transform((percent), '1.0-1') : percent.toString(),
          fontWeight: "300",
          fill: percent < 0 ? am5.color("#FF6C16") : percent === 0 ? am5.color("#EFBA00") : am5.color("#57AC52"),
          fontSize: 12,
          centerY: percent < 0 ? am5.p0 : am5.p100,
          centerX: am5.p50,
          populateText: true,
        });
        //Label Modify
        label.adapters.add("centerY", function (center, target) {
          var percent = getVariancePercent(target.dataItem);
          return percent <= -85 ? am5.p100 : (percent < 0 ? am5.p0 : center);
        });

        return am5.Bullet.new(root, {
          locationY: 1,
          locationX:-4,
          sprite: label
        });
      });

      series.appear(1000);
      chart.appear(1000, 100);

      function getVariancePercent(dataItem: any) {
        if (dataItem) {
          let value = dataItem.get("valueY");
          let openValue = dataItem.get("openValueY");
          let change = value - openValue;
          return Math.round(change);
        }
        return 0;
      }

      this.root = root;
    });
  }


  smallBarChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart = root.container.children.push(
        am5xy.XYChart.new(root, {
          panX: false,
          panY: false,
          wheelX: 'none',
          wheelY: 'none',
          paddingBottom: 50,
          paddingTop: 0,
          paddingLeft: 0,
          paddingRight: 0,
          layout: root.verticalLayout,
          pinchZoomX: false
        })
      );
      
      // Define data
      let data = this.data;
      var numberFormatter = am5.NumberFormatter.new(root, {
        numberFormat: "#.#"
      });
      for (var i = 0; i < data.length; i++) {
        data[i].formattedValue = numberFormatter.format(data[i].value);
      }

      let xRenderer = am5xy.AxisRendererX.new(root, {
        minorGridEnabled: true,
        minGridDistance: 30,
      });
      let xAxis = chart.xAxes.push(
        am5xy.CategoryAxis.new(root, {
          categoryField: 'category',
          renderer: xRenderer,
          tooltip: am5.Tooltip.new(root, {}),
        })
      );
      xRenderer.grid.template.setAll({
        location: 1,
        forceHidden: true,
      });
      xRenderer.labels.template.set('fontSize', 12);

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(
        am5xy.ValueAxis.new(root, {
          min: 0,
          // maxDeviation: 0.3,
          extraMax: 0.2,
          renderer: am5xy.AxisRendererY.new(root, {
            strokeOpacity: 0
          }),
          numberFormatter: numberFormatter
        })
      );


      let yRenderer = yAxis.get('renderer');

      yRenderer.grid.template.set('forceHidden', true);
      yRenderer.labels.template.set('forceHidden', true);

      let series1 = chart.series.push(
        am5xy.ColumnSeries.new(root, {
          name: this.name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: 'value',
          categoryXField: 'category',
          tooltip: am5.Tooltip.new(root, {}),
          fill: am5.color(0x3774e8),
          stroke: am5.color(0x3774e8),
          width: 35
        })
      );

      var tooltip = am5.Tooltip.new(root, {});

      // tooltip.label.setAll({
      //   direction: 'rtl',
      // });

      series1.columns.template.setAll({
        tooltipText: '{valueY}',
        width: am5.percent(90),
        tooltipY: 0,
        fill: am5.color(0x3774e8),
        tooltip: tooltip,
        cornerRadiusTL: 10,
        cornerRadiusTR: 10,
        fillOpacity: this.index === 0 ? 1 : Math.abs((1 - (this.index * 0.1))),
      });

      series1.bullets.push(function () {
        return am5.Bullet.new(root, {
          locationY: 0.97,
          sprite: am5.Label.new(root, {
            text: "{formattedValue}",
            fill: am5.color("#3774E8"),
            centerX: am5.p50,
            centerY: 30, // Position above the bar
            populateText: true
          })
        });
      });
      chart.children.unshift(am5.Label.new(root, {
        text: this.name,
        fontSize: 16,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        y: am5.percent(100),

      }));

    

      // var legend = chart.children.push(
      //   am5.Legend.new(root, {
      //     centerY:am5.percent(60),
      //     x: am5.percent(40)
      //   })
      // );
      // legend.data.setAll(chart.series.values);
      series1.data.setAll(data);

      this.root = root;
    });
  }

  smallvarianceIndicatorsChart() {
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
      let root = am5.Root.new(`${this.name}`);

      root.setThemes([am5themes_Animated.new(root)]);

      let chart: any = root.container.children.push(am5xy.XYChart.new(root, {
        panX: false,
        panY: false,
        wheelX: 'none',
        wheelY: 'none',
        paddingBottom: 50,
        paddingTop: 0,
        paddingLeft: 0,
        paddingRight: 0,
        layout: root.verticalLayout
      }));

      // Data
      let data: any = this.data;

      var numberFormatter = am5.NumberFormatter.new(root, {
        numberFormat: "#.#"
      });
      for (var i = 0; i < data.length; i++) {
        data[i].formattedValue = numberFormatter.format(data[i].value);
      }

      // Populate data
      for (var i = 0; i < (data.length - 1); i++) {
        data[i].valueNext = data[i + 1].value;
      }

      let xRenderer = am5xy.AxisRendererX.new(root, {
        cellStartLocation: 0.35,
        cellEndLocation: 0.9,
        minGridDistance: 40,
        minorGridEnabled: true
      });

      let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        categoryField: "category",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {})
      }));

      xRenderer.grid.template.setAll({
        location: 1,
        forceHidden: true,
      });

      xAxis.data.setAll(data);

      let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
        min: 0,
        extraMax: 0.3,
        renderer: am5xy.AxisRendererY.new(root, {
          strokeOpacity: 0
        }),
        numberFormatter: numberFormatter
      }));



      let yRenderer = yAxis.get('renderer');
      yRenderer.grid.template.set('forceHidden', true);
      yRenderer.labels.template.set('forceHidden', true);

      let series = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "value",
        categoryXField: "category",
        fill:am5.color("#3774E8"),
        width: 50
      }));

      series.columns.template.setAll({
        // tooltipText: "[#000000][bold]{category}:{valueY}",
        tooltipText: "[#000000][bold]{valueY}",
        width: 35,
        tooltipY: -4,
        cornerRadiusTL: 8,
        cornerRadiusTR: 8,
        tooltip: am5.Tooltip.new(root, {
          getFillFromSprite: false, // Disable getting color from series
          getStrokeFromSprite: false, // Enable getting stroke color from series
        })
      });

      // Customize tooltip background, border radius, and font size
      let tooltip = series.columns.template.get("tooltip");
      tooltip.get("background").setAll({
        fill: am5.color("#C3D5F8"), // Change to desired background color
        strokeWidth: 0,
        cornerRadiusTL: 10, // Set top-left border radius
        cornerRadiusTR: 10, // Set top-right border radius
        cornerRadiusBL: 10, // Set bottom-left border radius
        cornerRadiusBR: 10  // Set bottom-right border radius
      });

      tooltip.label.setAll({
        fontSize: 12, // Change to desired font size
      });
      // chart.get("colors").set("colors", [
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8'),
      //   am5.color('#3774E8')
      // ]);
      // series.columns.template.adapters.add("fill", function (fill: any, target: any) {
      //   return chart.get("colors").getIndex(series.columns.indexOf(target));
      // });
      series.data.setAll(data);
      // Variance indicator series
      let series2 = chart.series.push(am5xy.ColumnSeries.new(root, {
        xAxis: xAxis,
        yAxis: yAxis,
        valueYField: "valueNext",
        openValueYField: "value",
        categoryXField: "category",
        fill: am5.color(0x3774e8),
        stroke: am5.color(0x3774e8),
        locationY: 0.1,
        locationX: 0.7,
        fontWeight: "300",
        fontSize: 12
      }));

      // Add centerY adapter to series2
    series2.columns.template.setAll({
      centerY: 0 // Set initial centerY value
    });

    series2.columns.template.adapters.add("centerY", function(centerY: any, target: any) {
      var percent = getVariancePercent(target.dataItem);
      return percent <= -85 ? 30 : 0; // Adjust centerY based on the variance percentage
    });

      series2.columns.template.setAll({
        width: 0,
        fill: am5.color("#000000"),
        stroke: am5.color("#000000"),
      });
      series2.data.setAll(data);
      series2.bullets.push(function (root:any, series2:any, dataItem:any) {
        let percent = getVariancePercent(dataItem);

        if (percent === 0) {
          return null; // Hide arrow if no change
        }
        let arrow = am5.Graphics.new(root, {
          rotation: percent < 0 ? 90 : -90,
          centerX: am5.p50,
          centerY: am5.p50,
          dy:0,
          dx: 0,
          fill: am5.color("#000000"),
          stroke: am5.color("#000000"),
            draw: function (display) {
            display.moveTo(0, -3);
            display.lineTo(8, 0);
            display.lineTo(0, 3);
            display.lineTo(0, -3);
          }
        });
        //arrow modifys
        arrow.adapters.add("dy", (dy: any, target: any) => {
          var percent = getVariancePercent(target.dataItem);
          if (percent <= -90) {
            return -30; // Adjust this value to move the arrow further down
          }
          return
        });

        return am5.Bullet.new(root, {
          locationY: 1,
          locationX: 0.5,
          sprite: arrow
        });
      });

      series2.bullets.push( (root:any, series2:any, dataItem:any) => {
        let percent = getVariancePercent(dataItem);
        let label = am5.Label.new(root, {
          text: percent > 0 ? "+" + this.decimalPipe.transform((percent), '1.0-1') : percent.toString(),
          fontWeight: "300",
          fill: percent < 0 ? am5.color("#FF6C16") : percent === 0 ? am5.color("#EFBA00") : am5.color("#57AC52"),
          fontSize: 12,
          centerY: percent < 0 ? am5.p0 : am5.p100,
          centerX: am5.p50,
          populateText: true,
        });

        label.adapters.add("text", function (text, target) {
          let percent = getVariancePercent(target.dataItem);
          return percent ? percent + "%" : text;
        });
        //Label Modify
        label.adapters.add("centerY", function (center, target) {
          var percent = getVariancePercent(target.dataItem);
          return percent <= -85 ? am5.p100 : (percent < 0 ? am5.p0 : center);
        });

        return am5.Bullet.new(root, {
          locationY: 1,
          locationX:-4,
          sprite: label
        });
      });

      chart.children.unshift(am5.Label.new(root, {
        text: this.text,
        fontSize: 16,
        fontWeight: "500",
        textAlign: "center",
        x: am5.percent(50),
        centerX: am5.percent(50),
        y: am5.percent(100),

      }));

      series.appear(1000);
      chart.appear(1000, 100);

      function getVariancePercent(dataItem: any) {
        if (dataItem) {
          let value = dataItem.get("valueY");
          let openValue = dataItem.get("openValueY");
          let change = value - openValue;
          if(change === 0){
            return 0
          }
          let percentage = Math.round(change / openValue * 100);

          return isFinite(percentage) ? percentage : 100;
        }
        return 0;
      }

      this.root = root;
    });
  }

  ngOnDestroy() {
    // Clean up root element when the component is removed
    this.browserOnly(() => {
      if (this.root) {
        this.root.dispose();
      }
    });
  }
}
