<div class="filter-section" [formGroup]="formValue">
    <div class="amenties-header">
        <label>{{name | langtranslate}}</label>

        <div class="overlap-toggle">
            <span [ngClass]="{'overlap-label': checkOverlapEnable.length<2 || !overlapForms  }">Overlap</span>
            <div class="toggle">

                <input type="checkbox" id="mode-toggle" class="toggle__input"
                    [disabled]="typeForms.controls.length<=1 || checkOverlapEnable.length<2" [checked]="overlapForms"
                    (change)="onOverlaphange($event)">
                <label for="mode-toggle" class="toggle__label"
                    [ngClass]="{'disable-label': typeForms.controls.length<=1 || checkOverlapEnable.length<2}"></label>
            </div>
            <span [ngClass]="{'overlap-label': checkOverlapEnable.length<2 || overlapForms  }">All</span>
        </div>

    </div>


    <ng-container formArrayName='amenties' *ngIf="amentiesData.length>0">
        <div class="filter-amenties" *ngFor="let amenties of typeForms.controls; let i=index" [formGroupName]="i">
            <div class="filter-control">
                <mat-select class="form-control" placeholder="{{'reports.searchForStreetOrHighway'|translate}}"
                    (closed)="closeSelection(i)" formControlName='name' [panelClass]="'custom-amenties-panel custom-height'">
                    <mat-select-trigger>
                        {{typeForms.controls[i].value?.name.property_name}}
                    </mat-select-trigger>
                    <div class="search-input">
                        <mat-icon>search</mat-icon>
                        <input matInput [(ngModel)]="filterValue[i]" (input)="filterOptions(i)"
                            [ngModelOptions]="{ standalone: true }"
                            placeholder="{{'reports.searchForStreetOrHighway'|translate}}"
                            (keydown.space)="$event.stopPropagation()" class="search-filter" />
                    </div>

                    <mat-option class="filter-amentiesD" *ngFor="let option of amentiesList[i]"
                        [disabled]="checkSelectedValue(option , i)" [value]="option">

                        <div class="filter-option">
                            <span>{{option.property_name}}</span>
                            <div class="filter-list">
                                <span [matTooltip]="option.neigh_name">{{option.neigh_name}} {{option.block_name &&
                                    option.neigh_name ? '>' : ''}} {{option.block_name}}</span>
                                <span [matTooltip]="option.property_sub_cat">{{option.property_sub_cat}}</span>
                            </div>
                        </div>
                    </mat-option>

                    <ng-container *ngIf="amentiesList[i].length === 0">
                        <div class="filter-option noResultFound">
                            <span>{{'reports.noResultFound'|translate}}</span>

                        </div>
                    </ng-container>
                </mat-select>
            </div>
            <div class="filter-controlRange">

                <mat-select class="form-control" placeholder="select" formControlName='distance'
                    [panelClass]="'custom-distance-panel custom-height'">
                    <mat-option *ngFor="let radius of radiusList" [value]="radius" class="filter-rangeOption">
                        {{radius.distance}} {{'reports.km'|translate}} </mat-option>
                </mat-select>

            </div>
            <button class="plus-icon" (click)='deleteAmenties(i)'><mat-icon>close</mat-icon></button>

        </div>
    </ng-container>
    <ng-container *ngIf="amentiesData.length>0">
        <button class="addAmenties"
            [disabled]="typeForms.length >= 3 || !typeForms.controls[typeForms.length - 1].value.name"
            (click)="addAmenties()">
            <span>{{'reports.addAmenity'|translate}}</span>
            <mat-icon>add</mat-icon>
        </button>
    </ng-container>
</div>