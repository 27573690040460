<div class="mapLayer-panel">
    <div class="mapLayer-panel_header">
        <b>{{"reports.map_layer" | translate}}</b>
        <span (click)="layerToggle()" [ngStyle]="{'float': current_lang == 'en' ? 'right':'left'} ">{{"reports.close" |
            translate}}</span>
    </div>
    <div class="mapLayer-panel_body">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{"reports.property_legend" | translate}} 
                    <!-- <span class="max_choice">({{"reports.property_max2"  | translate}})</span> -->
                </mat-panel-title>
            </mat-expansion-panel-header>
            <!-- <ng-container *ngIf="layerData?.legend?.length > 0">
                <ng-container *ngFor="let legend of layerData.legend; let i = index">
                    <div class="img-with-text" [ngClass]="{'selected_option': legend.display}"
                        (click)="selectedLegend(legend)">
                        <img [class.img-disable]="reportService.getLockReport()" src="../../../../assets/images/map_layer_icons/{{legend.legend}}.svg"
                            (error)="onImgError($event)" />
                        <p>{{legend.label | translate}}</p>
                    </div>
                </ng-container>
            </ng-container> -->
            <ng-container *ngIf="legendLayerGroup?.length > 0">
                <ng-container *ngFor="let legend of legendLayerGroup; let i = index">
                    <div class="img-with-text" [ngClass]="{'selected_option': legend.display}"
                        (click)="selectedLegend(legend)" *ngIf="legend.status">
                        <img src="../../../../assets/images/map_layer_icons/property_{{legend.legend_type}}.svg"
                            (error)="onImgError($event)" />
                        <p>{{legend.label?.[current_lang_id]?legend.label[current_lang_id]:legend.label[1]}}</p>
                    </div>
                </ng-container>
            </ng-container>
        </mat-expansion-panel>
        <!-- <hr class="linedivider" [ngStyle]="{'left': current_lang == 'en' ? '5%':'','right': current_lang == 'ar' ? '5%':''}"> -->
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{"reports.map_style" | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="img-with-text" (click)="selectedBasemap('default')"
                [ngClass]="{'selected_option': basemap_select == 'default'}">
                <img src="../../../../assets/images/map_layer_icons/default_map_layers.svg" (error)="onImgError($event)" />
                <p>{{"reports.default" | translate}}</p>
            </div>
            <div class="img-with-text" (click)="selectedBasemap('satellite')"
                [ngClass]="{'selected_option': basemap_select == 'satellite'}">
                <img src="../../../../assets/images/map_layer_icons/satellite.svg" (error)="onImgError($event)" />
                <p>{{"reports.satellite" | translate}}</p>
            </div>
        </mat-expansion-panel>
        <hr class="linedivider" [ngStyle]="{'left': current_lang == 'en' ? '5%':'','right': current_lang == 'ar' ? '5%':''}">
        <mat-expansion-panel expanded>
            <mat-expansion-panel-header>
                <mat-panel-title>
                    {{"reports.block_level" | translate}}
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngFor="let map_layer of mapLayers; let i = index">
                <div *ngIf="map_layer?.layer_status" class="img-with-text maplayer-box"
                    [ngClass]="{'selected_option': map_layer.display}" (click)="selectedLayers(map_layer)" [matTooltip]="map_layer?.showToolTip ? (map_layer.label | translate) : ''" matTooltipPosition="above">
                    <img  [class.img-disable]="reportService.getLockReport()"  src="../../../../assets/images/map_layer_icons/{{map_layer.label}}.svg"
                        (error)="onImgError($event)" />
                    <p>{{map_layer.label | translate}}</p>
                </div>
            </ng-container>
        </mat-expansion-panel>
    </div>
</div>
