<!-- <div class="col-draw-tools" [ngClass]="{'colar-draw-tools' : current_lang == 'ar'  }" *ngIf="polygon_create && catchmentExpandList">
    <h1>{{getSaveFilter() ? getSaveFilter()  : ("map.draw_catchment" | translate)}}</h1>
    <i (click)="catchmentExpand()" class="catchmentCols-icon"></i>
</div> -->


<div class="catchment-name" (dblclick)="$event.stopPropagation()">
    <span class="title" [ngClass]="{'title-ar': current_lang == 'ar','col-title': drawnPolygons.length == 4}" matTooltip="{{getSaveFilter() ? getSaveFilter() : ('reports.unsavedCatchment' | translate)}}">{{getSaveFilter() ? getSaveFilter() : ("reports.unsavedCatchment" | translate)}} </span>

  

    <div class="selected-catchment" *ngIf="drawnPolygons.length > 0 && !loadCatchmentShow && !catchmentExpandList">
     
        <ng-container *ngFor="let filter of drawnPolygons;let i = index" >
            <div [ngClass]="{'catchment-active': filter?.alreadySaved}">
            <mat-chip-row class="filter-matChip mr-3"
                [ngClass]="filter.colorClassName" >
                <span matTooltip="{{ filter?.alreadySaved ? ('map.saveCatchement' | translate) : ('map.unSaveCatchement' | translate)  }}">C{{i+1}}</span>
                <mat-icon matChipRemove (click)="polygonWKTRemove(filter)" class="remove-filter" matTooltip="{{'map.remove' | translate}}">close</mat-icon>
                <!-- <button matChipRemove (click)="polygonWKTRemove(filter)" [disabled]="polygon_chip">
                    
                </button> -->
            </mat-chip-row>
        </div>
        </ng-container>
        <div class="save-and-reset" [ngClass]="{'save-and-reset-ar' : current_lang == 'ar'  }">
            <i matTooltip="{{'map.reset' | translate}}" *ngIf="savedCatchment.length === 0" (click)="resetCatchmentOpen()" class="resetcatchment-icon"></i>
            <i matTooltip="{{'map.reset' | translate}}" *ngIf="savedCatchment.length > 0" [ngClass]="{'reset-disable': alreadySelectedCatchment === drawnPolygons.length}"  (click)="revertCatchmentOpen()" class="resetcatchment-icon"></i>
            
           
            <button 
                 *ngIf="savedCatchment.length === 0"  
                 (click)="savedFilterName($event)"
                 [ngClass]="{'save-disable': drawnPolygons.length === 0 }"
                 class="saveCatchement-btn">
                 {{'map.save' | translate}}
            </button>
            <button 
                *ngIf="savedCatchment.length > 0" 
                [matMenuTriggerFor]="existingCatchment"
                [disabled]="alreadySelectedCatchment === drawnPolygons.length" 
                [ngClass]="{'save-disable': drawnPolygons.length === 0 }"
                class="saveCatchement-btn">{{'map.save' | translate}} 
            </button>

            <mat-menu #existingCatchment="matMenu" xPosition="after" class="catchment-more-panel">
                
                <ul class="catchment-moreInfo">
                    <li (click)="savedFilterName($event, true)">{{'map.overwrite_existing' | translate}}</li>
                    <li (click)="savedFilterName($event)">{{'map.saveAsNewCatchment' | translate}}</li>
                </ul>
            </mat-menu>
        </div>

    </div>

    <span class="catchment-more" [matMenuTriggerFor]="catchmentMore" matTooltip="{{'map.more' | translate}}"><mat-icon>more_vert</mat-icon></span>

    <mat-menu #catchmentMore="matMenu" class="catchment-more-panel" >
        <ul class="catchment-moreInfo">
            
            <li (click)="loadSaved()" *ngIf="reportService.savedCatchmentList.length || showViewCatchment">{{'map.viewSavedCatchment' | translate}}</li>
            <li *ngIf="drawnPolygons.length > 0" (click)="drawNewCatchment()">{{'reports.drawNewCatchment' | translate}}</li>
            <li (click)="removeCatchment()" >{{'map.removeCatchment' | translate}}</li>
        </ul>
        
    </mat-menu>
   <ng-container *ngIf="drawnPolygons.length > 0 && !loadCatchmentShow">
    <div class="catchment-expand" *ngIf="current_lang != 'ar'">
        <mat-icon *ngIf="!catchmentExpandList" (click)="catchmentExpand()">keyboard_arrow_left</mat-icon>
        <mat-icon *ngIf="catchmentExpandList" (click)="catchmentExpand()">keyboard_arrow_right</mat-icon>
    </div>

    <div class="catchment-expand catchment-expand-ar" *ngIf="current_lang == 'ar'">
        <mat-icon *ngIf="!catchmentExpandList" (click)="catchmentExpand()">keyboard_arrow_right</mat-icon>
        <mat-icon *ngIf="catchmentExpandList" (click)="catchmentExpand()">keyboard_arrow_left</mat-icon>
    </div>
   </ng-container>
   

</div>

<div class="load" *ngIf="loadCatchmentShow">
    <app-load-catchment (closeLoadCatchment)="closeLoadCatchment($event)"></app-load-catchment>
</div>


<div class="loader-prog" *ngIf="loader">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>