<div [ngClass]="{'custom_zindex' : tenantPropertyInfo}" class="map_section {{classname}}" [class.map-without-report] = "draw_btn_toggle" [class.isReport] = "isReport && (is_save_allow || (studyName.toLocaleLowerCase() === 'apartment' && (reportName.toLocaleLowerCase() === 'occupancy_and_lease' || reportName.toLocaleLowerCase() === 'inventory_in_all_the_neighborhoods' || reportName.toLocaleLowerCase() === 'units' || reportName.toLocaleLowerCase() === 'property_profiling')))">
<div id="map" #mapIdentifier [ngClass]="{'arb-map': current_lang == 'ar'}" [ngStyle]="{'margin-top' : reportId ? '60px' : '0px', 'height' : reportId ? 'calc(100vh - 143px)' : 'calc(100vh - 83px)'}">
    <div class="map-spinner" *ngIf="map_spinner">
        <mat-spinner class="background" mode="determinate" value="100">
        </mat-spinner>
        <mat-spinner class="mat-spin"></mat-spinner>
    </div>

    <div class="draw-toolbar"
        [ngStyle]="{'margin': draw_btn_toggle ? '12px 10px':''} "
        *ngIf="draw_btn_toggle">
        <ng-container *ngIf="polygon_create">
            <app-draw-catchment
            [drawnPolygons]="drawnPolygons"
            [filterList]="filterList"
            [polygon_chip]="polygon_chip"
            [polygon_create]="polygon_create"
            (polygonRemove)="polygonWKTRemove($event)"
            (catchmentExpandCol)="catchmentExpand($event)"
            (resetCatchmentConfirm)="resetCatchmentConfirm()"
            (loadCatchmentModel)="loadCatchmentModel()"
            (drawNewCatchmentLoad)="drawNewCatchmentLoad()"
        ></app-draw-catchment>

        </ng-container>


        <button
            [disabled]="reportService.getLockReport()"
            *ngIf="!polygon_create  && reportName?.toLocaleLowerCase() !== 'population'"
            id="draw_polybtn"
            class="draw-btn"
            [matMenuTriggerFor]="catchment"
            (click)="savedCatchment.length === 0 ? drawPolygon() : null"
            >
            <img *ngIf="reportService.getLockReport()" class="lock" src="assets/images/Lock-disable.png" alt="">
            <i class="setCatchment-icon"></i>
            <span *ngIf="!polygon_create">{{"map.set_catchment" | translate}}</span>

        </button>

        <div class="load" *ngIf="loadCatchmentShow">
            <app-load-catchment (closeLoadCatchment)="closeLoadCatchment($event)"></app-load-catchment>
        </div>


        <mat-menu #catchment="matMenu" class="catchment-panel" [xPosition]="current_lang == 'ar' ? 'before' : 'after'">
            <ul class="catchment-menu">
                <li (click)="drawPolygon();">{{'reports.drawNewCatchment' | translate}}</li>
                <li (click)="loadCatchment()">{{'reports.viewSavedCatchment' | translate}}</li>
            </ul>

        </mat-menu>



        <div class="tenant-names d-flex align-items-center" *ngIf="TenantName">
            {{'reports.show_on_map' | translate}}:<button><span>{{TenantName}}</span> <span class="icon"
                    (click)="removeCustomMaker(true)"><mat-icon class="remove-filter">close</mat-icon></span></button>
        </div>
    </div>
    <ng-container *ngIf="draw_btn_toggle">
      <div class="universalSearchContainer">
        <div class="search-setting"  >
          <div class="universal-search-btn-chip">
            <mat-chip-row *ngIf="isShowChip" class="searchedProperty searchedPropertyOnDrawPolygon"  [matTooltip]="universalSearchPropertyDetail" matTooltipPosition="below">
              {{universalSearchPropertyName}}
              <mat-icon matChipRemove (click)="closeChip(true)" class="remove-property">close</mat-icon>
            </mat-chip-row>
            <div class="map-filter map-search-comman">
              <button (click)="openMapSearch(); $event.stopPropagation()" class="map-filter_icns map-filter_icns-common" [class.active-map-search] = "isShowChip || isOpenMapSearch" matTooltip="{{'reports.search'| translate}}"><mat-icon
                  svgIcon="searchIcon"></mat-icon></button>
              <button (click)="layerToggle()" class="map-filter_icns map-filter_icns-common"  matTooltip="{{'reports.map-settings'|translate}}"><mat-icon
                    svgIcon="layerIcon"></mat-icon></button>
            </div>
          </div>
          <!--  -->
          <ng-container *ngIf="isOpenMapSearch">
            <div class="map-search map-search-comman search_container" id="map-search">
              <div class="search-bar">
                  <search-bar  #searchpropertyDetail  [MinCharSearch] = "3" [closeButton] = "true" (SearchClick)="getMapSearchedDetail($event)"
                   (close)="closeMapSearch($event)" [Placeholder]="cityCode == 'KWT' ? 'map.SearchPropertyNameKwt' : 'map.SearchPropertyName'"></search-bar>
              </div>
              <ng-container *ngIf="recentSearchedData?.length && showRecentSearch">
                <div class="recent-searched-container">
                  <div class="recent-search-heading">
                    <span>{{('map.recentSearch' | translate) | uppercase}}</span>
                    <button (click)="deleteRecentSearchedParcelDetail('delete_all')" class="delete-all">{{'map.delete_all' | translate}}</button>
                  </div>
                  <div class="searched-group">
                    <div *ngFor="let recentData of recentSearchedData let isLast = last" class="recent-searched-data-container">
                      <div class="recent-searched-data searched-data-container"  [class.no-border]="isLast">
                        <div class="searched-data" (click) = "locateSearchedParcelOnMap(recentData)">
                          <div class="parcel-id"><span>{{recentData.property_name}}</span></div>
                          <div class="neig-block-detail">
                            <span class="neigh-name">{{recentData.neigh_name}}</span>
                            <ng-container *ngIf="recentData.neigh_name && recentData.block_name"><span class="arrow-icons" > > </span></ng-container>
                            <span class="block-name">{{recentData.block_name}}</span>
                          </div>
                        </div>
                        <div class="delete" (click) = "deleteRecentSearchedParcelDetail('delete', recentData.id)">
                          <img src="assets/images/delete.png" alt="" width="12" height="14">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="searchedData?.length  && !showRecentSearch">
                <div class="searched-group">
                  <div class="searched-data-container" *ngFor="let data of searchedData" (click) = "locateSearchedParcelOnMap(data)">
                    <mat-icon class="map-filter_icns-common flip" svgIcon="upArrow"></mat-icon>
                    <div class="searched-data re-searched-data">
                        <div class="parcel-id"><span>{{data.property_name}}</span></div>
                        <div class="neig-block-detail">
                          <span class="neigh-name">{{data.neigh_name}} </span>
                          <ng-container *ngIf="data.neigh_name && data.block_name" ><span class="arrow-icons" > > </span></ng-container>
                          <span class="block-name">{{data.block_name}}</span>
                        </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-container *ngIf="!searchedData?.length  && !showRecentSearch">
                <div class="no-record-found">{{'reports.no_record_found' | translate}}</div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>


    <div class="legendContainer map_legend" *ngIf="legendToggle && legend_data_by_group?.length"
        [ngStyle]="{'direction': current_lang == 'ar' ? 'ltr':''} ">
        <span class="legendContainer_header"><span>{{'Map Legend' | translate}}
                ({{layerselected}})</span> <span class="legend_close" (click)="collapseLegend()"><img
                    class="legend_close" src="../../../../assets/images/cross.svg" /></span></span>
        <div *ngFor="let main_legend of legend_data_by_group;let idx = index;">
            <span class="mapLegend" style="font-weight: 500;">{{ main_legend.label[current_lang_id]?main_legend.label[current_lang_id]:main_legend?.label[1] }}</span>
            <div *ngFor="let legend of main_legend.data;let idx = index; let isLast=last;" class="mapLegend rows">
                <div style="display: flex;">
                        <span class="legendcolor" [class.circle]="main_legend.legend_type == 'grade'" [ngStyle]="{'background-color': legend.color}"></span>
                        {{ legend?.label?.[current_lang_id]?legend.label[current_lang_id]:legend?.label?.[1] }}
                </div>
            </div>
        </div>
    </div>
</div>
<app-reports-tenants-map-view *ngIf="tenantPropertyInfo" [propertyInfo]="tenantPropertyInfo"></app-reports-tenants-map-view>
</div>
<!-- <ng-container  *ngIf="currentReportView === viewType.gridView || currentReportView === viewType.mapGridView"> -->
<!-- <div class="mapLayer-panel" *ngIf="propertyinfoToggle">
    <app-map-property-details [propertyInfo]="selected_property"></app-map-property-details>
</div> -->
<ng-container *ngIf="showAreaToggle">
    <div class="mapLayer-panel"
    [ngClass]="{'mapLayer-fullpanel': showAreaToggle && currentReportView === viewType.gridView }">
      <app-report-neigh-area-details [propertyInfo]="neightInfo"></app-report-neigh-area-details>
    </div>
</ng-container>
<ng-container *ngIf="showProjectToggle">
    <div class="mapLayer-panel"
        [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }"
        *ngIf="reportName !== 'inventory'">

        <app-report-housevilla-project-details
            [propertyInfo]="projecttInfovar"></app-report-housevilla-project-details>
    </div>
    <div class="mapLayer-panel"
        [ngClass]="{'mapLayer-fullpanel': showProjectToggle && currentReportView === viewType.gridView }"
        *ngIf="reportName == 'inventory'">

        <app-report-inventory-project-details
            [propertyInfo]="projecttInfovar"></app-report-inventory-project-details>
    </div>
</ng-container>

<ng-container *ngIf="propertyinfoToggle">
    <div class="mapLayer-panel"
        [ngClass]="{'mapLayer-fullpanel': currentReportView === viewType.gridView }">
        <app-power-bi-property-details [property]="selected_property"></app-power-bi-property-details>
    </div>
</ng-container>
