import { Injectable, signal } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ReportsViewEnum } from '../models/enums/reports.enum';
import { environment } from '../../../environments/environment';
import { apiEndPoints } from '../../common/constants/api-endpointsconstant';
import { ApiService } from '../../common/services/api.service';
import { SharedService } from '../../common/services/shared.service';
import { ActivatedRoute } from '@angular/router';
import { SubscriptionPayload } from '../models/users.model';
import { LoaderType } from 'src/app/common/models/enums/common.enum';
import { DecimalPipe } from '@angular/common';
import moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { HttpClient } from '@angular/common/http';
import { StoreService } from 'src/app/common/services/store.service';
import { GlobalContants } from 'src/app/common/constants/global.contants';
import { UserService } from 'src/app/common/services/user.service';
import { models } from 'powerbi-client';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  CurrentYear = new Date().getFullYear();
  reportView$ = new BehaviorSubject<number>(ReportsViewEnum.mapView);
  reportQueryToggle$ = new Subject<boolean>();
  selectedReport$ = new BehaviorSubject<any>({ flag: false });
  reportPermission$ = new Subject<boolean>();
  selectedObjective: any | null = null;
  selectedStudies: any | null = null;
  selectedRepored: any | null = null;
  reportFilter: any | null = [];
  queryParams: any = null;
  currentReport: any;
  filterQueryParams: any = null;
  filterFlag: boolean = false;
  userSubscription: any = [];
  user_preference = Object(null);
  transactionPropertyDetails = new Subject<any>();
  savedFilters: any = [];
  showDefaultSaveButtonState!: boolean;
  showDefaultSaveButton!: boolean;
  master_filter_list: any = [];
  projectReportView: boolean = false;
  multi_story_residential$$ = signal(false);
  LockReport: boolean = true;
  userSublength: number = -1;
  showTenantMap$ = new Subject<boolean>();
  tenantDataState$ = new Subject<any>();
  showTenantPrevPropertyMap$ = signal('');
  amentiesData$ = new Subject<string>();
  reloadCatchment$ = new Subject<boolean>();
  savedCatchmentList: any = [];
  loadCatchementDataapi$ = new Subject<boolean>();

  constructor(
    private apiService: ApiService,
    private sharedService: SharedService,
    private activatedRoute: ActivatedRoute,
    private decimalPipe: DecimalPipe,
    private translate: TranslateService,
    private alertService: AlertService,
    public storeService: StoreService,
    private userService: UserService
  ) {
    this.getSearchMaster();
  }

  setLockReport(lock: boolean) {
    this.LockReport = lock;
  }

  getLockReport() {
    return this.LockReport;
  }


  getSearchMaster() {
    this.getSearchMasterFilters().subscribe({
      next: (res: any) => {
        if (res.data) {
          this.master_filter_list = res.data;
        } else {
          this.master_filter_list = []
        }
      },
      error: () => {
        this.master_filter_list = []
      }
    })
  }
  getObjectStudyReport() {
    let url: string = `${environment.miUrl}${apiEndPoints.objStudyReport}`;
    return this.apiService.get(url);
  }

  getUserSubscription() {
    const userInfo: any = this.sharedService.getUserInfo();
    let url: string = `${environment.miBaseUrl}${apiEndPoints.userSubscription}?customer_id=${userInfo.user_id}&user_id=${userInfo.user_id}&sort_asc=false&is_count=true&status=1`;
    return this.apiService.get(url);
  }

  getReportData(req: any) {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miBaseUrl}${apiEndPoints.reports}?status=1&sort_asc=true&sort_by=sort_order&is_count=false&group_id=${req?.gid}&id=${req?.rid}&user_id=${userInfo.user_id}`;
    return this.apiService.get(url, false, LoaderType.normal);
  }

  getReportDownload(queryParams: any, type: any) {
    const changeParams = this.setfilterQueryParams(queryParams);
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${changeParams}&user_id=${userInfo.user_id}&type=${type}`;
    return this.apiService.get(url);
  }
  getReportDownloadHouseVilla(queryParams: any, type: any, neigh_name: string = '') {
    const changeParams = this.setfilterQueryParams(queryParams);
    const userInfo: any = this.sharedService.getUserInfo();
    let url: string = `${environment.miUrl}${apiEndPoints.downloadHouseVillaReport}${changeParams}&user_id=${userInfo.user_id}&type=${type}`;
    if (neigh_name) url += `&neigh_name=${neigh_name}`
    return this.apiService.get(url);
  }

  downloadExcelUrl(filePath: string, placeCote: string) {
    const downloadUrl = `${this.sharedService.downloadExcelPath()}?file_name=${filePath}&city_code=${placeCote.toLowerCase()}&module_name=house_and_villa`;
    this.sharedService.downloadExcelUrl(downloadUrl);
  }
  subscriptionRequest(payload: SubscriptionPayload) {
    const url: string = `${environment.miBaseUrl}${apiEndPoints.subscriptionRequest}`;
    return this.apiService.post(url, payload);
  }

  setCurrentReport(report: any) {
    this.currentReport = report;
  }
  getCurrentReport() {
    return this.currentReport;
  }

  getAmenties(searchText: string = '') {
    let url: string = `${environment.miBaseUrl}${apiEndPoints.amenitiesFilter}?search_text=${searchText}`;
    return this.apiService.get(url);
  }

  getfilterData(
    name: string,
    table_name: string,
    payload: any = null,
    search_text: string = '',
    selected_param = ''
  ): Observable<any> {
    let params = '';
    if (payload) {
      let filterParams = { ...payload };

      delete filterParams.rid;
      delete filterParams.oid;
      delete filterParams.gid;
      delete filterParams.zoom;
      delete filterParams.centroid;
      delete filterParams.polygon;
      delete filterParams.srid;

      let filter: any = {};
      for (let key in filterParams) {
        if (['property_category', 'unit_category'].indexOf(key) > -1) {
          if (filterParams[key] && key != selected_param) {
            filter[key] = filterParams[key];
          }
        }
      }
      if(name !== 'year'){
        filter['year']= null; //this.CurrentYear;
      }
      params = JSON.stringify(filter).replace(/[+]/g, '%2B'); // handle +
    }

    ////Appending reportID in Report APIs
    let reportID = this.activatedRoute.snapshot.queryParams['rid'];

    let url = `${environment.miBaseUrl}${apiEndPoints.casecaddingfilter}?param=${name}&table_name=${table_name}`;

    if (reportID) {
      url += `&report_id=${reportID}`;
    }
    if (search_text) {
      url += `&search_text=${search_text}`;
    }
    if (params) url += `&filter=${encodeURIComponent(params)}`;
    return this.apiService.get(url, false)
  }

  getCombinefilterData(
    filter_data: any,
    payload: any = null,
  ): Observable<any> {
    let params = '';
    if (payload) {
      let filter: any = {};
      let removeParm = ['property_category'];
      for (let key in payload) {
        if (removeParm.indexOf(key) > -1) {
          filter[key] = payload[key];
        }
      }
      params = Object.keys(filter).length ? JSON.stringify(filter).replace(/[+]/g, '%2B') : ''; // handle +
    }

    ////Appending reportID in Report APIs
    let reportID = this.activatedRoute.snapshot.queryParams['rid'];

    let url = `${environment.miBaseUrl}${apiEndPoints.combineFilterDataList}`;

    if (reportID) {
      url += `?report_id=${reportID}`;
    }
    if (params && params) url += `&filter=${encodeURIComponent(params)}`;
    return this.apiService.post(url, filter_data)
  }

  getRecentSavedReport(user_id: number, searchTerm: string = "") {
    let url: string = `${environment.miBaseUrl}${apiEndPoints.recentSavedReport}?user_id=${user_id}&order_by=added_date&group_by=added_date&sort_asc=false`;
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm)
        .replace(/[!'()*]/g, (char) => '%' + char.charCodeAt(0).toString(16).toUpperCase());
      url += `&report_name=${encodedSearchTerm}`;
    }
    return this.apiService.get(url, false);
  }



  setFiltervalue(data: any) {
    let url: any = {};
    let selectedReport: any = this.getCurrentReport();
    // if (this.browser.getLocalValue('selected_report')) {
    //   selectedReport = JSON.parse(this.browser.getLocalValue('selected_report'));
    // }
    let master_f: any;
    if (selectedReport && selectedReport.filter) {
      for (let key in data) {
        master_f = selectedReport.filter.filter((ele: any) => key == ele.parameter_name);
        master_f = master_f[0];
        if (master_f) {
          if (master_f.type == 'range') {
            const value = data[key].split('-');
            url[key] = {
              min: value[0],
              max: value[1],
            };
          } else {
            url[key] = data[key];
          }
        }
      }

    }
    return url;
  }
  /**
   * Applied filter action perform
   * @param data
   * @returns
   */
  getFilterInURL(data: any) {
    let selectedReport: any = this.getCurrentReport();
    let url: any = {};
    let master_f: any;
    for (let key in data) {
      key = key.toLowerCase();

      if (data[key]) {
        master_f = selectedReport?.filter?.find((ele: any) => key == ele.parameter_name);
        if (master_f) {
          if (master_f.type == 'range') {
            let min = 0, max = 0;
            if (data[key].min == null || data[key].min == '' || data[key].min == undefined) {
            } else {
              min = data[key].min;
            }
            if (data[key].max == null || data[key].max == '' || data[key].max == undefined) {
            } else {
              max = data[key].max;
            }
            url[key] = `${min}-${max}`;
          } else if (master_f.type == 'single_select' || master_f.type == 'text') {
            if (data[key]) {
              url[key] = data[key];
            }
          } else if (master_f.type == 'multi_select' && data[key]) {
            if (key == "year" && selectedReport?.query?.component[0] == 'employment-data') {
              url[key] = data[key];
            } else {
              url[key] = data[key].join();
            }
          } else if (master_f.type == 'date_range') {
            if (data[key]) {
              url[key] = data[key];
            }
          } else if (master_f.type == 'form' && key === 'amenities') {
            const amenities: any = [];
            data[key]?.amenties.forEach((item: any) => {
              if (item.name) {
                amenities.push(`${item.name.property_name}*${item.name.property_uid}*${item.distance.value}*${item.name.lat}*${item.name.lon}`)
              }
            })
            
            if (amenities.length) {
              url[key] = amenities.join(',');
              url['is_overlap'] = amenities.length == 1 ? false : data[key]?.is_overlap;
            } else {
              delete url[key]
              delete url['is_overlap']
            }
          }
        }
      } else {
        url[key] = null
      }
    }
    return url;
  }

  setCetegoryFilter(report: any) {
    let defaultFilter: any = {};
    if (report.filter?.length > 0) {
      report.filter.forEach((filter: any) => {
        if (filter.default && (filter.parameter_name == 'property_category' || filter.parameter_name == 'unit_category')) {
          defaultFilter[filter.parameter_name] = this.getDefault(filter.default);
        }
      });
    }
    return defaultFilter;
  }

  setDefaultFilter(report: any) {
    let defaultFilter: any = {};
    if (report.filter?.length > 0) {
      report.filter.forEach((filter: any) => {
        if (filter.default) {
          defaultFilter[filter.parameter_name] = filter.type == 'range'
            ? this.getRangeValue(filter.default)
            : this.getDefault(filter.default);

          // if(report.query.component.every((v: string) => v === 'employment-data' )  && filter.parameter_name === 'year'){
          //   defaultFilter['quarter'] = JSON.stringify({
          //     [filter.default] : [1,2,3,4]
          //   });
          // }
        }
      });
    }
    return defaultFilter;
  }

  setSavedFilterData(defaultFilter: any, saved: any) {
    let filter_data = { ...saved };
    if (filter_data?.quarter) {
      filter_data.quarter = JSON.stringify(filter_data.quarter);
    }
    if (Object.keys(defaultFilter).length > 0) {
      Object.keys(defaultFilter).forEach(v => {
        if (!filter_data[v]) {
          filter_data[v] = defaultFilter[v]
        }
      })
    }
    return filter_data;
  }
  getDefault(value: any) {
    if (Array.isArray(value)) {
      return value.join(',');
    }
    return value;
  }

  getRangeValue(rangeValue: string) {
    if (rangeValue) {
      const val = rangeValue.split('|');
      if (val[0]) {
        return val[0] == "0-0" ? null : val[0];
      }
      return rangeValue;
    }
    return '';
  }

  // setfilterQueryParams(paramsData: any) {
  setfilterQueryParams(paramsData: any, type?: any, legendData?: any, bbox?: any) {
    let filter = `?report_id=${paramsData.rid}`;


    if (paramsData?.polygon) { /// If polygon filter applied then disable Neighbourhood Filter
      const polygonVal = JSON.parse(paramsData?.polygon)
      polygonVal.features = polygonVal.features?.map((data: any) => {
        return {
          type : data.type,
          properties : data.properties,
          geometry : data.geometry
        }
      })
      filter += `&polygon=${JSON.stringify(polygonVal)}`;
      filter += `&neigh_name=null`;
    } else if (paramsData?.neigh_name) {
      filter += `&polygon=null`;
      filter += `&neigh_name=${paramsData.neigh_name}`;
    }

    if (paramsData?.year) {
      let str = paramsData.year.toString();
      let layer_year = (str.includes(',')) ? paramsData.year.split(',').map(Number)
        .sort(function (a: any, b: any) { return a - b }).pop() : paramsData.year;
      filter += `&year=${layer_year}`;
      // filter += `&year=${paramsData.year}`;
    }

    if (paramsData?.property_category) {
      filter += `&property_category=${paramsData.property_category}`;
    }

    if (paramsData?.unit_category) {
      filter += `&unit_category=${paramsData.unit_category}`;
    }
    if (paramsData?.amenities) {
      const amenities = paramsData?.amenities.split(',');
      paramsData.amenities = amenities.length && amenities.map((item: any) => {
        const val = item.split('*')
        return {
          uid: val[1],
          distance: val[2]
        }
      })
    }


    // if (Object.keys(this.filterData(paramsData)).length > 0) {
    //   let filterValue = JSON.stringify(this.filterData(paramsData)).replace(/[+]/g, '%2B');
    //   filter += `&filter=${encodeURIComponent(filterValue)}`;
    // }
    if (Object.keys(this.filterData(paramsData)).length > 0) {
      if (type !== 'map') {
        let filterValue = JSON.stringify(this.filterData(paramsData)).replace(/[+]/g, '%2B');
        filter += `&filter=${encodeURIComponent(filterValue)}`;
      } else {
        let filterValue = JSON.stringify(this.filterData(paramsData)).replace(/[+]/g, '%2B');
        filter += `&flt=${filterValue}`;
      }
    }
    if (type === 'map') {
      filter += `&legend_type=${legendData}`;
      if (!paramsData?.polygon) filter += `&polygon=${bbox}`;
    }
    return filter;
  }

  filterData(paramsData: any) {
    let filterParams = { ...paramsData };

    delete filterParams.rid;
    delete filterParams.oid;
    delete filterParams.gid;
    delete filterParams.zoom
    delete filterParams.centroid
    delete filterParams.polygon
    delete filterParams.srid
    delete filterParams.property_category
    delete filterParams.unit_categorys

    if (filterParams?.year) {
      delete filterParams.year;
    }
    if (filterParams?.neigh_name) {
      delete filterParams.neigh_name;
    }
    if (filterParams?.property_category) {
      delete filterParams.property_categoryp;
    }
    if (filterParams?.unit_category) {
      delete filterParams.unit_category;
    }
    return filterParams;
  }

  yearMaxValue(year: any): any {
    return year ? (year?.includes(',') ? Math.max(...year.split(',')) : year) : null;
  }

  // setUserPreference(body: UserPreference) {
  //   const url: string = `${environment.miUrl}${apiEndPoints.userPreference}`;
  //   return this.apiService.post(url, body);
  // }

  subscriptionPayload(report: any): SubscriptionPayload {
    const userInfo: any = this.sharedService.getUserInfo();
    const payload = {
      user_id: userInfo.user_id,
      user_name: userInfo.uname || '',
      user_email: userInfo.email || '',
      user_company: userInfo.companies || '',
      entity_id: report.report_id,
      entity_type: 'report',
      status: 1,
      added_by: userInfo.user_id
    }
    return payload;
  }

  formatInternationNum(num: any, digits: number = 0) {
    if (!num) {
      return '';
    }
    let ans: any = '';
    if (isNaN(num))
      return "Invalid Input"
    if (num >= 1000 && num <= 999999) {
      ans = this.decimalPipe.transform((num / 1000), '1.0-1') + this.translate.instant('thousand');
    } else if (num >= 1000000 && num <= 999999999) {
      ans = this.decimalPipe.transform((num / 1000000), '1.0-1') + this.translate.instant('million');
    } else if (num >= 1000000000 && num <= 999999999999) {
      ans = this.decimalPipe.transform((num / 1000000000), '1.0-1') + this.translate.instant('billion');
    } else {
      ans = this.decimalPipe.transform(num, '1.0-1') + ""
      if (ans.includes('.00')) ans = this.decimalPipe.transform((ans.split('.00')[0]), '1.0-1');
    }
    return ans;
  }

  calcPercentage(y: number, x: number) {
    let lastYear = (Number(y));
    let currentYear = (Number(x));
    if (lastYear && currentYear) {
      //  return (((currentYear/lastYear)*100)-100)?.toFixed(2);
      return (((currentYear - lastYear) / lastYear) * 100)?.toFixed(2);
    } else {
      return '-';
    }
  }

  saveFilter(body: any) {
    this.userService.sessionCheck();
    const url: string = `${environment.miUrl}${apiEndPoints.saveFilter}`;
    return this.apiService.post(url, body);
  }
  /**
   * Function is used to get the saved filter
   * @param searchTerm : used to search the filter on the basis of name;
   */
  getSavedFilter(searchTerm: string = "", sort_asc: boolean = false) {
    const UserId: any = this.sharedService.UserId;
    if (!this.showDefaultSaveButtonState) this.showDefaultSaveButton = true;
    let url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${UserId}`;
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm);
      url += `&search_text=${encodedSearchTerm}`;
    }
    if (!sort_asc) {
      let sort_order = JSON.stringify({ "order": "desc", "column": "added_date" })
      url += `&sort_order=${sort_order}`;
    } else if (sort_asc) {
      let sort_order = JSON.stringify({ "order": "asc", "column": "added_date" })
      url += `&sort_order=${sort_order}`;
    }
    this.apiService.get(url).subscribe({
      next: (res: any) => {
        if (res.status == 200) {
          this.savedFilters = res.data;
          const filter = this.savedFilters.find((data: any) => data.name == searchTerm);
          if (filter) {
            this.storeService.addSessionData("filter", filter)
            this.reloadCatchment$.next(true)
          }
          this.showDefaultSaveButton = false;
          this.showDefaultSaveButtonState = true;
        } else {
          this.savedFilters = [];
          if (!searchTerm) {
            this.showDefaultSaveButton = true;
            this.showDefaultSaveButtonState = false;
          }
        }
      },
      error: (err: any) => {
        this.savedFilters = [];
        this.showDefaultSaveButton = true;
        this.showDefaultSaveButtonState = false;
      }
    });
  }
  /**
   * This function is used to delete the saved Filter
   * @param filter_id : filter id
   * @returns
   */
  deleteFilter(filter_id: string) {
    const userInfo: any = this.sharedService.getUserInfo();
    this.userService.sessionCheck();
    const url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?filter_id=${filter_id}&user_id=${userInfo.user_id}`;
    return this.apiService.delete(url);
  }


  getSearchMasterFilters() {
    let url = `${environment.miBaseUrl}${apiEndPoints.searchMaster}?&status=1`;
    return this.apiService.get(url);
  }

  /**
   * This function use for the getting filter label of given filter_key
   * @param key
   * @param value_list
   * @returns
   */
  getParameterLabel(key: string, value_list: any = []) {
    let filter = value_list.find((f: any) => f.parameter_name == key);
    if (filter) {
      return filter?.label[this.sharedService.getCurrentLangID()] || filter?.label['1']
    }
    return key;
  }
  /**
   * Api integration to get the report count
   * @returns  total count of saved report.
   */
  getUserReportFilterCount() {
    const userInfo: any = this.sharedService.getUserInfo();
    const url: string = `${environment.miUrl}${apiEndPoints.userReportCount}?user_id=${userInfo.user_id}`;
    return this.apiService.get(url);
  }
  /**
   * This Function delete the selected Saved Filter in Bulk.
   * @returns
   */
  bulkDeleteSavedFilter() {
    const userInfo: any = this.sharedService.getUserInfo();
    let filterId = this.sharedService.savedFilter.join(',')
    let url = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${userInfo.user_id}&filter_id=${filterId}`;
    return this.apiService.delete(url);
  }
  /**
   * This Function is used to update the saved filter
   * @param body updated filter
   * @returns
   */
  updateSavedFilter(body: any, savedFilter: any) {
    const userInfo: any = this.sharedService.getUserInfo();
    this.userService.sessionCheck();
    const url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${userInfo.user_id}&filter_id=${savedFilter.id}`;
    return this.apiService.patch(url, body);
  }
  /**
   *
   * @param grades
   * @returns
   * The count of all grades has been totaled
   */
  calculateTotalGradeValue(grades: any) {
    return Object.values(grades)
      .filter(value => value !== null)
      .reduce((sum: any, current: any) => sum + current, 0);
  }
  /**
   *
   * @param gradeValue
   * @param totalGradeValue
   * @returns
   * The grade-wise percentage has been calculated
   */
  calculateGradePercentage(gradeValue: any, totalGradeValue: any): any {
    if (gradeValue === null) return 0;
    const percentage = (gradeValue / totalGradeValue) * 100;
    return this.decimalPipe.transform(percentage, '1.2-2');
  }

  downloadReport(type: string, user_id: any) {
    const url: string = `${environment.miUrl}${apiEndPoints.reportDownload}${this.filterQueryParams}&type=${type}&user_id=${user_id}`;
    return this.apiService.get(url);
  }
  retailInventoryTenantDetails(type: string) {
    let queryParams = { ...this.activatedRoute.snapshot.queryParams }
    let year = this.yearMaxValue(queryParams['year']);
    if (!year) {
      year = new Date().getFullYear();
      queryParams['year'] = year;
    }
    let changeParams = this.setfilterQueryParams(queryParams)
    const url: string = `${environment.miUrl}${apiEndPoints.retailInventoryTenantDetails}${changeParams}&shop_name=${type}`;
    return this.apiService.get(url);
  }
  /**
   *
   * @param data
   * @returns number
   * The count of all grades has been totaled
   */
  getMaxValue(data: any) {
    let maxValue = 0;
    for (let val in data) {
      maxValue += data[val]
    }
    return maxValue;
  }

  getPercentageOverview(data: any) {
    let dataChange: any = { ...data };
    let newobj: any = {}
    let max = this.getMaxValue(data);
    for (let category in data) {
      if (dataChange[category]) {
        newobj[category] = { category: dataChange[category], calculation: dataChange[category] ? Math.round(this.percentageCal(dataChange[category], max)) : 0 }
      }
    }
    return newobj;
  }

  percentageCal(category: any, max: number) {
    let cal = (category * 100) / max
    if (cal > 0 && cal < 1) {
      cal = 1;
    }
    return cal
  }

  rangeToNumberFor(value: string) {
    if (value && value?.includes('-')) {
      let minmax = value.split('-')
      let numrangeval = this.numFormat(minmax[0]) + '-' + this.numFormat(minmax[1])
      return numrangeval;
    }
    return value;
  }

  numFormat(num: any) {
    if (!num) {
      return '';
    }
    if (isNaN(num)) {
      return num;
    }
    return this.decimalPipe.transform(Number(num), '1.0-1')
  }

  getDataFormat(date: any) {
    if (date) return date ? moment.utc(new Date(date)).local().format('DD MMM YYYY') : null;
    return '';
  }
  /**
   *
   * @param selectedChartValue
   * @param overviewChartData
   * @param e
   * @param value
   * @param maxSelections
   * @returns
   * Common function if select dropdown
   */
  selectChart(
    selectedChartValue: string[],
    overviewChartData: any,
    e: any,
    value: string,
    maxSelections: number = 2
  ): any {
    let index = selectedChartValue.indexOf(value);

    if (!e.checked) {
      if (selectedChartValue.length === 1) {
        this.alertService.warn(this.translate.instant('atLeastOneMust'));
        e.source.checked = true;
      } else {
        selectedChartValue.splice(index, 1);
      }
    } else {
      if (selectedChartValue.length > maxSelections) {
        this.alertService.warn(this.translate.instant('youCannotSelectMoreThan'));
        e.source.checked = false;
        return false;
      } else {
        selectedChartValue.push(value)
      }
    }
    // Return the filtered chart report data
    return Object.keys(overviewChartData)
      .filter(key => selectedChartValue.includes(key))
      .reduce((obj: any, key: any) => {
        obj[key] = overviewChartData[key];
        return obj;
      }, {});
  }
  // Define the showMoreText logic here
  showMoreText(selectedChartValue: any[], textContent: string | null): string {
    let firstEleCount: number = textContent?.split(',')[0].length || 0;

    if (selectedChartValue.length == 2 && firstEleCount > 4) {
      return `(1) ${this.translate.instant('reports.more')}`;
    } else if (selectedChartValue.length == 3 && firstEleCount > 4) {
      return `(2) ${this.translate.instant('reports.more')}`;
    } else if (selectedChartValue.length == 2 && firstEleCount < 4) {
      return '';
    } else {
      return `(1) ${this.translate.instant('reports.more')}`;
    }
  }
  //Filter Subscription
  filterSubscription() {
    //Add Lock
    let reportSubscription = this.userSubscription.find((x: any) => (x.report_id == this.queryParams?.rid));
    //if reportSubscription object not match reportFilter object so merge object in reportFilter array
    if (Array.isArray(reportSubscription?.filter)) {
      reportSubscription.filter.forEach((subscription: any) => {
        // Check if the parameter_name value does not exist in reportFilter
        if (!this.reportFilter.some((filter: any) => filter.parameter_name === subscription.parameter_name)) {
          // If it doesn't exist, push the object into reportFilter
          subscription['title'] = subscription.label;
          this.reportFilter.push(subscription);
        }
      });
    }

    this.reportFilter.map((reportFilter: any) => {

      // Find the matching filter from userSubscription based on parameter_name or col_name
      const matchingUserFilter = reportSubscription?.filter?.find((userFilter: any) => userFilter.parameter_name === reportFilter.parameter_name);
      // If a match is found
      if (matchingUserFilter) {
        //Set Defult Value
        if (matchingUserFilter?.default) {
          reportFilter['default'] = matchingUserFilter?.default;
        }
        reportFilter['dynamic_value'] = matchingUserFilter?.dynamic_value;
        reportFilter['required'] = matchingUserFilter?.required;
        reportFilter['map_filter'] = matchingUserFilter?.map_filter;
        reportFilter['type'] = matchingUserFilter?.type;

        // if (matchingUserFilter?.all_list) {
        //   reportFilter['all_list'] = matchingUserFilter?.all_list;
        // }
        // Iterate over the value_list of allFilter and match with the values in userSubscription
        if (reportFilter.type != 'hidden' && reportFilter?.value_list?.length && !matchingUserFilter?.dynamic_value) {
          reportFilter?.value_list.forEach((valueListItem: any) => {
            if (Array.isArray(matchingUserFilter?.value)) {
              const isMatch = matchingUserFilter?.value.some(
                (userFilterValue: any) => userFilterValue.val.toLowerCase() === valueListItem.val.toLowerCase()
              );
              // If a match is found, lock the corresponding object
              if (isMatch) {
                valueListItem['isSubscription'] = true;
              } else {
                valueListItem['isSubscription'] = false; // or leave it undefined if not required
              }
            }
          })
        } else if (matchingUserFilter?.dynamic_value || reportFilter.type == 'hidden') {
          reportFilter?.value_list?.map((valueListItem: any) => valueListItem['isSubscription'] = true);
        }
        if (reportFilter?.value_list?.length) {
          reportFilter['isSubscription'] = reportFilter?.value_list.every((x: any) => x.isSubscription);
          reportFilter['atLeastOneSubscription'] = reportFilter?.value_list.some((x: any) => x.isSubscription);
          reportFilter['atLeastOneSubsLock'] = reportFilter?.value_list.some((x: any) => !x.isSubscription);
        }
        if (matchingUserFilter?.type == 'range') { reportFilter['isSubscription'] = true; reportFilter['atLeastOneSubscription'] = true; reportFilter['atLeastOneSubsLock'] = false; }
        if (matchingUserFilter?.type === 'date_range') { reportFilter['isSubscription'] = true; reportFilter['atLeastOneSubscription'] = true; reportFilter['atLeastOneSubsLock'] = false; }
        if (matchingUserFilter?.type === 'form') { reportFilter['isSubscription'] = true; reportFilter['atLeastOneSubscription'] = true; reportFilter['atLeastOneSubsLock'] = false; }
        //only subscription value push to allfilter
        if (Array.isArray(matchingUserFilter?.value)) {
          matchingUserFilter.value.forEach((filter: any) => {
            let exists: any;
            if (Array.isArray(reportFilter?.value_list)) {
              exists = reportFilter?.value_list.some(
                (userFilter: any) => userFilter.val.toLowerCase() === filter.val.toLowerCase()
              );
            }
            // If it does not exist, push the object into matchingUserFilter
            if (!exists) {
              filter['isSubscription'] = true;
              if (!Array.isArray(reportFilter?.value_list)) {
                reportFilter.value_list = [];
              }
              reportFilter?.value_list.push(filter);
            }
          });
        }
      }
    })
    //update the filter by subscripetion
    if (this.currentReport) this.currentReport['filter'] = this.reportFilter;
    return this.reportFilter;
  }


  savedFilterList(searchTerm: string = "", sort_asc: boolean = false) {
    const UserId: any = this.sharedService.UserId;
    this.userService.sessionCheck();
    let url: string = `${environment.miUrl}${apiEndPoints.getSavedFilter}?user_id=${UserId}`;
    if (searchTerm) {
      const encodedSearchTerm = encodeURIComponent(searchTerm);
      url += `&search_text=${encodedSearchTerm}`;
    }

    let sort_order = JSON.stringify({ "order": "desc", "column": "added_date" })
    url += `&sort_order=${sort_order}`;

    if (sort_asc) {
      sort_order = JSON.stringify({ "order": "asc", "column": "added_date" })
      url += `&sort_order=${sort_order}`;
    }
    return this.apiService.get(url)
  }
  powerBiFecthReport(rId: any, gId: any): Observable<any> {
    let url: string = `${environment.miBaseUrl}${apiEndPoints.powerBiEmbedToken}?group_id=${rId}&report_id=${gId}`;
    return this.apiService.get(url);
  }
  //Get Catchment Properties
  getCatchmentproperties(polygon:any) {
    let filter;
    if (polygon) {
      filter = `?polygon=${encodeURIComponent(polygon)}`;
    }
    let url: string = `${environment.miUrl}${apiEndPoints.catchmentproperties}${filter}`;
    return this.apiService.get(url, false);
  }
  PropertyDetail(queryParams: any, uid: string) {
    if (queryParams?.year) queryParams.year = this.yearMaxValue(queryParams.year);
    let url: string;
    if (queryParams.year == null) {
      queryParams['year'] = new Date().getFullYear();
    }
    let changeParams = this.setfilterQueryParams(queryParams);
    url = `${environment.miUrl}${apiEndPoints.houseVillaPropertyDetail}?year=${queryParams['year']}&property_uid=${uid}`;
    return this.apiService.get(url);
  }

  getDistance() {
    return [{
      distance: 0.5,
      value: 500
    },
    {
      distance: 1,
      value: 1000
    },
    {
      distance: 1.5,
      value: 1500
    },
    {
      distance: 2,
      value: 2000
    },
    {
      distance: 2.5,
      value: 2500
    }, {
      distance: 3,
      value: 3000
    }, {
      distance: 3.5,
      value: 3500
    }, {
      distance: 4,
      value: 4000
    }, {
      distance: 4.5,
      value: 4500
    }, {
      distance: 5,
      value: 5000
    }]
  }

  getMapSearchedDetail(searchedText: string = '', year: number) {
    this.userService.sessionCheck();
    let url: string = `${environment.miUrl}${apiEndPoints.propertySearch}?search_text=${searchedText}&year=${year}`;
    return this.apiService.get(url);
  }

  getRecentSearchedParcelDetail() {
    this.userService.sessionCheck();
    let url: string = `${environment.miUrl}${apiEndPoints.recentSearchedProperties}?user_id=${this.sharedService.UserId}`;
    return this.apiService.get(url);
  }
  addRecentSearchedParcelDetail(payload: any) {
    const userInfo: any = this.sharedService.getUserInfo();
    let body = {
      ...payload,
      user_id: userInfo.user_id
    }
    this.userService.sessionCheck();
    let url: string;
    url = `${environment.miUrl}${apiEndPoints.recentSearchedProperties}`;
    return this.apiService.post(url, body);
  }
  deleteRecentSearchedParcelDetail(action: string, id: number = 0) {
    const userInfo: any = this.sharedService.getUserInfo();
    let url: string;
    this.userService.sessionCheck();
    url = `${environment.miUrl}${apiEndPoints.recentSearchedPropertiesDelete}?user_id=${userInfo.user_id}&action=${action}`;
    if (action == 'delete')
      url += `&id=${id}`;
    return this.apiService.delete(url);
  }

  resetCatchmentFilter(polygon: any) {
    const savefilter: any = {...this.storeService.getSessionData('filter')};
    savefilter.data.catchment.features = polygon,
    this.storeService.addSessionData("filter", savefilter)
  }



/**
 * This is a fuction to set filter for power bi
 * @param params 
 * @param catchmentCount 
 * @returns 
 */  
  createFilterJson(params:any, catchmentCount:number=0, propertyUid:any = null){
    return new Promise((resolve, reject) => {     
      let paramsFilters = {...params};
      // delete paramsFilters.rid;
      delete paramsFilters.oid;
      delete paramsFilters.gid;
      delete paramsFilters.zoom;
      delete paramsFilters.centroid;
      // delete paramsFilters.polygon;
      delete paramsFilters.srid;
  
      let cityCode = this.storeService.get(GlobalContants.StoreKey.placeCode);
      let basicFilter:any = [
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "property_summary",
            column: "citycode"
          },
          operator: "In",
          values: [cityCode]
        },
        {
          $schema: "http://powerbi.com/product/schema#basic",
          target: {
            table: "reports",
            column: "id"
          },
          operator: "In",
          values: [paramsFilters.rid]
        }
      ];
      if(propertyUid){
        basicFilter.push(
          {
            $schema: "http://powerbi.com/product/schema#basic",
            target: {
              table: "property_summary",
              column: "property_uid"
            },
            operator: "In",
            values: [propertyUid]
          }
        )
        if(paramsFilters.hasOwnProperty('unit_category')){
          let filter = this.reportFilter.find((f:any)=> f.parameter_name == 'unit_category');
          let propertyCatFilter = paramsFilters.unit_category.split(',');
          let propertyCondition:any[] = [];
          propertyCatFilter.map((e:any) => {
            propertyCondition.push({
              operator: 'Contains',
              value: e
            })
          });
          if(filter){
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                  table: filter.table,
                  column: filter.col_name
                },
                logicalOperator: "Or",
                conditions: propertyCondition,
                filterType: models.FilterType.Advanced
              }
            )
          }          
        }
      }
       if((this.reportFilter?.length > 0 || params.hasOwnProperty('polygon')) && !propertyUid){
        for(let key in paramsFilters){
          let data = paramsFilters[key];
          let filter = this.reportFilter.find((f:any)=> f.parameter_name == key);
          if(key == 'year'){
            data = (paramsFilters['year']?.split(',') || [new Date().getFullYear()]).map((y: any) => Number(y));
          } else if(key == 'polygon'){
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#basic",
                target: {
                  table: "catchmentid",
                  column: "id"
                },
                operator: "In",
                values: catchmentCount ? [catchmentCount] : null
              }
            )
          } else if(key == 'property_category'){
            let propertyCatFilter = paramsFilters.property_category.split(',');
            let propertyCondition:any[] = [];
            propertyCatFilter.map((e:any) => {
              propertyCondition.push({
                operator: 'Contains',
                value: e
              })
            });
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                  table: "property_summary",
                  column: "property_cat_subcat"
                },
                logicalOperator: "Or",
                conditions: propertyCondition,
                filterType: models.FilterType.Advanced
              }
            )
          } else if(key == 'unit_category'){
            let propertyCatFilter = paramsFilters.unit_category.split(',');
            let propertyCondition:any[] = [];
            propertyCatFilter.map((e:any) => {
              propertyCondition.push({
                operator: 'Contains',
                value: e
              })
            });
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                  table: filter.table,
                  column: filter.col_name
                },
                logicalOperator: "Or",
                conditions: propertyCondition,
                filterType: models.FilterType.Advanced
              }
            )
          }
          else if(key == 'transaction_date'){
            let transDate = data?.split('to');
            const startDate = new Date(transDate[0]); // Example start date
            const endDate = new Date(transDate[1]);   // Example end date 
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                  table: filter.table,
                  column: filter.col_name
                },
                logicalOperator: "And",
                conditions: [
                  {operator: "GreaterThanOrEqual", value: startDate.toISOString()},     
                  {operator: "LessThanOrEqual", value: endDate.toISOString()}   
                ],
                filterType: models.FilterType.Advanced
              }
            )
          }
          else if(filter?.type == 'range'){
            //Numerical Range Filter
            let rangeValue = data?.split('-');
            basicFilter.push(
              {
                $schema: "http://powerbi.com/product/schema#advanced",
                target: {
                  table: filter.table,
                  column: filter.col_name
                },
                logicalOperator: "And",
                conditions: [
                  {operator: "GreaterThanOrEqual", value: rangeValue[0]},     
                  {operator: "LessThanOrEqual", value: rangeValue[1]}   
                ],
                filterType: models.FilterType.Advanced
              }
            )
          }
          else{
            data = paramsFilters[key].includes(',') ? paramsFilters[key]?.split(',') : [paramsFilters[key]]
          }
          if(filter && key !== 'transaction_date' && filter.type !== 'range' && key !== 'property_category' && key !== 'unit_category'){
            basicFilter.push({
                  $schema: "http://powerbi.com/product/schema#basic",
                  target: {
                    table: filter.table,
                    column: filter.col_name
                  },
                  operator: "In",
                  values: data
                })
          }
         }
         if(!environment.is_captcha){
          console.log(basicFilter);
         }
          resolve(basicFilter);
       }else{
          resolve(basicFilter);
       }
      
    })
    
  }
  

  getPropertyConfigAPI(){  
    return new Promise((resolve, reject) => {    
      let url: string = `${environment.miBaseUrl}${apiEndPoints.biPropertyDetails}`;
      this.apiService.get(url).subscribe({
        next: (res:any)=>{
          if(res.status == 200){
            resolve(res.data);
          }else{
            resolve(null)
          }
        },
        error:(err)=>{
          resolve(null)
        }
      })
    })
  }

}
