import { Injectable } from "@angular/core";
import { GlobalContants } from "../constants/global.contants";

@Injectable({
    providedIn: 'root'
})

export class StoreService {

    public add(key: string, data: any) {
        const match = localStorage.getItem(key);
        if (match) {
            localStorage.removeItem(key);
        }
        const dataString = JSON.stringify(data);
        localStorage.setItem(key, dataString);
    }

    public get<T>(key: string) {
        const dataString = localStorage.getItem(key) as string;
        const data = JSON.parse(dataString);
        const typedData = data as T;
        return typedData;
    }

    public clear(key: string) {
        localStorage.removeItem(key);
    }

    public clearAll() {
        let lang = this.get(GlobalContants.StoreKey.CurrentLangID);
        let langCode = this.get(GlobalContants.StoreKey.CurrentLang);
        let currentRoute = this.get(GlobalContants.StoreKey.currentRoute);
        localStorage.clear()
        sessionStorage.clear();
        this.add(GlobalContants.StoreKey.CurrentLangID, lang);
        this.add(GlobalContants.StoreKey.CurrentLang, langCode);
        // this.add(GlobalContants.StoreKey.currentRoute, currentRoute);
    }

    public async addSessionData(key: string, data: any) {
        const match = sessionStorage.getItem(key);
        if (match) {
            sessionStorage.removeItem(key);
        }
        const dataString = JSON.stringify(data);
        sessionStorage.setItem(key, dataString);
    }

    public getSessionData<T>(key: string) {
        const dataString = sessionStorage.getItem(key) as string;
        const data = JSON.parse(dataString);
        const typedData = data as T;
        return typedData;
    }

    public clearSessionData(key: string) {
        sessionStorage.removeItem(key);
    }

}
