import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { GlobalContants } from './common/constants/global.contants';
import { AuthGuard } from './common/services/guard/auth.guard';
import { SampleReportComponent } from './sample-report/sample-report.component';
import { Page404Component } from './page-404';
import { LandingPageComponent } from './landing-page/landing-page/landing-page.component';
import { ResendMailComponent } from './resend-mail/resend-mail.component';

const appRoutes: Routes = [
  {
    path: '',
    component: LandingPageComponent
  },
  {
    path: GlobalContants.Routes.notVerified,
    component: ResendMailComponent,
  },
  {
    path: GlobalContants.Routes.Log_In,
    loadChildren: () => import('./login/login.module').then((m) => m.LogInModule)
  },
  // {
  //   path: GlobalContants.Routes.LogIn,
  //   loadChildren: () => import('./login/login.module').then((m) => m.LogInModule)
  // },
  {
    path: `:cid/${GlobalContants.Routes.Reports}`,
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate:[AuthGuard]
  },
  {
    path: GlobalContants.Routes.Reports,
    loadChildren: () => import('./reports/reports.module').then((m) => m.ReportsModule),
    canActivate:[AuthGuard]
  },
  {
    path: GlobalContants.Routes.Signup,
    loadChildren: () => import('./signup/sign-up.module').then((m) => m.SignUpModule)
  },
  {
    path: GlobalContants.Routes.SignUp,
    loadChildren: () => import('./signup/sign-up.module').then((m) => m.SignUpModule)
  },
  {
    path: `:cid/${GlobalContants.Routes.Profile}`,
    loadChildren: () => import('./profile/profile.module').then((m) => m.ProfileModule),
    canActivate:[AuthGuard]
  },
  {
    path: `:cid/${GlobalContants.Routes.BookMark}`,
    loadChildren: () => import('./my-report/my-report.module').then((m) => m.MyReportModule),
    canActivate:[AuthGuard]
  },
  // {
  //   path: `${GlobalContants.Routes.Dashboard}`,
  //   loadChildren: () => import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
  //   canActivate:[AuthGuard]
  // },
  // {
  //   path: `:cid/${GlobalContants.Routes.Dashboard}`,
  //   loadChildren: () => import('./dashboard/dashboard.module').then((d) => d.DashboardModule),
  //   canActivate:[AuthGuard]
  // },
  {
    path: GlobalContants.Routes.RequestDemo,
    loadChildren: () => import('./request-for-demo/request-for-demo.module').then((m) => m.RequestForDemoModule),

  },
  {
    path:`:cid/${GlobalContants.Routes.SampleReport}`,
    loadChildren: () => import('./sample-report/sample-report.module').then((m) => m.SampleReportModule),
    canActivate:[AuthGuard]
  },
  {
    path: '**',
    component: Page404Component
  },
];

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
