import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AuthGuard } from './common/services/guard/auth.guard';
import { AppComponent } from './app.component';
import { I18nModule } from './i18n/i18n.module';
import { SharedModule } from './common/shared.module';
import { ReportsModule } from './reports/reports.module';
import { HttpInterceptorsProvider } from './common/services/interceptor.service';
import {MatIconModule} from '@angular/material/icon';
import { Page404Component } from './page-404';
import { LandingPageComponent } from './landing-page/landing-page/landing-page.component';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatSidenavModule } from '@angular/material/sidenav';
import { DatePipe } from '@angular/common';
import { ResendMailComponent } from './resend-mail/resend-mail.component';
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics';
import { environment } from 'src/environments/environment';
import {MatSnackBarModule} from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    Page404Component,
    LandingPageComponent,
    ResendMailComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    I18nModule,
    SharedModule,
    ReportsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatIconModule,
    CarouselModule,
    MatSidenavModule,
    MatSnackBarModule,
    NgxGoogleAnalyticsModule.forRoot(environment.googleAnalyticsId),
  ],
  providers: [HttpInterceptorsProvider, AuthGuard, DatePipe],
  bootstrap: [AppComponent]
})
export class AppModule { }
