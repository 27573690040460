
<div class="report-preview" *ngIf="reportData">
    <div class="report-list-data" [class.grid-full-height]="reportData?.query?.component == 'power-bi-full-with-map'">
        <!-- <div class="title" *ngIf="studyName == 'apartment'"><h2 >{{ reportData?.label | langtranslate  }}</h2>
        </div> -->
        <ng-container *ngIf="reportData && reportData?.query">
            <ng-container [ngSwitch]="reportData?.query.component">
                <app-report-unit *ngSwitchCase="'units-report'" [params]="queryParams"></app-report-unit>
                <app-report-inventory *ngSwitchCase="'unit-inventory-report'" [params]="queryParams"></app-report-inventory>
                <app-report-property-profiling *ngSwitchCase="'property-profiling'" [params]="queryParams"></app-report-property-profiling>
                <app-report-occupancy-lease *ngSwitchCase="'unit-occupancy-reports'" [params]="queryParams"></app-report-occupancy-lease>
                <app-report-population *ngSwitchCase="'population'" [params]="queryParams"></app-report-population>
                <app-report-under-construction *ngSwitchCase="'under-construction'" [params]="queryParams"></app-report-under-construction>
                <app-report-mall-profiling *ngSwitchCase="'mall-profiling'" [params]="queryParams"></app-report-mall-profiling>
                <app-report-mall-inventory *ngSwitchCase="'mall-inventory'" [params]="queryParams"></app-report-mall-inventory>
                <app-report-fb *ngSwitchCase="'retail-f-and-b'" [params]="queryParams"></app-report-fb>
                <app-report-tenant-profiling *ngSwitchCase="'tenant-profiling'" [params]="queryParams"></app-report-tenant-profiling>
                <app-report-transactions *ngSwitchCase="'transactions'" [params]="queryParams" [multi_story_residential]="multi_story_residential"></app-report-transactions>
                <app-report-house-villas *ngSwitchCase="'house-villas'" [params]="queryParams" [multi_story_residential]="multi_story_residential"></app-report-house-villas>
                <app-report-house-rental *ngSwitchCase="'house-rental'" [params]="queryParams"></app-report-house-rental>
                <app-report-house-land-vacancy *ngSwitchCase="'house-land-vacancy'" [params]="queryParams"></app-report-house-land-vacancy>
                <app-report-office-space-rental *ngSwitchCase="'office-space-rental'" [params]="queryParams"></app-report-office-space-rental>
                <app-report-retail-rental *ngSwitchCase="'retail-rental'" [params]="queryParams"></app-report-retail-rental>
                <app-report-warehouse-rental *ngSwitchCase="'warehouse-rental'" [params]="queryParams"></app-report-warehouse-rental>
                <app-report-investment-inventory *ngSwitchCase="'inventory'" [params]="queryParams" [multi_story_residential]="multi_story_residential"></app-report-investment-inventory>
                <app-report-occupancy-rental *ngSwitchCase="'occupancy'" [params]="queryParams" [multi_story_residential]="multi_story_residential"></app-report-occupancy-rental>
                <app-report-retail-inventory  *ngSwitchCase="'retail-inventory'" [params]="queryParams"></app-report-retail-inventory>
                <app-report-office-inventory  *ngSwitchCase="'office-inventory'" [params]="queryParams"></app-report-office-inventory>
                <app-report-warehouse-inventory  *ngSwitchCase="'warehouse-inventory'" [params]="queryParams"></app-report-warehouse-inventory>
                <app-report-pbi-retail-malls-plaza  *ngSwitchCase="'power-bi-report'" [params]="queryParams"></app-report-pbi-retail-malls-plaza>
                <app-report-pbi-riyadh-storage-sector  *ngSwitchCase="'riyadh-storage-sector'" [params]="queryParams"></app-report-pbi-riyadh-storage-sector>
                <app-power-bi-dynamic  *ngSwitchCase="'power-bi-dynamic'" [params]="queryParams"></app-power-bi-dynamic>
                <app-land-inventory *ngSwitchCase="'land-inventory'" [params]="queryParams"></app-land-inventory>
                <app-report-power-bi-full-with-map  *ngSwitchCase="'power-bi-full-with-map'" [params]="queryParams"></app-report-power-bi-full-with-map>
                <app-report-power-bi-full-without-map  *ngSwitchCase="'power-bi-full-without-map'" [params]="queryParams"></app-report-power-bi-full-without-map>
            </ng-container>
        </ng-container>
    </div>

</div>
