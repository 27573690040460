import { SharedService } from 'src/app/common/services/shared.service';
import { Injectable } from '@angular/core';

import { environment } from 'src/environments/environment';
import { apiEndPoints } from '../constants/api-endpointsconstant';
import { ApiService } from './api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AlertService } from '../components/alert/alert.service';
import { GlobalContants } from '../constants/global.contants';
import { StoreService } from './store.service';
import { actionIdConstants } from '../constants/common.contants';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  savedReport$ = new BehaviorSubject<boolean>(false);
  // countdown: number = -1; 
  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private sharedService: SharedService,
    private storeService: StoreService,
    private router: Router
    ) { }

  login(body:any): Observable<any>{
    let url: string = `${environment.umsUrl}${apiEndPoints.login}`;
    return this.apiService.post(url, body, true);
  }

  notification(body:any): Observable<any>{
    let url: string = `${environment.umsUrl}${apiEndPoints.notification}`;
    return this.apiService.post(url, body, true);
  }

  otpVerify(body:any): Observable<any>{
    let url: string = `${environment.umsUrl}${apiEndPoints.verify}`;
    return this.apiService.post(url, body, true);
  }

  resetPassword(body:any): Observable<any>{
    let url: string = `${environment.umsUrl}${apiEndPoints.reset_password}`;
    return this.apiService.patch(url, body, true);
  }
  register(body:any): Observable<any>{
    let url: string = `${environment.umsUrl}${apiEndPoints.register}`;
    return this.apiService.post(url, body, true);
  }

  setUserInfo(userInfo: any){
    let first_name = userInfo.user_details && userInfo.user_details[this.sharedService.getCurrentLangID()] ? userInfo.user_details[this.sharedService.getCurrentLangID()].first_name  : '';
    let last_name = userInfo.user_details && userInfo.user_details[this.sharedService.getCurrentLangID()] ? userInfo.user_details[this.sharedService.getCurrentLangID()].last_name : '';
    let fullName = first_name.trim() + ' ' + last_name.trim();
    let user_info = {
            ...userInfo,
            fname: first_name,
            lname: last_name,
            uname: fullName,
            contact: userInfo.phone_number || '',
            country: userInfo.nationality || '',
            email: userInfo.primary_email_id || '',
            createdDate: userInfo.created_date || '',
            latitude: userInfo.lat || '',
            longitude: userInfo.lon || '',
            language: 'en',
            imageURL: userInfo.profile_image || '',
            userString: fullName.split(' ').map((name: any) => name.charAt(0)).join('')?.toUpperCase()
          };
    return user_info;
  }

  updateUserInfo(body: any, id:string): Observable<any> {
    this.sessionCheck();
    let url: string = `${environment.umsUrl}${apiEndPoints.userInfo}?updated_by=${id}&id=${id}`;
    return this.apiService.patch(url, body, true);
  }

  getUserSavedReport(user_id:number, bucket_id:string = "",searchTerm:string = "", total:boolean=false, limit:number=1000, offset:number=0) {

    let url = `${environment.miUrl}${apiEndPoints.userReports}?user_id=${user_id}&status=1&order_by=added_date&sort_asc=false&limit=${limit}&offset=${offset}`;
    if(total){
      url += `&is_count=true`
    }
    if (bucket_id){
      url += `&bucket_id=${bucket_id}`;
    }

    if (searchTerm) {
      const encodedSearchTerm =  encodeURIComponent(searchTerm)
      .replace(/[!'()*_]/g, (char) => '%' + char.charCodeAt(0).toString(16).toUpperCase())
      url += `&report_name=${encodedSearchTerm}`;
    }

    return this.apiService.get(url,false);
  }
  getUserBuckets (user_id:number) {
    let url = `${environment.miUrl}${apiEndPoints.userBuckets}?user_id=${user_id}&order_by=added_date&sort_asc=false&status=1`;
    return this.apiService.get(url, false)
  }
  getUseDetails(user_id:number){
    let url = `${environment.umsUrl}${apiEndPoints.userInfo}?user_id=${user_id}`;
    return this.apiService.get(url,true)
  }

  uploadProfileImage(user_id:string, body:any) {
    this.sessionCheck();
    let url = `${environment.umsUrl}${apiEndPoints.profileImage}?user_id=${user_id}`;
    return this.apiService.post(url, body, true);
  }

  removeProfileImage(user_id:string, body:any) {
    this.sessionCheck();
    let url = `${environment.umsUrl}${apiEndPoints.userInfo}?updated_by=${user_id}&&id=${user_id}`;
    return this.apiService.patch(url, body, true);
  }
  getUserPreference(user_id:string) {
    let url = `${environment.miBaseUrl}${apiEndPoints.userNotifications}?user_id=${user_id}`;
    return this.apiService.get(url, false);
  }

  updateUserPreference(body:any) {
    this.sessionCheck();
    let url = `${environment.miBaseUrl}${apiEndPoints.userNotifications}`;
    return this.apiService.post(url, body);
  }

  getUserSetting(user_id:string) {
    let url = `${environment.umsUrl}${apiEndPoints.userSetting}?user_id=${user_id}`;
    return this.apiService.get(url, true);
  }

  userSetting(body:any) {
    this.sessionCheck();
    let url = `${environment.umsUrl}${apiEndPoints.userSetting}`;
    return this.apiService.post(url, body, true);
  }

  /**
   * TO log the user activities
   */
  logUserActivity(data:any) {
    const { reportId, userdata, actionId, entity, entityId } = data;
    if (!actionId) {
      this.alertService.error('Action ID is required for activity logging!');
      return;
    }

    let body:any = {
      action_id: actionId ? actionId : '',
      user_id: this.sharedService.UserId,
      duration: data?.duration ? data?.duration : 0,
      data: userdata ? userdata : {},
      // location: this.getUserLocationData(),
      // ip: this.ip ? this.ip : null,
      added_by: this.sharedService.UserId,
    };
    if (reportId) {
      body['report_id'] = reportId;
    }
    if (entity) {
      body['entity_name'] = entity;
    }
    if (entityId) {
      body['entity_id'] = entityId;
    }
    let url = `${environment.miBaseUrl}${apiEndPoints.userActivity}`;
    this.apiService.post(url, body).subscribe((res: any) => {
      if (res && res.status == 201) {
      } else {
      }
    });
  }

  saveUserBuckets (body:any, isUpdate:boolean = false) {
    this.sessionCheck();
    let url = `${environment.miUrl}${apiEndPoints.userBuckets}`;
    if(isUpdate) return this.apiService.patch(url, body)
    return this.apiService.post(url, body);
  }
  saveUserReport (body:any) {
    let url = `${environment.miUrl}${apiEndPoints.userReports}`;
    return this.apiService.post(url, body);
  }
  updateSavedReport(body:any){
    let url = `${environment.miUrl}${apiEndPoints.userReports}`;
    return this.apiService.patch(url, body);
  }

  updatePassword(body:any) {
    let url = `${environment.umsUrl}${apiEndPoints.changePassword}`;
    return this.apiService.post(url, body, true);
  }
  contactUs(body:any){
    let url = `${environment.miBaseUrl}${apiEndPoints.contactUs}`;
    return this.apiService.post(url, body);
  }
  /**
   * This is a function for used to contact us api with city id
   * and set the selected city in http headers
   * @param body
   * @param placeId
   * @returns
   */
  contactUsPost(body:any, placeId:any){
    let url = `${environment.miBaseUrl}${apiEndPoints.contactUs}`;
    return this.apiService.contactUsPost(url, body, placeId);
  }
  requestAccount(body:any, city_id:string){
    let url = `${environment.miUrl}${apiEndPoints.requestAccount}`;
    return this.apiService.requestDemoPost(url, body, String(city_id));
  }
  deleteReport(report:any){
    this.sessionCheck();
    let url = `${environment.miUrl}${apiEndPoints.userReports}?id=${report.id}&report_id=${report.report_id}&deleted_by=${this.sharedService.UserId}`;
    return this.apiService.delete(url);
  }
  deleteFolder(bucket_id:number, user_id:number, force_delete:boolean) {
    this.sessionCheck();
    let url = `${environment.miUrl}${apiEndPoints.userBuckets}?bucket_id=${bucket_id}&user_id=${this.sharedService.UserId}&force_delete=${force_delete}`;
    return this.apiService.delete(url);
  }

  removeReportsFromFolder(body:any) {
    this.sessionCheck();
    let url = `${environment.miUrl}${apiEndPoints.bulkUpdateReport}`;
    return this.apiService.patch(url, body);
  }

  deleteReportsFromFolder(user_id:string, reports:any) {
    this.sessionCheck();
    let url = `${environment.miUrl}${apiEndPoints.bulkDeleteReport}?user_id=${user_id}&report_ids=${reports}`;
    return this.apiService.delete(url);
  }

  /**
   * This is a logout function
   */
  userLogout(val:boolean=false, verify:boolean = false){
    this.sharedService.canOpenPopUp = true;
    const data = {
      user_id: Number(this.sharedService.UserId),
      place_id: this.storeService.get(GlobalContants.StoreKey.PlaceId)
    }
    const activity = {
      userdata: data,
      actionId: actionIdConstants.User_Logout,
      entity: 'logout',
      entityId: Number(this.sharedService.UserId)
    }
    this.logUserActivity(activity);
    let url = `${environment.umsUrl}${apiEndPoints.logout}?all_device=${val}`;
    this.apiService.get(url, true).subscribe({})
    this.storeService.clearAll();
    this.sharedService.isUserInfoLoaded.next(false);
    if(!verify) {
      this.router.navigate(['/'+GlobalContants.Routes.Log_In]);
    }
  }

  resendEmail(payload:any) {
    let url = `${environment.umsUrl}${apiEndPoints.notification}`
    return this.apiService.post(url, payload, true);
  }

  /**
   * This is session check function if user session expired user will logout 
   */
  sessionCheck(){
    if (this.sharedService.UserId) this.apiService.getAllowResources(this.sharedService.UserId);
  }
    
}
