import { SearchBarComponent } from './../../../common/components/search-bar/search-bar.component';
import { Component, OnInit, PLATFORM_ID, Inject, OnDestroy, Input, effect, ViewChild, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { ReportMapService } from '../../services/report-map.service';
import { SharedService } from '../../../common/services/shared.service';
import { GlobalContants } from '../../../common/constants/global.contants';
import { environment } from 'src/environments/environment';
import { ReportService } from '../../services/report.service';
import { wktToGeoJSON, geojsonToWKT } from "@terraformer/wkt";
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, debounceTime, distinctUntilChanged, fromEvent, takeUntil } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogBoxComponent } from 'src/app/common/components/confirm-dialog-box/confirm-dialog-box.component';
import { ReportsViewEnum } from '../../models/enums/reports.enum';
import { SEOService } from 'src/app/common/services/SEOService.service';
import { GoogleAnalyticsService } from 'src/app/common/services/google-analytics.service';
import { centroid } from '@turf/turf';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { StoreService } from 'src/app/common/services/store.service';

declare const L: any;
export interface PopupDataInterface {
  type: string, propertiesdata: any, allowedAttributes: any, btn_label?: any, tenantData?:any, className?:string
}
@Component({
  selector: 'app-reports-map-view',
  templateUrl: './reports-map-view.component.html',
  styleUrls: ['./reports-map-view.component.scss']
})
export class ReportsMapViewComponent implements OnInit, OnDestroy {
  @ViewChild('mapIdentifier') mapIdentifier!: ElementRef;
  @ViewChild('searchpropertyDetail') searchpropertyDetail !: SearchBarComponent
  mapWidth!: number;
  mapHeight!: number;
  circleAnenities: any = [];
  public mapCenter: any = [29.3560456, 47.9999959];
  public base_Layer: any;
  public map: any;
  public layerData: any;
  public parcelLayerWms: any;
  public parcelLayerPbf: any;
  public Orig_parcelLayerWms: any;
  public pbfParcelLayer: any;
  public layerselected: number = 1;
  public legend_data: any = [];
  public legendToggle: boolean = false;
  public propertyinfoToggle: boolean = false;
  public selected_property: any;
  public selected_downloadopt: string = 'print';
  public downloadopt_toggle: boolean = false;
  public report_countLayer: any;
  public tenant_countLayer: any;
  public tenantMarkers: any;
  public individualTenantMarkers: any;
  public amenitiesMarkers: any;
  public map_spinner: boolean = false;
  public transaction_layer_active: boolean = true;
  public transaction_Popup: any;
  public current_lang: any = 'en';
  public cql_param: string = ``;
  public editableLayers: any;
  public wmsCityLayer: any;
  public drawControl: any;
  public polygon_create: boolean = false;
  public popup_remove: any = ['lat', 'lon', 'wkt', 'property_name', 'property_uid'];
  public mainTransaction_Lyrdata: any;
  public transaction_Lyrdata: any;
  public mapZoom: any = 12;
  public drawPolySub$: Subscription = new Subscription();
  public overlay_LayerSub$: Subscription = new Subscription();
  public mapCoordinatesSub$: Subscription = new Subscription();
  public amenitiesItemListSub$: Subscription = new Subscription();
  public selectedReportSub$: Subscription = new Subscription();
  public resetFilterSub$: Subscription = new Subscription();
  public legendLayerSub$: Subscription = new Subscription();
  public moveEndSubscription1$: Subscription = new Subscription();
  public moveEndSubscription2$: Subscription = new Subscription();
  public moveEndSubscription3$: Subscription = new Subscription();
  public moveEndSubscription4$: Subscription = new Subscription();
  public moveEndSubscriptionMap$: Subscription = new Subscription();
  public polygon_WKT: any;
  public overlay_Layer: any;
  public projectLayer: any;
  public circleGroup: any;
  public Pbf_OverlayLayer: any = [];
  public drawnPolygons: any = [];
  public mapLayers: any[] = [];
  public catchment_Markers: any;
  public filterObj: any;
  public showProjectToggle: boolean = false;
  public resizeObserver: any;
  public legend_data_by_group: any;
  public current_lang_id: number = 1;
  public currentReportData: any = null;
  public polygon_chip: boolean = false;
  public reportName: string = '';
  public layer_year = new Date().getFullYear();
  routeSub$: Subscription = new Subscription();
  public draw_btn_toggle: boolean = false;
  private unsubscribe$: Subject<void> = new Subject<void>();
  @Input() classname!: string;
  @Input() tenantPropertyInfo: any;
  @Input() filterList: any;
  @Input() is_save_allow !: boolean;
  @Input() studyName: string = '';
  isReport !: boolean
  overlay_mapLayer_current = this.mapService.overlay_mapLayer_current
  public pbfStyle_filter = {
    weight: 2,
    color: 'black',
    dashArray: '2, 6',
    fillOpacity: 0
  };
  public pbfStyle_nonfilter = {
    weight: 0,
    fillColor: '#F96038',
    color: '#F96038',
    fillOpacity: 0,
    fill: true,
  };
  public showAreaToggle: boolean = false;
  NeighDetails$: Subscription = new Subscription();
  neightInfo: any;
  projecttInfovar: any;
  lastMarker: any;
  currentLegendView$: Subscription = new Subscription();
  currentReportView$: Subscription = new Subscription();
  overlayMapLayerSubscription$: Subscription = new Subscription();
  selectedReportSubscription$: Subscription = new Subscription();
  currentReportView!: number;
  viewType = ReportsViewEnum;
  markerObjects: any[] = [];
  showMultiLatLon: any[] = [];
  TenantName: any = '';//this.mapService.tenantName();
  reportId: any;
  layerEndPoint = 'property_details';
  layer1: any;
  layer2: any;
  layer3: any;
  layer4: any;
  polys: any;
  featureGroup: any;
  poly: any;
  isCatchmentExpand: boolean = false;
  PBIpropertyToggle: boolean = false;

  zoomControlPosition: string = 'bottomright';   // store the position on zoom Control on map.
  zoomControl: any;        // store the zoom control which is being added on map.
  loadCatchmentShow: boolean = false;
  reLoadCatchmentShow: boolean = false;

  // Universal Map search variables
  searchedData: any      // store property data bases on search text
  recentSearchedData: any  // store recent searched property data.
  showRecentSearch: boolean = true; // To show recentSearched data conditionaly
  mapMarker: any;      // Store the leaflet marker of selected property
  isOpenMapSearch: boolean = false; // used to open search bar for property search .
  universalSearchPopUp: any     // Store the leaflet popup of selected property
  universalSearchPropertyName: string = ''    // selected searched property name/uid
  universalSearchPropertyDetail: string = '';   // selected searched property name along which neigh and block name
  isShowChip: boolean = false;     // To show or hide the chip (contains selected property name/uid)
  savedCatchment: any = [];
  cityCode: any='';
  searchOverlayDiv:any;
  mapPopupInstance: any;
  popupContent!:PopupDataInterface;
  @HostListener('document:click', ['$event'])
  onClickOutside(event: Event): void {
    const clickedElement = event.target as HTMLElement;
    // Check if the click is outside the active div
    const clickedInside = this.eRef.nativeElement.querySelector(`#map-search`)?.contains(clickedElement);
    if (!clickedInside && this.isOpenMapSearch) {
      this.isOpenMapSearch = false;
      this.showRecentSearch = true;
      this.removeScrollPropagationEvent();
    }
  }

  constructor(private eRef: ElementRef, @Inject(PLATFORM_ID) private platformId: Object, private sharedService: SharedService, private _decimalPipe: DecimalPipe,
    private mapService: ReportMapService, public reportService: ReportService, private dailog: MatDialog,
    private activatedRoute: ActivatedRoute, private router: Router, private translate: TranslateService,
    private _seoService: SEOService, private _googleAnalyticsService: GoogleAnalyticsService, private alertService: AlertService,
    private storeService: StoreService, private cdr: ChangeDetectorRef) {
    this.sharedService.language$.pipe(
      debounceTime(200),
      takeUntil(this.unsubscribe$)
    ).subscribe((lang) => {
      this.zoomControlPosition = (lang == 'ar') ? 'bottomleft' : 'bottomright';
      if (this.zoomControl) {
        this.zoomControl.remove();
        this.zoomControl = L.control.zoom({
          position: this.zoomControlPosition
        }).addTo(this.map);
      }

      this.current_lang_id = lang == 'ar' ? 2 : 1;
      this.current_lang = lang;
      if (this.drawControl) this.drawControl.remove();
      if (this.map && this.polygon_create) {
        this.createDrawLayer()
      }
      //disable control
      if (this.drawnPolygons.length >= 4) {
        this.disableDrawLayer()
      }

      if(this.mapPopupInstance && this.mapPopupInstance.isOpen()) {
        let content = this.mapService.popup_content(this.popupContent.type, this.popupContent.propertiesdata, this.popupContent.allowedAttributes, this.popupContent.btn_label, this.popupContent.tenantData, this.popupContent.className);
        this.mapPopupInstance.setContent(content)
      }
      if(this.universalSearchPopUp && this.universalSearchPopUp.isOpen()) {
        let content = this.mapService.popup_content(this.popupContent.type, this.popupContent.propertiesdata, this.popupContent.allowedAttributes, this.popupContent.btn_label, this.popupContent.tenantData, this.popupContent.className);
        this.universalSearchPopUp.setContent(content)
      }
    });
    this.selectedReportSubscription$?.unsubscribe();
    this.selectedReportSubscription$ = this.reportService.selectedReport$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data) => {

      if (this.activatedRoute.snapshot.queryParams['rid']) {
        this.draw_btn_toggle = true;
      }
      else this.draw_btn_toggle = false;
      if (data.flag && data.type == 'new_report') {
        this.currentReportData = this.reportService.getCurrentReport();
        // subscription filter check
        let subsreport = this.reportService.userSubscription.find((type: any) => this.currentReportData.id == type.report_id)
        if (subsreport?.filter) {
          this.currentReportData.filter.map((e: any) => {
            const matchingUserFilter = subsreport.filter?.find((userFilter: any) => userFilter.parameter_name === e.parameter_name);
            if (matchingUserFilter) {
              if (matchingUserFilter?.default) {
                e['default'] = matchingUserFilter?.default;
              }
              e['dynamic_value'] = matchingUserFilter?.dynamic_value;
              e['required'] = matchingUserFilter?.required;
              e['map_filter'] = matchingUserFilter?.map_filter;
              e['type'] = matchingUserFilter?.type;
            }
          })
        }
        if (this.currentReportData) {
          this.seoMetupdates(this.currentReportData.label[1], 'reports');
          this.layerData = this.currentReportData.layer;
          if (!this.legend_data_by_group) this.legend_data_by_group = this.currentReportData.legend;
          this.mapLayers = this.currentReportData.map_layer;
          // let activeLayer = this.mapLayers.filter((item:any)=> item.display)
          // if(activeLayer) this.overlayLayers(activeLayer)
          // this.layerData = this.mapService.getLayerData(); /// for testing only
          // if (!this.transaction_layer_active) this.addlayer(3); // commented for testing // not required
        }
        this.reportName = this.reportService?.selectedRepored?.report_name;
        this.filterObj = this.currentReportData.filter //// Dynamic Filter impleentation from MI APplication
      }
    })
    this.drawPolySub$ = this.mapService.drawPolyToggle.subscribe((toggle: any) => {
      if (toggle) this.drawPolygon();
      else {
        if (this.polygon_create) this.drawPolygon();
        if (this.editableLayers) this.editableLayers.remove();
        if (this.drawControl) this.drawControl.remove();
        if (this.transaction_layer_active) {
          this.removeLayers();
        }
      }
    })

    this.mapService.propertyinfotoggle.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((toggle: boolean) => {
      if (toggle) {this.propertyinfoToggle = true;
        this.reportService.projectReportView = true;
      }else{
        this.propertyinfoToggle = false;
      }
    })

    this.activatedRoute.queryParams.subscribe((params: any) => {
      this.reportId = params['rid'];
      this.propertyinfoToggle = false;
      this.showProjectToggle = false;
      this.showAreaToggle = false;
      if (this.drawControl) this.drawControl.remove();
      this.removeCustomMaker();
    });

    this.mapService.selected_propertydetails.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((data: any) => {
      if (data) this.propertyInfo(data);
    })
    effect(() => this.TenantName = this.mapService.tenantName());
  }
  seoMetupdates(title: string = '', type: string = '') {
    let obj_label: string = this.reportService.selectedObjective?.obj_label?.[1] ? this.reportService.selectedObjective.obj_label[1] : '';
    let study_label: string = this.reportService.selectedStudies?.study_label?.[1] ? this.reportService.selectedStudies.study_label[1] : '';
    let reportTree = `${obj_label ? obj_label + ' > ' : ''} ${study_label ? study_label + ' > ' : ''} ${title}`;
    if (obj_label) this._googleAnalyticsService.trackEvent('button_click', { event_category: 'Generate Report', event_name: 'Button Click', event_label: 'Create Report', event_value: reportTree, screen_name: '' })
    this._seoService.updateTitle(`${GlobalContants.defaultTitleConstant.defaultTitle} | ${title}`)
  }


  ngOnInit(): void {
    this.mapService.universalSearchtoggle.subscribe(res => {
      if (!res){
        this.isOpenMapSearch = false;
        this.closeChip(true)
      }
    })
    if (isPlatformBrowser(this.platformId)) {
      if (this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId)) {
        this.mapCenter = [this.sharedService.getStoreValue(GlobalContants.StoreKey.latitude), this.sharedService.getStoreValue(GlobalContants.StoreKey.longitude)];
      }
      this.initMap();
    }
    this.getCurrentCity();
    this.NeighDetails$ = this.reportService.transactionPropertyDetails.subscribe((data) => {
      if (data) {
        // Check for neigh_uid
        if (data && data.neigh_uid) {
          this.showAreaToggle = true;
          this.neightInfo = data;
          this.showProjectToggle = false;
          this.projecttInfovar = {}
          // reset property
          this.selected_property = {};
          this.propertyinfoToggle = false;
          this.reportService.projectReportView = true;
        }
        // Check for proj_uid
        if (data && data.proj_uid) {
          this.showProjectToggle = true;
          this.reportService.projectReportView = true;
          this.projecttInfovar = data;
          this.showAreaToggle = false;
          this.neightInfo = {};
          // reset property
          this.selected_property = {};
          this.propertyinfoToggle = false;
        }
        if (data && !data.proj_uid && this.showProjectToggle)
          this.showProjectToggle = false;
      } else {
        //reset all
        this.projecttInfovar = {};
        this.showProjectToggle = false;
        this.neightInfo = {};
        this.showAreaToggle = false;
        // reset property
        this.selected_property = {};
        this.propertyinfoToggle = false;

        // reset tenant floor layout
        this.reportService.showTenantMap$.next(false);
        this.tenantPropertyInfo = null;

        // back to report from property
        this.reportService.projectReportView = false;
        this.reportService.reportView$.next(ReportsViewEnum.mapGridView)
      }
    });

    this.currentReportView$ = this.reportService.reportView$.subscribe((view: number) => {
      if (this.currentReportView != view) {
        if (this.map && view !== 0) this.map.closePopup();
        this.currentReportView = view;
        // on view change hide the property details and neigh and project details
        // this.reportService.transactionPropertyDetails.next(false)
      }
    });
    this.getRecentSearchedParcelDetail();

    this.reportService.loadCatchementDataapi$.subscribe((data: boolean) => {
      this.getSavedCatchment()
    })

  }
  // Remove the last marker if it exists(Page load & show on map click)
  // removeCustomMaker(flag:boolean=false){
  //   let that = this;
  //   if (that.lastMarker) {
  //     that.map.removeLayer(that.lastMarker);
  //     this.markerObjects.forEach(marker => {
  //       that.map.removeLayer(marker);
  //     });
  //     that.markerObjects = [];
  //     if(flag){
  //       this.TenantName='';
  //       that.showMultiLatLon = [];
  //       that.lastMarker = '';
  //     }
  //   }
  //   return that;
  // }
  removeCustomMaker(flag: boolean = false) {
    let that = this;
    if (that.lastMarker) {
      that.map.removeLayer(that.lastMarker);
      this.markerObjects.forEach(marker => {
        that.map.removeLayer(marker);
      });
      that.markerObjects = [];
    }
    if (that.tenantMarkers && flag) {
      that.map.removeLayer(that.tenantMarkers);
      if (that.individualTenantMarkers) that.map.removeLayer(that.individualTenantMarkers);
      this.TenantName = '';
      let time = new Date().getTime();
      this.mapService.selectedIndex.set(time)
      that.showMultiLatLon = [];
      that.tenantMarkers = '';
      that.individualTenantMarkers = ''
      that.addlayer();
      that.lastMarker = '';
      if (that.map.getZoom() > 15) {
        let legend = this.sharedService.getStoreValue(GlobalContants.StoreKey.legend);
        if (that.activatedRoute.snapshot.queryParams['rid'] && !that.activatedRoute.snapshot.queryParams['polygon']) that.multiBbox(that.map.getBounds().toBBoxString(), legend);
      }
    }
    return that;
  }

  getCurrentCity() {
    this.cityCode = this.storeService.get(GlobalContants.StoreKey.placeCode);
    this.sharedService.selectedCity$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((city: any) => {
      this.cityCode = this.storeService.get(GlobalContants.StoreKey.placeCode);
      this.reportService.showTenantMap$.next(false);
      this.mapCenter = [this.sharedService.getStoreValue(GlobalContants.StoreKey.latitude), this.sharedService.getStoreValue(GlobalContants.StoreKey.longitude)];
      if (this.map) this.map.setView(this.mapCenter, this.mapZoom);
      this.removeLayers();
      this.map.closePopup();
      if (this.map){
        if(this.map.hasLayer(this.wmsCityLayer)){
          this.map.removeLayer(this.wmsCityLayer);
          this.wmsCityLayer = ''
        }
         this.addWmsCityBorderHighlight();
      }
    });
  }

  private initMap(): void {
    var container = L.DomUtil.get('map');
    if (container && container['_leaflet_id'] != null) {
      container.remove();
      if (this.map) {
        this.map.off();
        this.map.remove();
      }
    }
    this.mapService.mapLayer_current.set('default');
    this.map = L.map('map', {
      zoomControl: false,
      maxZoom: 18,
      minZoom: 4
    }).setView(this.mapCenter, this.mapZoom);

    this.zoomControl = L.control.zoom({
      position: this.zoomControlPosition
    }).addTo(this.map);

    this.map.attributionControl.setPrefix('<a href="https://leafletjs.com/" target="_blank">Leaflet</a>');
    if (environment.is_production) {
      this.base_Layer = L.tileLayer(environment.jawgLayer, {
        attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>',
        maxZoom: 18,
        minZoom: 4,
        subdomains: 'abcd',
        zIndex: 1,
      }).addTo(this.map);
    } else {
      this.base_Layer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
        attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
        subdomains: 'abcd',
        maxZoom: 18,
        minZoom: 4,
        zIndex: 1,
      }).addTo(this.map);
    }
    this.map.on('zoom', (e: any) => {
      let mapZoom = this.map.getZoom();
      this.mapService.setZoom(mapZoom);
      if (mapZoom < 15) {
        if (this.lastMarker) {
          this.map.removeLayer(this.lastMarker);
        }
      }
      if (mapZoom < 16) {
        this.map.closePopup();
      }
      let Center = this.map.getCenter();
      this.mapService.setCentroid(`${Center.lat}-${Center.lng}`);
      // this.map.invalidateSize(true);//for reducing grey parts on map
    })

    this.mapFullScreen();

    if (this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId)) {
      this.mapEvntSubscriber();
    }
    this.addWmsCityBorderHighlight();
  }

  addWmsCityBorderHighlight(): void {
    const placeCode = this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode);
    const place_id = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId);
    let overlay_storename = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_gisdb`;
        let overlay_url = `${environment.geoestater}wms-layer?place_id=${place_id}&token=${environment.user_token}&store=${overlay_storename}`;
        this.wmsCityLayer = new L.tileLayer.wms(overlay_url, {
          layers: `${placeCode}_gisdb:city_layer`,
          srs: 'EPSG:900913',
          format: 'image/png',
          transparent: true,
          styles: 'overlay_Layer',
          width: 709,
          height: 768,
          zIndex: 12
        });
    this.wmsCityLayer.setParams({ env: `overlay_color:000000;overlay_stroke:2`});
    this.wmsCityLayer.addTo(this.map);
  }

  ngAfterViewInit(): void {

    this.allLeyars('');
  }

  allLeyars(legend: any) {
    let that = this;
    const moveEndObservable = fromEvent(that.map, 'moveend');
    that.moveEndSubscriptionMap$ = moveEndObservable.pipe(
      debounceTime(300)
    ).subscribe(event => {
      if (!that.activatedRoute.snapshot.queryParams['polygon'] && !that.TenantName) {
        if (that.map.getZoom() > 15) {
          if (!legend) legend = this.sharedService.getStoreValue(GlobalContants.StoreKey.legend);
          if (that.activatedRoute.snapshot.queryParams['rid'] && !that.activatedRoute.snapshot.queryParams['polygon']) that.multiBbox(that.map.getBounds().toBBoxString(), legend);
        } else {
          if (that.map.hasLayer(that.layer1)) that.map.removeLayer(that.layer1);
          if (that.map.hasLayer(that.layer2)) that.map.removeLayer(that.layer2);
          if (that.map.hasLayer(that.layer3)) that.map.removeLayer(that.layer3);
          if (that.map.hasLayer(that.layer4)) that.map.removeLayer(that.layer4);
        }
        that.map.invalidateSize(true);//for reducing grey parts on map
      }
    })
  }
  /**
   * multiBbox function used to convert BBox to polygon
   * @param bound viewports map Bounds
   * @param legend Leged
   */
  multiBbox(bound: any, legend: any) {

    const cords = bound.split(',');
    // console.log(cords)
    let maxLat = Number(cords[0]);
    let minLon = Number(cords[1]);
    let minLat = Number(cords[2]);
    let maxLon = Number(cords[3]);

    let centLon = (maxLon + minLon) / 2;
    let centLat = (maxLat + minLat) / 2;

    if (centLon - 180 > 0) {
      centLon -= 360;
    } else if (centLon + 180 < 0) {
      centLon += 360;
    }
    this.poly = 'POLYGON((' + minLat + ' ' + minLon + ', ' + maxLat + ' ' + minLon + ', ' + maxLat + ' ' + maxLon + ', ' + minLat + ' ' + maxLon + ', ' + minLat + ' ' + minLon + '))'

    let poly1 = 'POLYGON((' + centLat + ' ' + minLon + ', ' + maxLat + ' ' + minLon + ', ' + maxLat + ' ' + centLon + ', ' + centLat + ' ' + centLon + ', ' + centLat + ' ' + minLon + '))';
    let poly2 = 'POLYGON((' + minLat + ' ' + centLon + ', ' + centLat + ' ' + centLon + ', ' + centLat + ' ' + maxLon + ', ' + minLat + ' ' + maxLon + ', ' + minLat + ' ' + centLon + '))';
    let poly3 = 'POLYGON((' + centLat + ' ' + maxLon + ', ' + maxLat + ' ' + maxLon + ', ' + maxLat + ' ' + centLon + ', ' + centLat + ' ' + centLon + ', ' + centLat + ' ' + maxLon + '))';
    let poly4 = 'POLYGON((' + centLat + ' ' + minLon + ', ' + minLat + ' ' + minLon + ', ' + minLat + ' ' + centLon + ', ' + centLat + ' ' + centLon + ', ' + centLat + ' ' + minLon + '))';

    this.polys = [poly1, poly2, poly3, poly4];
    this.getJsonData('1', poly1, legend);
    this.getJsonData('2', poly2, legend);
    this.getJsonData('3', poly3, legend);
    this.getJsonData('4', poly4, legend);
  }
  /**
   * getJsonData function used to get parcel data based on polygon and applied filter
   * @param sn index of layer
   * @param poly polygon
   * @param legend legend color
   * @param type
   */
  getJsonData(sn: any, poly: any, legend: any, type: string = '') {
    this.mapWidth = this.mapIdentifier.nativeElement.offsetWidth;
    this.mapHeight = this.mapIdentifier.nativeElement.offsetHeight;
    let zoom = (!type) ? this.map.getZoom() : null;
    let localLegend = this.sharedService.getStoreValue(GlobalContants.StoreKey.legend)
    if (localLegend) legend = localLegend;
    let that = this;
    if (that.activatedRoute.snapshot.queryParams['rid']) {
      that.mapService.getGeoJsonLayerData(poly, legend, zoom).subscribe((layerdata: any) => {
        layerdata.data['features'] = (layerdata?.status == 200 && layerdata?.data?.g_ids) ? that.mapService.decryptKey(layerdata.data.g_ids) : null;
        delete layerdata.data.g_ids;
        if (layerdata?.data?.features) {
          let lyr: any;
          lyr = L.geoJSON(layerdata.data, {
            onEachFeature: function (feature: any, layer: any) {
              let property_category = (feature.properties?.property_category)?.toLowerCase();
              const assignedColors = Object.keys(legend)
                .filter(category => property_category?.includes(category))
                .map(category => legend[category]);
              let fillColor = (property_category && assignedColors?.length) ? assignedColors[0] : 'rgba(0, 0, 0, 0)';
              let color = that.currentReportData.map_layer?.find((item: any) => item.layer_name?.includes(that.layerEndPoint))?.color || '#ff5733';
              layer.setStyle({ fillColor: fillColor, color: color, weight: 0.6, fillOpacity: 1 });
              layer.on('mouseover', (e: any) => {
                let layerId = e.target.feature?.properties?.parcel_uid ? e.target.feature?.properties?.parcel_uid : e.target.feature?.properties?.property_uid;
                if (layerId) {
                  layer.setStyle({
                    weight: 2,
                    color: 'black',
                    dashArray: '2, 6',
                    noClip: true
                  });
                  layer.bringToFront()
                }
              }).on('click', async (e: any) => {
                that.removeExistingLoader();

                let properties = e.target.feature?.properties;
                let content;
                const center = centroid(e.target.feature?.geometry);
                let latlon = center.geometry.coordinates;
                const loader = L.divIcon({
                  className: 'parcel-loader',
                  html: '<div class="spinner"></div>',
                  iconSize: [16, 16],
                });
                const loaderMarker = L.marker([latlon[1], latlon[0]], { icon: loader }).addTo(that.map);
                // Get API to get map-popup property data
                if (!properties || !properties.property_uid) {
                  console.warn("Property UID is missing.");
                  return;
                }
                try {
                  const propertyData: any = await that.mapService.getParcelInfo(properties.property_uid);
                  that.map.removeLayer(loaderMarker);
                  if (propertyData.status == 200 && propertyData?.data?.length) {
                    let options = that.mapService.popupOptions(e, that.mapWidth, that.mapHeight);
                    properties = propertyData.data[0];
                    if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
                      content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes, '', '', options.class_name)
                      that.popupContent = { type:'transaction', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes, className:options.class_name }
                    } else {
                      content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes, '', '', options.class_name);
                      that.popupContent = { type:'property', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes, className:options.class_name }
                    }
                    //
                    that.mapPopupInstance = L.popup({ autoPan: false, offset: [options.x, options.y], className: options.class_name })
                      .setContent(content)
                      .setLatLng(e.latlng)
                      // .openOn(that.map)
                      // .update();
                      that.mapPopupInstance.openOn(that.map);
                  } else {
                    that.map.removeLayer(loaderMarker);
                    console.warn("No Data Available");
                  }
                } catch (error) {
                  that.map.removeLayer(loaderMarker);
                  console.error("Failed to fetch property data:", error);
                }
              }).on('mouseout', (e: any) => {
                layer.setStyle({ color: color, weight: 1, dashArray: null });
              })
            }
          });
          switch (sn) {
            case '1':
              if (that.map.hasLayer(that.layer1)) that.map.removeLayer(that.layer1);
              that.layer1 = lyr;
              that.map.addLayer(that.layer1);
              break;
            case '2':
              if (that.map.hasLayer(that.layer2)) that.map.removeLayer(that.layer2);
              that.layer2 = lyr;
              that.map.addLayer(that.layer2);
              break;
            case '3':
              if (that.map.hasLayer(that.layer3)) that.map.removeLayer(that.layer3);
              that.layer3 = lyr;
              that.map.addLayer(that.layer3);
              break;
            case '4':
              if (that.map.hasLayer(that.layer4)) that.map.removeLayer(that.layer4);
              that.layer4 = lyr;
              that.map.addLayer(that.layer4);
              break;
            default:
          }
        } else {
          if (sn == 1 && that.map.hasLayer(that.layer1)) that.map.removeLayer(that.layer1);
          if (sn == 2 && that.map.hasLayer(that.layer2)) that.map.removeLayer(that.layer2);
          if (sn == 3 && that.map.hasLayer(that.layer3)) that.map.removeLayer(that.layer3);
          if (sn == 4 && that.map.hasLayer(that.layer4)) that.map.removeLayer(that.layer4);
        }
      })
    }
  }
/**
 * removeExistingLoaderfunction used to removed parcel loader 
 */
  removeExistingLoader() {
    // Ensure no lingering loaders exist on the map
    this.map.eachLayer((layer: any) => {
      if (layer instanceof L.Marker && layer.options.icon?.options.className === 'parcel-loader') {
        this.map.removeLayer(layer);
      }
    });
  }
  viewMultiCluster(coordinates: any, lastZoom: number = 0) {
    let that = this;
    that.mapService.tenantDataState$.next('')
    if (that.tenantMarkers)
      that.tenantMarkers.remove();
    if (that.individualTenantMarkers)
      that.individualTenantMarkers.remove();
    that.tenantMarkers = new L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: (cluster: any) => {
        let cluster_count = 0;
        cluster.getAllChildMarkers().forEach((marker: any) => {
          cluster_count += marker.customData.value;
        })
        return L.divIcon({
          className: 'clusterdiv-icon', html: '<b style="position:relative;top:9px">' + cluster_count + '</b>', iconSize: [30, 30]
        });
      }
    })

    that.individualTenantMarkers = L.layerGroup();
    for (let i = 0; i < coordinates.length; i++) {
      let count = 1;
      let shop_count = (coordinates[i].shop_count > 1) ? coordinates[i].shop_count : '';
      let badgeClass = (coordinates[i].is_layout) ? "tenant-labels_badge" : "tenant-labels";
      let marker: any = L.marker(new L.LatLng(coordinates[i].lat, coordinates[i].lon), {
        icon: L.icon({
          iconUrl: 'assets/images/tenant_1.svg',
          iconSize: [27, 33],
          iconAnchor: [14, 13], // point of the icon which will correspond to marker's location (optional)
          className: ['tenant-icon_']
        })
      }).bindTooltip(`${shop_count}`, {
        permanent: true,
        direction: 'center',
        className: badgeClass
      }).on('click', (e: any) => {
        let content = that.mapService.popup_content('tenant', coordinates[i], that.currentReportData?.allowed_attributes, '', coordinates);
        that.popupContent = { type:'tenant', propertiesdata: coordinates[i], allowedAttributes: that.currentReportData?.allowed_attributes , tenantData: coordinates}
        that.mapPopupInstance = L.popup({ autoPan: true, keepInView: true })
          .setContent(content)
          .setLatLng(e.latlng)
          // .openOn(that.map);
          that.mapPopupInstance.openOn(that.map)
        if (that.map.getZoom() <= 16) that.map.setView(new L.LatLng(coordinates[i].lat, coordinates[i].lon), 17);
        that.steCurrentBounds();
      });
      marker.customData = {
        value: count
      };
      that.tenantMarkers.addLayer(marker);
      that.individualTenantMarkers.addLayer(marker);
    }
    that.tenantMarkers.addTo(that.map);
    this.updateMarkers('tenant');
    if (lastZoom && !this.activatedRoute.snapshot.queryParams['amenities']) that.map.setZoom(lastZoom)
    that.tenantMarkers.on('clusterclick', (a: any) => {
      const clusterCenter = a.layer.getBounds().getCenter();
      if (that.map.getZoom() <= 16) that.map.setView(clusterCenter, 17, {
        "animate": true,
        "pan": {
          "duration": 0.5
        }
      });
    });

  }
  updateMarkers(type: string) {
    let that = this;
    if (type == 'tenant') {
      if (that.map.getZoom() >= 17) {
        if (that.map.hasLayer(that.tenantMarkers)) {
          that.map.removeLayer(that.tenantMarkers);
          that.map.addLayer(that.individualTenantMarkers);
        }
      } else {
        if (that.map.hasLayer(that.individualTenantMarkers)) {
          that.map.removeLayer(that.individualTenantMarkers);
          that.map.addLayer(that.tenantMarkers);
        }
      }
    }
    if (type == 'amenities') {
      // if (that.map.getZoom() >= 17) {
      if (that.amenitiesMarkers)
        that.amenitiesMarkers.remove();
      if (that.circleGroup)
        that.circleGroup.remove();
      // }
    }
  }
  mapEvntSubscriber() {
    this.mapService.mapZoom.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((zoom: any) => {
      this.map.setZoom(zoom)
      this.updateMarkers('tenant');
      if (zoom >= 15) {
        let that = this;
        if (!that.showMultiLatLon?.length && that.lastMarker) { that.lastMarker.addTo(that.map) }
        // if(that.showMultiLatLon?.length > 0) this.viewMultiCluster(this.showMultiLatLon)
        // else if(that.lastMarker){that.lastMarker.addTo(that.map)}
      } else {
        // this.removeCustomMaker();
      }
    });
    /// Re-centering of Map when Map-Div width is being changed
    let mapDiv = document.getElementById("map") as HTMLElement;
    this.resizeObserver = new ResizeObserver(() => {
      this.map.invalidateSize();
    });
    this.resizeObserver.observe(mapDiv);

    this.mapService.basemapLayer.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((baseLayer: any) => {
      this.basemapSwitcher(baseLayer);
    })
    /**
     * Amenities filter
     */
    this.amenitiesItemListSub$?.unsubscribe();
    this.amenitiesItemListSub$ = this.mapService.amenitiesItemList.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((amenities: any) => {
      this.circleAnenities = [];
      if (amenities) {
        this.map.closePopup();
        const items = amenities.split(",");
        const resultArray = items.map((item: any) => {
          const parts = item.split("*");
          return {
            property_name: parts[0],
            uid: parts[1],
            distance: parts[2],
            lat: parts[3],
            lon: parts[4]
          };
        });
        // const latLngs = resultArray.map((point: any) => L.latLng(point.lat, point.lon));
        // const bounds = L.latLngBounds(latLngs);
        // this.map.fitBounds(bounds, {
        //   padding: [30, 30] // Adds padding to the bounds so points aren't right at the edge
        // });
        this.viewAmenitiesCluster(resultArray)
      } else {
        this.updateMarkers('amenities');
      }
    });

    this.mapCoordinatesSub$?.unsubscribe()
    this.mapCoordinatesSub$ = this.mapService.mapCoordinates.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((coordinates_data: any) => {
      this.map.closePopup();
      if (coordinates_data?.multiLatLon) {
        this.mapService.tenantName.set(coordinates_data?.tenant_name);
        this.TenantName = coordinates_data?.tenant_name;
        const latLngs = coordinates_data?.data.map((point: any) => L.latLng(point.lat, point.lon));
        const bounds = L.latLngBounds(latLngs);
        this.map.fitBounds(bounds, {
          padding: [30, 30] // Adds padding to the bounds so points aren't right at the edge
        });
      } else {
        this.TenantName = '';
        this.map.setView(coordinates_data.coordinates, coordinates_data.zoom, {
          "animate": true,
          "pan": {
            "duration": 0.5
          }
        });
      }
      let that = this;
      if (coordinates_data?.layer_name == "property") {
        const properties = coordinates_data?.data;
        let that = this;
        if (properties) {
          setTimeout(() => {
            let latLon = [properties.lat, properties.lon];
            let content;
            if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
              content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes)
              that.popupContent = { type:'transaction', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes }
            } else {
              content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes);
              that.popupContent = { type:'property', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes}
            }
            that.mapPopupInstance = L.popup()
              .setContent(content)
              .setLatLng(latLon)
              // .openOn(that.map);
              that.mapPopupInstance.openOn(that.map)
          }, 501)
        }
      }
      // Remove the last marker if it exists
      this.showMultiLatLon = [];
      this.removeCustomMaker();
      if (!coordinates_data?.multiLatLon) {
        // Add new marker
        that.lastMarker = L.marker(new L.LatLng(coordinates_data.coordinates[0], coordinates_data.coordinates[1]), {
          icon: L.divIcon({
            className: ['animate-point']
          })
        });
        that.lastMarker.addTo(that.map);
      }
      if (coordinates_data?.multiLatLon) {
        // Add new marker for each set of coordinates
        this.showMultiLatLon = coordinates_data?.data;
        this.viewMultiCluster(coordinates_data?.data);
      }
    })
    this.selectedReportSub$?.unsubscribe();
    this.selectedReportSub$ = this.reportService.selectedReport$.pipe(
      debounceTime(800),
    ).subscribe(isReport => {
      this.map.closePopup();
      this.removeLayers(isReport);
      this.isReport = isReport.flag
      if (isReport.flag) {

        // if (this.layerData) this.addlayer();
        if (this.legend_data_by_group) {
          this.addlayer();
          let activeLayer = this.mapLayers?.filter((item: any) => item.display)
          if (activeLayer) this.mapService.overlay_mapLayer.next(activeLayer)
        }
        this.transaction_layer_active = false;
        this.mapService.transaction_LayerActive.next(false);
        if (this.transaction_Popup) this.transaction_Popup.forEach((popup: any) => { popup.remove() });
      } else {
        this.transaction_layer_active = true;
        this.mapService.transaction_LayerActive.next(true);
        // 'moveend' event
        this.moveEndSubscription3$?.unsubscribe();
        const moveEndObservable = fromEvent(this.map, 'moveend');
        this.moveEndSubscription3$ = moveEndObservable
          .pipe(debounceTime(30)) // 30 milliseconds debounce time
          .subscribe(event => {
            let Center = this.map.getCenter();
            this.mapService.setCentroid(`${Center.lat}-${Center.lng}`);
            this.map.invalidateSize();
          });
      }
    });

    this.sharedService.language$.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (!this.transaction_layer_active)
        if (this.legend_data_by_group) this.addlayer();
      // if (this.layerData) this.addlayer();

    });

    this.routeSub$ = this.activatedRoute.queryParams.subscribe((params) => {
      this.loadCatchmentShow = this.reLoadCatchmentShow;
      if (params['polygon']) {
        this.polygon_create = false
      }
      this.checkPolygonDraw();
      let that = this;
      if (that.lastMarker) {
        that.map.removeLayer(that.lastMarker);
      }
    })
  }
  viewAmenitiesCluster(coordinates: any, lastZoom: number = 0) {
    let that = this;
    if (that.amenitiesMarkers)
      that.amenitiesMarkers.remove();
    if (that.circleGroup)
      that.circleGroup.remove();
    that.amenitiesMarkers = L.layerGroup();
    that.circleGroup = L.layerGroup();
    for (let i = 0; i < coordinates.length; i++) {
      let marker: any = L.marker(new L.LatLng(coordinates[i].lat, coordinates[i].lon), {
        icon: L.icon({
          iconUrl: 'assets/images/amenities/amenity_icon.svg',
          // iconUrl: 'assets/images/tenant_1.svg',
          iconSize: [27, 33],
          iconAnchor: [14, 27], // point of the icon which will correspond to marker's location (optional)
          className: ['tenant-icon_']
        })
      }).on('mouseover', (e: any) => {
        let content = that.mapService.createPoup('amenities', coordinates[i]);
        L.popup({ autoPan: true, keepInView: true, className: 'amenities_popup', closeButton: false })
          .setContent(content)
          .setLatLng(e.latlng)
          .openOn(that.map);
      }).on('mouseout', (e: any) => {
        that.map.closePopup();
      });
      let circleAnenities = L.circle([coordinates[i].lat, coordinates[i].lon], {
        radius: coordinates[i].distance,
        color: '#dddddd',
        fillOpacity: 0
      })
      that.circleAnenities.push(circleAnenities)
      that.circleGroup.addLayer(circleAnenities);
      that.circleGroup.addTo(that.map);

      that.amenitiesMarkers.addLayer(marker);
    }
    that.amenitiesMarkers.addTo(that.map);
    that.fitMapToCircles(that.circleAnenities);
  }
  /**
   * fitMapToCircles use to mapzoom
   * @param circles List of amenities redius circle
   */
  fitMapToCircles(circles: any): void {
    const bounds = L.latLngBounds([]);

    circles.forEach((circle: any) => {
      bounds.extend(circle.getBounds());
    });

    this.map.fitBounds(bounds, { padding: [20, 20] }); // Add padding for better display
  }
  /**
   * checkPolygonDraw function to referesh/check polygon
   */
  checkPolygonDraw() {
    let that = this;
    if (that.activatedRoute.snapshot.queryParams['polygon']) {
      if (!that.polygon_create) {
        if (that.editableLayers) that.editableLayers.remove();
        that.editableLayers = new L.FeatureGroup().addTo(that.map);
        let layer = JSON.parse(that.activatedRoute.snapshot.queryParams['polygon']);
        that.drawnPolygons = layer.features;
        that.polygon_WKT = layer.features;
        that.refreshPolygoncount();
      }
      that.polygon_create = true;
      that.map.fitBounds(that.editableLayers.getBounds());
      if (this.polygon_create) {

        this.createDrawLayer();
        that.map.off(L.Draw.Event.CREATED);
        that.map.on(L.Draw.Event.CREATED, (e: any) => {
          let layer = e.layer;
          let type = e.layerType;
          // if (!layer_created) {
          if (type == 'circle') {
            layer = that.circleToPolygon(layer);
          }
          let temp_layer = new L.FeatureGroup({ interactive: false }).addTo(that.map);
          temp_layer.addLayer(layer);
          temp_layer.remove();
          that.polygon_chip = false;

          that.editableLayers.addLayer(layer);
          that.drawnPolygons = that.editableLayers.toGeoJSON()?.features;

          if (that.drawnPolygons.length >= 4) {
            this.disableDrawLayer()
          } else {
            this.enableDrawLayer();
          }
          let viewMap = that.editableLayers.getBounds();
          if (viewMap.isValid()) {
            that.polygon_WKT = JSON.stringify(that.editableLayers.toGeoJSON());
            that.mapZoom = that.map.getZoom();
            that.mapCenter = that.map.getCenter();
            that.router.navigate([], {
              queryParams: { polygon: that.polygon_WKT, zoom: that.mapZoom, centroid: that.mapService.getCentroid() },
              queryParamsHandling: 'merge',
            });
          } else {
            that.polygon_create = false;
            that.mapService.drawPolyToggle.next(that.polygon_create);
          }
        }).on('draw:drawstart', (e: any) => {
          that.polygon_chip = true;
          that.removeHighlightDrawCatchmentIcon()
          that.activeDrawCatchmentIcon(e.layerType)
        }).on('draw:drawstop', function (e: any) {
          that.polygon_chip = false;
          that.removeHighlightDrawCatchmentIcon()
        });
      }

      if (that.drawnPolygons.length >= 4) {
        this.disableDrawLayer()
      }
    }
    //// For using same olygon on every report/Transaction Page
    // else if (that.polygon_create && that.polygon_WKT && that.polygon_WKT.length > 1) {
    //   that.router.navigate([], {
    //     queryParams: { polygon: that.polygon_WKT },
    //     queryParamsHandling: 'merge',
    //   });
    // }
    else {
      that.polygon_create = false;
      if (that.drawControl) {
        that.drawControl.remove()
      }
      that.polygon_WKT = null;
      that.drawnPolygons = [];
      if (that.editableLayers) that.editableLayers.remove();
      if (that.catchment_Markers) that.catchment_Markers.remove();
      /// set zoom and center from the URL
      if (this.activatedRoute.snapshot.queryParams['zoom']) {
        this.mapService.setZoom(Number(this.activatedRoute.snapshot.queryParams['zoom']))
        this.mapZoom = Number(this.activatedRoute.snapshot.queryParams['zoom']);
        if (!this.activatedRoute.snapshot.queryParams['amenities']) this.map.setZoom(this.mapZoom);
      }
      if (this.activatedRoute.snapshot.queryParams['centroid'] && this.map) {
        this.map.setView(this.activatedRoute.snapshot.queryParams['centroid'].split('-'), Number(this.activatedRoute.snapshot.queryParams['zoom']));
        this.mapCenter = [this.activatedRoute.snapshot.queryParams['centroid'].split('-')];
        this.mapService.setCentroid(this.activatedRoute.snapshot.queryParams['centroid'])
      }
    }
  }

  basemapSwitcher(value?: string) {
    if (this.base_Layer)
      this.map.removeLayer(this.base_Layer);

    if (value == 'satellite') {
      this.base_Layer = L.tileLayer('https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}', {
        maxZoom: 18,
        minZoom: 4,
        zIndex: -1,
        subdomains: ['mt0', 'mt1', 'mt2', 'mt3'],
      })
    } else {
      if (environment.is_production) {
        this.base_Layer = L.tileLayer(environment.jawgLayer, {
          attribution: '<a href="http://jawg.io" title="Tiles Courtesy of Jawg Maps" target="_blank">&copy; <b>Jawg</b>Maps</a> &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>',
          maxZoom: 18,
          minZoom: 4,
          zIndex: -1,
          subdomains: 'abcd'
        });
      } else {
        this.base_Layer = L.tileLayer('https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png', {
          attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors &copy; <a href="https://carto.com/attributions">CARTO</a>',
          subdomains: 'abcd',
          maxZoom: 18,
          minZoom: 4,
          zIndex: -1,
        })
      }
    }
    this.base_Layer.addTo(this.map);
  }

  addlayer() {
    this.legendLayerSub$?.unsubscribe();
    let that = this;
    let queryParam = this.reportService.queryParams;
    if (queryParam?.year) {
      this.layer_year = queryParam?.year.split(',').map(Number)
        .sort(function (a: any, b: any) { return a - b }).pop();
    } else if (!queryParam?.year) {
      this.layer_year = new Date().getFullYear();
    }
    // let legend_data: any = that.layerData?.legend.filter((r: any) => r.display == true) || [];
    let legend_data: any = that.legend_data_by_group?.filter((r: any) => r.display == true) || [];
    let place_id = that.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId);
    // let store_name = that.layerData?.store_name;
    // let layer_name = `${that.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${that.layerData?.layer_name}_${this.layer_year}`;
    let store_name = 'MI';//that.layerData?.store_name;
    let layer_name = `${store_name}:${that.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerEndPoint}`;
    let url = `${environment.geoestater}wms-layer?place_id=${place_id}&token=${environment.user_token}&store=${store_name}`;
    // if(that.TenantName){
    //   that.addoriginalWMSlayer(url, layer_name);
    //   return;
    // }
    that.layerFilter();
    let filter_polygon = null;
    if (!that.polygon_create) {
      if (that.activatedRoute.snapshot.queryParams['rid']) {
        that.getSavedCatchment()
        that.mapService.getReportCount(filter_polygon).subscribe((layerdata: any) => {
          let data = layerdata.data;
          that.reportCountLayer(data);

        })
      }
    }

    // that.legendLayerSub$ = that.mapService.legendLayer.pipe(
    that.legendLayerSub$ = that.mapService.legendLayerGroup.pipe(
      takeUntil(this.unsubscribe$),
      distinctUntilChanged()
    ).subscribe((data: any) => {
      legend_data = data;
      that.addWMSlayer(legend_data, url, `${store_name}:${layer_name}`);
      that.addPbfLayer(store_name, layer_name);
    });
    // let counter = 0;//added for testing
    that.overlayMapLayerSubscription$?.unsubscribe();
    that.overlayMapLayerSubscription$ = that.mapService.overlay_mapLayer.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((layer: any) => {
      that.overlayLayers(layer)
    })
    // that.addWMSlayer(legend_data, url, `${store_name}:${layer_name}`);
    that.addPbfLayer(store_name, layer_name);
    that.addoriginalWMSlayer(url, layer_name);
    // that.addParcelPbfLayer(store_name, '', '');
    that.mapLegendmenu();
    this.map_spinner = false;
  }

  overlayLayers(layer: any) {
    let that = this;
    let place_id = that.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId);
    if (that.overlay_Layer) that.overlay_Layer.remove();
    if (that.Pbf_OverlayLayer) that.Pbf_OverlayLayer = [];
    that.overlay_Layer = new L.layerGroup();
    let property_details_pbf = layer?.find((item: any) => item.layer_name?.includes(that.layerEndPoint));
    if (!property_details_pbf) {
      if (this.parcelLayerPbf) // Property legend layer
        this.map.removeLayer(this.parcelLayerPbf);
    }
    layer.forEach((overlay: any, index: any) => {
      if (overlay?.type == 'wms') {
        let overlay_storename = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${overlay.store_name}`;
        let overlay_url = `${environment.geoestater}wms-layer?place_id=${place_id}&token=${environment.user_token}&store=${overlay_storename}`;
        let layerName = `MI:${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerEndPoint}`;
        let wms_layer = new L.tileLayer.wms(overlay_url, {
          layers: overlay.layer_name,
          srs: 'EPSG:900913',
          format: 'image/png',
          transparent: true,
          styles: 'overlay_Layer',
          width: 709,
          height: 768,
          zIndex: 12
        });
        wms_layer.setParams({ env: `overlay_color:${overlay?.color ? overlay.color.replaceAll("#", "") : ''}` });
        wms_layer.addTo(that.overlay_Layer);
      }
      else {
        that.addPbfOverlayLayer(overlay, index);
      }
    })
    that.overlay_Layer.addTo(that.map);
    if (that.overlay_mapLayer_current().layer_name == 'neighbourhoods' && that.overlay_mapLayer_current()?.display) that.map.setZoom(13)
  }
  steCurrentBounds() {
    let mapbounds = this.map.getBounds();
    this.mapService.mapCurrentMapState.set(mapbounds);
    this.mapService.mapCurrentMapStateZoom.set(this.map.getZoom());
  }
  mapLayerZoomlimit() {
    let that = this;
    if (that.TenantName) {
      if (that.report_countLayer) that.map.removeLayer(that.report_countLayer);
      if (that.parcelLayerWms) that.map.removeLayer(that.parcelLayerWms);
      if (that.pbfParcelLayer) // property pbf layer (parcel boundry)
        that.map.removeLayer(that.pbfParcelLayer);
      // Remove layer if tanent exesist ===
      if (that.map.hasLayer(that.layer1)) that.map.removeLayer(that.layer1);
      if (that.map.hasLayer(that.layer2)) that.map.removeLayer(that.layer2);
      if (that.map.hasLayer(that.layer3)) that.map.removeLayer(that.layer3);
      if (that.map.hasLayer(that.layer4)) that.map.removeLayer(that.layer4);
      return;
    }
    if (!that.polygon_create) { /// Initial WMS Zoom Level logic
      if (that.map.getZoom() >= 16) {
        if (that.report_countLayer) that.map.removeLayer(that.report_countLayer);
        if (that.parcelLayerWms) that.map.addLayer(that.parcelLayerWms);
      }
      else {
        if (that.map.hasLayer(that.parcelLayerWms)) that.map.removeLayer(that.parcelLayerWms);
        that.map.addLayer(that.report_countLayer);
      }
    } else {
      // that.map.removeLayer(that.report_countLayer);
      if (this.polygon_WKT?.length > 0 || that.map.getZoom() >= 16)
        that.map.removeLayer(that.report_countLayer);
      else that.map.addLayer(that.report_countLayer);
      if (that.parcelLayerWms) that.map.addLayer(that.parcelLayerWms);
      // if(that.parcelLayerPbf) that.map.addLayer(that.parcelLayerPbf);
    }
  }

  removeLayers(isReportChange: any = '') {
    this.TenantName = '';
    if (this.amenitiesMarkers)
      this.amenitiesMarkers.remove();
    if (this.circleGroup)
      this.circleGroup.remove();
    if (this.tenantMarkers)
      this.tenantMarkers.remove();
    if (this.individualTenantMarkers)
      this.individualTenantMarkers.remove();
    let report_type = isReportChange && isReportChange?.type;//new_report
    if (this.Orig_parcelLayerWms) // Property layer
      this.map.removeLayer(this.Orig_parcelLayerWms);
    if (this.parcelLayerWms) // Property legend layer
      this.map.removeLayer(this.parcelLayerWms);
    if (this.pbfParcelLayer) // property pbf layer (parcel boundry)
      this.map.removeLayer(this.pbfParcelLayer);
    if (this.map.hasLayer(this.report_countLayer)){ // report count
      this.map.removeLayer(this.report_countLayer);
      this.report_countLayer = null;
    }
    if (report_type == 'new_report') {
      if (this.parcelLayerPbf) // Property legend layer
        this.map.removeLayer(this.parcelLayerPbf);
      if (this.overlay_Layer) // pbf and WMS layer group (block layer == wms layer/ neigh layer == pbf)
        this.overlay_Layer.remove();
      if (this.Pbf_OverlayLayer) // combination of markeer and bounry layer for pbf overlay (neighbourhoods and project )
        this.Pbf_OverlayLayer = [];
      if (this.projectLayer) // Boundry layers
        this.projectLayer.remove();

      if (this.map && !this.polygon_create) this.map.setZoom(10);
    }
  }

  addoriginalWMSlayer(url: string, layername: string) {
    return;
    if (this.Orig_parcelLayerWms)
      this.map.removeLayer(this.Orig_parcelLayerWms);

    this.Orig_parcelLayerWms = new L.tileLayer.wms(url, {
      layers: layername,
      styles: 'boundary_lines',
      srs: 'EPSG:900913',
      format: 'image/png',
      transparent: true,
      width: 709,
      height: 768,
      zIndex: 1
    }).addTo(this.map);
  }

  addWMSlayer(legend_data: any = [], url: string, layername: string) {
    let that = this;
    if (that.parcelLayerWms)
      that.map.removeLayer(that.parcelLayerWms);
    if (that.TenantName) return;
    that.parcelLayerWms = new L.layerGroup();

    legend_data.forEach((filter: any, index: any) => {
      let layerName = `MI:${that.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${that.layerEndPoint}`;
      let layer = new L.tileLayer.wms(url, {
        layers: layerName,
        srs: 'EPSG:900913',
        format: 'image/png',
        transparent: true,
        version: '1.1.0',
        minZoom: 15,
        maxZoom: 23
      });
      if (index == 0) {
        // layer.setParams({ env: 'myCol:' + select_legend });
        // if (filter.type != 'like') layer.setParams({ styles: `style_Layer_${filter.type}` });
        // else layer.setParams({ styles: `style_Layer_${filter.legend}` });
        // if (this.cql_param.length > 6 && !this.polygon_create) { layer.setParams({ cql_filter: this.cql_param.slice(5) }); }
        // else if (this.cql_param.length > 6 && this.polygon_create) { layer.setParams({ cql_filter: this.cql_param }); }
      } else if (index == 1) {
        // layer.setParams({ env: 'myCol:' + select_legend });
        // if (filter.type != 'like') layer.setParams({ styles: `style_Layer2_${filter.type}` });
        // else layer.setParams({ styles: `style_Layer2_${filter.legend}` });
        // if (this.cql_param.length > 6 && !this.polygon_create) { layer.setParams({ cql_filter: this.cql_param.slice(5) }); }
        // else if (this.cql_param.length > 6 && this.polygon_create) { layer.setParams({ cql_filter: this.cql_param }); }
      } else if (index == 2) {
        // layer.setParams({ env: 'myCol:' + select_legend });
        // if (filter.type != 'like') layer.setParams({ styles: `style_Layer1_${filter.type}` });
        // else layer.setParams({ styles: `style_Layer1_${filter.legend}` });
        // if (this.cql_param.length > 6 && !this.polygon_create) { layer.setParams({ cql_filter: this.cql_param.slice(5) }); }
        // else if (this.cql_param.length > 6 && this.polygon_create) { layer.setParams({ cql_filter: this.cql_param }); }
      }
      layer.addTo(this.parcelLayerWms);
    })

    this.toggle_LayerFilter();
    this.parcelLayerWms.addTo(this.map)
  }

  addPbfLayer(store_name: string, layer_name: string) {
    return;
    let layerName = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerEndPoint}_${this.layer_year}`;
    console.log(store_name, layer_name)
    if (this.pbfParcelLayer)
      this.map.removeLayer(this.pbfParcelLayer);
    if (this.TenantName) return;
    let that = this;
    var vectorTileOptions = {
      interactive: true,
      rendererFactory: L.canvas.tile,
      // filtername: `${layer_name}:year:=:${that.layer_year}`,
      onEachFeature: function (feature: any, featureLayer: any, vtLayer: any, tileCoords: any) { },
      minZoom: 16, //16
      maxZoom: 25, //21
      zIndex: 9999,
      getFeatureId: function (feature: any) {
        let layerId = feature?.properties?.parcel_uid ? feature?.properties?.parcel_uid : feature?.properties?.property_uid;
        return layerId;
      },
      vectorTileLayerStyles: {
        [`${layer_name}`]: function (properties: any, zoom: any) {
          return that.pbfStyle_nonfilter;
        },
      },
    };
    that.pbfParcelLayer = L.vectorGrid.protobuf(
      `${environment.geoestater}pbf-layer?store=${store_name}&layer=${layer_name}&token=${environment.user_token}&points={z}/{x}/{-y}.pbf`,
      vectorTileOptions
    ).on('click', (e: any) => {
      let properties = e.layer?.properties;
      let content;
      // if (this.currentReportData && this.currentReportData?.name === 'transactions') {
      if (this.currentReportData && this.currentReportData?.name.includes('transaction')) {
        content = this.mapService.popup_content('transaction', properties, this.currentReportData?.allowed_attributes)
      } else {
        content = this.mapService.popup_content('property', properties, this.currentReportData?.allowed_attributes);
      }
      if (e.latlng) {
        if (this.polygon_create) {
          if (e.layer.properties.wkt) {
            let wkt = wktToGeoJSON(e.layer.properties.wkt);
            let feat_bound = L.geoJson(wkt).getBounds();
            let polygon_para_condition = '';
            let lyr = this.editableLayers.getLayers()[0];
            if (this.drawnPolygons.length > 1) {
              for (let i = 0; i < lyr.getLayers().length; i++) {
                polygon_para_condition += `|| ${lyr.getLayers()[i].getBounds().intersects(feat_bound)} `
              }
            } else polygon_para_condition += `|| ${lyr?.getBounds().intersects(feat_bound)} `
            polygon_para_condition = polygon_para_condition.slice(2);
            if (eval(polygon_para_condition)) {
              L.popup({ autoPan: true, keepInView: true })
                .setContent(content)
                .setLatLng(e.latlng)
                .openOn(this.map);
            }
          }
        } else {
          L.popup({ autoPan: true, keepInView: true })
            .setContent(content)
            .setLatLng(e.latlng)
            .openOn(this.map);
        }
      }

    }).on('mouseover', (e: any) => {
      let layerId = e.layer?.properties?.parcel_uid ? e.layer?.properties?.parcel_uid : e.layer?.properties?.property_uid;
      if (that.polygon_create) {
        if (e.layer.properties.wkt) {
          let wkt = wktToGeoJSON(e.layer.properties.wkt);
          let feat_bound = L.geoJson(wkt).getBounds();
          let polygon_para_condition = '';
          let lyr = this.editableLayers.getLayers()[0];
          if (this.drawnPolygons.length > 1) {
            for (let i = 0; i < lyr.getLayers().length; i++) {
              polygon_para_condition += `|| ${lyr.getLayers()[i].getBounds().intersects(feat_bound)} `
            }
          } else { if (lyr) polygon_para_condition += `|| ${lyr?.getBounds().intersects(feat_bound)} ` }
          polygon_para_condition = polygon_para_condition.slice(2);
          if (eval(polygon_para_condition)) {
            that.pbfParcelLayer.setFeatureStyle(layerId, that.pbfStyle_filter);
          } else {
            that.pbfParcelLayer.setFeatureStyle(layerId, that.pbfStyle_nonfilter);
          }
        }
      } else {
        that.pbfParcelLayer.setFeatureStyle(layerId, that.pbfStyle_filter);
      }
    }).on('mouseout', (r: any) => {
      let highlight = r.layer?.properties?.parcel_uid ? r.layer?.properties?.parcel_uid : r.layer?.properties?.property_uid;
      that.pbfParcelLayer.resetFeatureStyle(highlight);
      // that.map.closePopup();
    }).addTo(that.map);

    that.layerFilter(); //// Map Filter to apply on PBF Layer

  }

  /**
   * parcels PBF Layer
   * @param data
   * @param index
   */
  addParcelPbfLayer(store_name: string, layer_name: string, data: any) {
    store_name = data?.store_name ? data?.store_name : 'MI';
    layer_name = data?.layer_name ? `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${data?.layer_name}` :
      `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerEndPoint}`;
    if (this.parcelLayerPbf) // Property legend layer
      this.map.removeLayer(this.parcelLayerPbf);
    let that = this;
    let layer_color = data?.color ? data.color : 'rgb(255, 87,51)';
    let layer_minzoom = data?.minZoom ? data.minZoom : 15;
    let layer_maxzoom = data?.maxZoom ? data.maxZoom : 21;
    var vectorTileOptions = {
      interactive: true,
      rendererFactory: L.canvas.tile,
      onEachFeature: function (feature: any, featureLayer: any, vtLayer: any, tileCoords: any) { },
      minZoom: `${layer_minzoom}`,
      maxZoom: `${layer_maxzoom}`,
      zIndex: 1,
      getFeatureId: function (feature: any) {
        let layerId = feature?.properties?.parcel_uid ? feature?.properties?.parcel_uid : feature?.properties?.property_uid;
        return layerId;
      },
      vectorTileLayerStyles: {
        [`${layer_name}`]: function (properties: any, zoom: any) {
          return {
            weight: 1,
            color: `${layer_color}`,
            fillOpacity: 0
          };
        },
      }
    };
    that.parcelLayerPbf = L.vectorGrid.protobuf(
      `${environment.geoestater}pbf-layer?store=${store_name}&layer=${layer_name}&token=${environment.user_token}&points={z}/{x}/{-y}.pbf`,
      vectorTileOptions
    ).on('click', (e: any) => {
      that.parcelPopu(e)
    }).on('mouseover', (e: any) => {
      let layerId = e.layer?.properties?.parcel_uid ? e.layer?.properties?.parcel_uid : e.layer?.properties?.property_uid;
      that.parcelLayerPbf.setFeatureStyle(layerId, that.pbfStyle_filter);
    }).on('mouseout', (r: any) => {
      let highlight = r.layer?.properties?.parcel_uid ? r.layer?.properties?.parcel_uid : r.layer?.properties?.property_uid;
      that.parcelLayerPbf.resetFeatureStyle(highlight);
    }).addTo(that.map);
  }

  async parcelPopu(e: any) {
    let that = this;

    let properties = e.layer?.properties;//e.target?.feature?.properties;
    let content;
    const loader = L.divIcon({
      className: 'parcel-loader',
      html: '<div class="spinner"></div>',
      iconSize: [16, 16],
    });
    const loaderMarker = L.marker([properties.lat, properties.lon], { icon: loader }).addTo(that.map);
    // Get API to get map-popup property data
    let year = (this.activatedRoute.snapshot.queryParams['year']) ? this.reportService.yearMaxValue((this.activatedRoute.snapshot.queryParams['year']).toString()) : new Date().getFullYear();
    // "forYear":"For year","data_not_available": "Data is not available",
    let translateData = `${this.translate.instant('reports.data_not_available')}. ${this.translate.instant('reports.forYear')} ${year}`;

    if (!properties || !properties.property_uid) {
      console.warn("Property UID is missing.");
      return;
    }
    try {
      const propertyData: any = await that.mapService.getParcelInfo(properties.property_uid);
      that.map.removeLayer(loaderMarker);
      if (propertyData.status == 200 && propertyData?.data?.length) {
        let options = that.mapService.popupOptions(e, that.mapWidth, that.mapHeight);
        properties = propertyData.data[0];
        if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
          content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes, '', '', options.class_name)
          that.popupContent = { type:'transaction', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes, className:options.class_name }
        } else {
          content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes, '', '', options.class_name);
          that.popupContent = { type:'property', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes, className:options.class_name }
        }
        that.mapPopupInstance = L.popup({ autoPan: false, offset: [options.x, options.y], className: options.class_name })
          .setContent(content)
          .setLatLng(e.latlng);
          // .openOn(that.map)
          // .update();
          this.mapPopupInstance.openOn(that.map);
      } else {
        that.map.removeLayer(loaderMarker);
        that.alertService.warn(translateData, {}, 5000);
      }
    } catch (error) {
      that.map.removeLayer(loaderMarker);
      that.alertService.warn(translateData, {}, 5000);
    }
  }
  addPbfOverlayLayer(data: any, index: any) {
    let that = this;
    let feature_uid_store: any = [];
    if (that.Pbf_OverlayLayer[index]) that.map.removeLayer(that.Pbf_OverlayLayer[index]);

    if (that.projectLayer)
      that.map.removeLayer(that.projectLayer);

    let layer_color = `${data?.color}`;
    that.Pbf_OverlayLayer[index] = [];
    that.Pbf_OverlayLayer[index]['layer'] = new L.layerGroup();
    that.Pbf_OverlayLayer[index]['minZoom'] = data?.minZoom;
    that.Pbf_OverlayLayer[index]['maxZoom'] = data?.maxZoom;
    if (data?.layer_name == 'neighbourhoods') that.Pbf_OverlayLayer[index]['minZoom'] = 13; //Avoid Neighbourhood Markers Overlapping

    let store_name = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${data?.store_name}`;
    let layer_name = `${data?.layer_name}`;
    // layer_name
    if (data?.layer_name?.includes(that.layerEndPoint)) {
      store_name = data?.store_name;
      layer_name = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${data?.layer_name}`;
      this.addParcelPbfLayer(store_name, layer_name, data)
      return;
    }
    let url = `${environment.geoestater}pbf-layer?store=${store_name}&layer=${layer_name}&token=${environment.user_token}&points={z}/{x}/{-y}.pbf`;
    let current_langID = this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrentLangID);

    let ProjectvectorTileOptions = {
      interactive: true,
      rendererFactory: L.canvas.tile,
      onEachFeature: function (feature: any, featureLayer: any, vtLayer: any, tileCoords: any) {
        let uid = feature.properties?.proj_uid ? feature.properties?.proj_uid : feature.properties?.neigh_uid;
        if (!feature_uid_store.includes(uid)) {
          let point: any = [];
          if (feature.properties?.center)
            point = wktToGeoJSON(feature.properties?.center);
          else
            point["coordinates"] = [feature.properties?.lon, feature.properties?.lat];
          // let icon_o = 'assets/images/Union_orange.svg';
          // if (data.layer_name == 'neighbourhoods') icon_o = 'assets/images/Union_blue.svg';
          // let Icon = L.icon({
          //   iconUrl: icon_o,
          //   iconSize: [150, 50]
          // });
          let layerclassName = data.layer_name == 'neighbourhoods' ? "union-orange union-blue" : "union-orange";
          let json_val = feature.properties?.proj_name ? feature.properties?.proj_name : feature.properties?.neigh_name;
          if (json_val) {
            let value = `<span style='color:${layer_color};'>${JSON.parse(json_val)[current_langID]}</span>`
            let marker: any = L.marker(new L.LatLng(point.coordinates[1], point.coordinates[0]), {
              icon: L.divIcon({
                className: [layerclassName],
                html: value,
              }),
            }).bindTooltip('', {
              permanent: true,
              direction: 'center',
              className: "my-labels"
            });
            marker.on('click', (e: any) => {
              let content = that.mapService.popup_content('project', feature.properties, that.currentReportData?.allowed_attributes, data.label);
              that.popupContent = { type:'project', propertiesdata: feature.properties, allowedAttributes: that.currentReportData?.allowed_attributes , btn_label:data.label}
              that.mapPopupInstance = L.popup({ autoPan: true, keepInView: true })
                .setContent(content)
                .setLatLng(e.latlng)
                // .openOn(that.map);
                that.mapPopupInstance.openOn(that.map)
            }).addTo(that.Pbf_OverlayLayer[index].layer);
            feature_uid_store.push(uid);
            that.projectLayer.setFeatureStyle(uid, {
              weight: 2,
              color: `${layer_color}`,
              dashArray: '2, 6',
              fillOpacity: 0
            });
          }
        }
      },
      minZoom: data?.minZoom, //16
      maxZoom: data?.maxZoom, //21
      zIndex: 9,
      getFeatureId: function (feature: any) {
        let featureid = feature.properties?.proj_uid ? feature.properties?.proj_uid : feature.properties?.neigh_uid;
        return featureid;
      },
      vectorTileLayerStyles: {
        [`${layer_name}`]: function (properties: any, zoom: any) {
          return {
            weight: 2,
            color: `${layer_color}`,
            dashArray: '2, 6',
            fillOpacity: 0
          };
        },
      }
    };

    that.projectLayer = L.vectorGrid.protobuf(url, ProjectvectorTileOptions).addTo(this.overlay_Layer);
    let mapZoom = that.map.getZoom();
    that.Pbf_OverlayLayer.forEach((layer_data: any, index: number = 0) => {
      if (mapZoom >= layer_data.minZoom && mapZoom <= layer_data.maxZoom) {
        if (layer_data?.layer) {
          that.overlay_Layer.addLayer(layer_data.layer);
        }
      }
      else {
        if (layer_data?.layer) that.overlay_Layer.removeLayer(layer_data.layer);
      }
    })
    // 'moveend' event
    this.moveEndSubscription4$?.unsubscribe();
    const moveEndObservable = fromEvent(this.map, 'moveend');
    this.moveEndSubscription4$ = moveEndObservable
      .pipe(debounceTime(30)) // 30 milliseconds debounce time
      .subscribe(event => {
        let mapZoomCurrent = that.map.getZoom();
        this.map.invalidateSize();
        that.Pbf_OverlayLayer.forEach((layer_data: any) => {
          if (mapZoomCurrent >= layer_data.minZoom && mapZoomCurrent <= layer_data.maxZoom) {
            if (layer_data?.layer) that.overlay_Layer.addLayer(layer_data.layer);
          }
          else {
            if (layer_data?.layer) that.overlay_Layer.removeLayer(layer_data.layer);
          }
        })

      })
  }

  mapLegendmenu() {
    let that = this;
    that.legend_data = that.layerData?.legend.filter((r: any) => r.display == true);
    // that.mapService.legendLayer.pipe(
    //   takeUntil(this.unsubscribe$)
    // ).subscribe((data: any) => {
    //   if (data) {
    //     that.layerselected = data.length;
    //     that.legend_data = data;
    //     that.mapLayerZoomlimit();
    //   }
    // })
    this.currentLegendView$?.unsubscribe();
    this.currentLegendView$ = this.mapService.legendLayerGroupList.pipe(
      distinctUntilChanged()
    ).subscribe((data: any) => {
      if (data) {
        let legendData = this.mapService.filterLegendData(data)
        this.legend_data_by_group = legendData.filter((item: any) => item.display == true && item.status == true);
        this.layerselected = this.legend_data_by_group?.length;
      } else {
        let lagendActiveGroupData = this.mapService.filterLegendData(this.currentReportData.legend);
        this.legend_data_by_group = lagendActiveGroupData?.filter((item: any) => item.display == true && item.status == true);
        this.layerselected = this.legend_data_by_group?.length;
      }
      // this.mapLayerZoomlimit();

      const categoryColorObject = this.legend_data_by_group && this.legend_data_by_group[0]?.data.reduce((acc: any, item: any) => {
        item.category.forEach((cat: any) => {
          const splitCategories = cat.split(',').map((c: string) => c.trim());
          splitCategories.forEach((singleCategory: any) => {
            acc[singleCategory] = item.color;
          });
        });
        return acc;
      }, {});
      // console.log("MoveEnd events", categoryColorObject)
      this.sharedService.setStoreValue(GlobalContants.StoreKey.legend, categoryColorObject);
      // if(!this.activatedRoute.snapshot.queryParams['polygon'])this.allLeyars(categoryColorObject);
      if (this.activatedRoute.snapshot.queryParams['polygon']) this.refreshPolygoncount('polygon')
    });


    that.mapService.legendToggle.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((toggle: boolean) => {
      that.legendToggle = toggle;
      that.mapLayerZoomlimit();
    })
  }

  collapseLegend() {
    this.mapService.legendToggle.next(false);
  }

  propertyInfo(properties: any) {
    this.reportService.transactionPropertyDetails.next(properties);
    this.selected_property = properties;
  }

  returnVal(value: string, type: string, mainobj: any) {
    if (type == 'text') {
      return this.translate.instant(value.replace(/_/g, " ").toLowerCase());
    } else if (type == 'src') {
      let style_name;
      let place_id = this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId);
      let store_name = this.layerData.store_name;
      let layer_name = `${this.sharedService.getStoreValue(GlobalContants.StoreKey.placeCode)}_${this.layerData.layer_name}_${this.layer_year}`;
      let style_index = this.legend_data.findIndex((r: any) => r == mainobj);
      if (style_index == 0) {
        if (mainobj.type != 'like') style_name = `style_Layer_${mainobj.type}`;
        else style_name = `style_Layer_${mainobj.legend}`;
      } else if (style_index == 1) {
        if (mainobj.type != 'like') style_name = `style_Layer2_${mainobj.type}`;
        else style_name = `style_Layer2_${mainobj.legend}`;
      } else if (style_index == 2) {
        if (mainobj.type != 'like') style_name = `style_Layer1_${mainobj.type}`;
        else style_name = `style_Layer1_${mainobj.legend}`;
      }
      let url = `${environment.geoestater}wms-layer?place_id=${place_id}&token=${environment.user_token}&request=GetLegendGraphic&layer=${layer_name}` +
        `&style=${style_name}&width=20&height=20&store=${store_name}&rule=${value}`;
      return url;
    } else {
      return value;
    }
  }

  mapFullScreen() {
    let that = this;
    that.mapService.mapFullscreenToggle.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe((fullScreen: any) => {
      if (fullScreen)
        that.map.toggleFullscreen()
    });
  }

  toggle_LayerFilter() {
    this.resetFilterSub$?.unsubscribe();
    this.resetFilterSub$ = this.mapService.reportFiltertoggle.subscribe({
      next: (data) => this.layerFilter(data),
      complete: () => this.refreshPolygoncount()
    });
  }

  layerFilter(type: any = '') {
    let layer_param_set = false, layer_param_set_1 = false;
    setTimeout(() => {
      let queryParam: any = [];
      queryParam = this.reportService.queryParams;
      // let layer_param_set = false, layer_param_set_1 = false;
      if (this.polygon_create) { /// If polygon filter applied then disable Neighbourhood Filter
        if (queryParam.neigh_name) {
          queryParam = Object.keys(queryParam).filter(objKey => objKey !== 'neigh_name').reduce((newObj: any, key) => {
            newObj[key] = queryParam[key];
            return newObj;
          }, {});
        }
      }
      this.cql_param = ` `;
      let pbf_para: any = [];

      for (let k in queryParam) {
        let r = this.filterObj?.find((e: any) => e.parameter_name == k);
        // if (r.name == k || k == 'unit_size') {  ////Old Filter logic
        if (r) {//// Dynamic Filter impleentation from MI APplication

          if (r.map_filter == 'equals') {//// Dynamic Filter impleentation from MI APplication
            if (!this.cql_param.includes(k)) {
              this.cql_param += ` and (strToLowerCase(${k}) IN (${queryParam[k].split(',').map((s: any) => `'${s}'`).join(',')}))`;
              pbf_para.push({ value: queryParam[k], type: 'equals', parameter: k, param: k });
            }
          }
          if (r.map_filter == 'like') {//// Dynamic Filter impleentation from MI APplication
            if (!this.cql_param.includes(k)) {
              this.cql_param += ` and (${queryParam[k].split(',').map((s: any) => `${k} ilike '${s}'`).join(' or ')})`;
              pbf_para.push({ value: queryParam[k], type: 'like', parameter: k, param: k });
            }
          }
          if (r.map_filter == 'number') {//// Dynamic Filter impleentation from MI APplication
            if (!this.cql_param.includes(k)) {
              this.cql_param += ` and (${k} between ${queryParam[k].split('-')[0]} and ${queryParam[k].split('-')[1]})`;
              pbf_para.push({ value: queryParam[k], type: 'number', parameter: k, param: k });
            }
          }
          if (r.map_filter == 'date') {//// Dynamic Filter impleentation from MI APplication
            let val = queryParam[k].split('to');
            for (let i in val) { val[i] = new Date(val[i]); }
            if (!this.cql_param.includes(`${k} >= ${val[0]} and ${k} <= ${val[1]}`)) {
              this.cql_param += ` and (${k} BETWEEN ${val[0].toISOString()} and ${val[1].toISOString()})`;
              pbf_para.push({ value: val, type: 'date', parameter: k, param: k });
            }
          }
        }


      }
      if (this.parcelLayerWms) this.parcelLayerWms.getLayers().forEach((layer: any) => {
        if (this.polygon_create) {
          let polygon_string = '';
          for (let i = 0; this.editableLayers.toGeoJSON().features.length > i; i++) {
            let wkt_String = geojsonToWKT(this.editableLayers.toGeoJSON().features[i].geometry);
            polygon_string += ` OR INTERSECTS(geom, SRID=4326;${wkt_String} )`;
          }
          polygon_string = `(${polygon_string.slice(4)})`;

          if (this.cql_param.length > 6 && !layer_param_set) {
            // this.cql_param = ` AND BBOX(geom, ${neLng}, ${neLat}, ${swLng}, ${swLat} ) AND ${this.cql_param.slice(5)}`
            this.cql_param = `${polygon_string} AND ${this.cql_param.slice(5)}`;
            layer.setParams({ cql_filter: `${this.cql_param}` });
            layer_param_set = true;
          }
          else if (!layer_param_set) {
            // this.cql_param = ` AND BBOX(geom, ${neLng}, ${neLat}, ${swLng}, ${swLat} )`
            this.cql_param = `${polygon_string}`;
            layer.setParams({ cql_filter: this.cql_param });
            layer_param_set = true;
          } else if (!layer_param_set_1) {
            layer.setParams({ cql_filter: this.cql_param });
            layer_param_set_1 = true;
          }
        } else {
          layer.setParams({ cql_filter: this.cql_param.slice(5) });
        }
      });
      this.pbf_LayerFilter(pbf_para);
      if (queryParam['zoom'] && !this.polygon_create && (!this.activatedRoute.snapshot.queryParams['amenities'])) {
        this.mapService.setZoom(Number(queryParam['zoom']))
        if (queryParam['centroid'] != '' && this.map) {
          this.map.setView(queryParam['centroid'].split('-'), Number(queryParam['zoom']));
        }
      }
      // if (type == 'reset') this.map.setZoom(10);
    }, 200)
  }

  pbf_LayerFilter(pbf_filter: any) {
    return;
    this.pbfParcelLayer.on('mouseover', (e: any) => {
      let layerId = e.layer?.properties?.parcel_uid ? e.layer?.properties?.parcel_uid : e.layer?.properties?.property_uid;
      // if (this.pbf_Condition(pbf_filter, e.layer?.properties)) {
      //   if (this.polygon_create) {
      //     if (e.layer.properties.wkt) {
      //       let wkt = wktToGeoJSON(e.layer.properties.wkt);
      //       let feat_bound = L.geoJson(wkt).getBounds();
      //       let polygon_para_condition = '';
      //       let lyr = this.editableLayers.getLayers()[0];
      //       if (this.drawnPolygons.length > 1) {
      //         for (let i = 0; i < lyr.getLayers().length; i++) {
      //           polygon_para_condition += `|| ${lyr.getLayers()[i].getBounds().intersects(feat_bound)} `
      //         }
      //       } else polygon_para_condition += `|| ${lyr?.getBounds().intersects(feat_bound)} `
      //       polygon_para_condition = polygon_para_condition.slice(2);
      //       if (eval(polygon_para_condition)) {
      //         this.pbfParcelLayer.setFeatureStyle(layerId, this.pbfStyle_filter);
      //       } else {
      //         this.pbfParcelLayer.setFeatureStyle(layerId, this.pbfStyle_nonfilter);
      //       }
      //     }
      //   } else {
      //     this.pbfParcelLayer.setFeatureStyle(layerId, this.pbfStyle_filter);
      //   }
      // }
      // else {
      //   this.pbfParcelLayer.setFeatureStyle(layerId, this.pbfStyle_nonfilter);
      // }
      this.pbfParcelLayer.setFeatureStyle(layerId, this.pbfStyle_filter);
    }).on('click', (e: any) => {
      let properties = e.layer?.properties;
      let content, that = this;

      if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
        content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes)
      } else {
        content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes);
      }
      L.popup({ autoPan: true, keepInView: true })
        .setContent(content)
        .setLatLng(e.latlng)
        .openOn(this.map);
      // if (this.pbf_Condition(pbf_filter, e.layer?.properties)) {
      //   let properties = e.layer?.properties;
      //   let content, that = this;

      //   // if (that.currentReportData && that.currentReportData?.name === 'transactions') {
      //   if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
      //     content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes)
      //   } else {
      //     content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes);
      //   }
      //   L.popup({ autoPan: true, keepInView: true })
      //   .setContent(content)
      //   .setLatLng(e.latlng)
      //   .openOn(this.map);
      //   // if (e.latlng) {
      //   //   if (this.polygon_create) {
      //   //     if (e.layer.properties.wkt) {
      //   //       let wkt = wktToGeoJSON(e.layer.properties.wkt);
      //   //       let feat_bound = L.geoJson(wkt).getBounds();
      //   //       let polygon_para_condition = '';
      //   //       let lyr = this.editableLayers.getLayers()[0];
      //   //       if (this.drawnPolygons.length > 1) {
      //   //         for (let i = 0; i < lyr.getLayers().length; i++) {
      //   //           polygon_para_condition += `|| ${lyr.getLayers()[i].getBounds().intersects(feat_bound)} `
      //   //         }
      //   //       } else polygon_para_condition += `|| ${lyr?.getBounds().intersects(feat_bound)} `
      //   //       polygon_para_condition = polygon_para_condition.slice(2);
      //   //       if (eval(polygon_para_condition)) {
      //   //         L.popup({ autoPan: true, keepInView: true })
      //   //           .setContent(content)
      //   //           .setLatLng(e.latlng)
      //   //           .openOn(this.map);
      //   //       }
      //   //     }
      //   //   } else {
      //   //     L.popup({ autoPan: true, keepInView: true })
      //   //       .setContent(content)
      //   //       .setLatLng(e.latlng)
      //   //       .openOn(this.map);
      //   //   }
      //   // }

      // } else {
      //   this.map.closePopup();
      // }
    })
  }

  pbf_Condition(pbf_filter: any, properties: any) {
    let condition: any;
    let result_val: any = [];
    pbf_filter.forEach((filter: any) => {

      if (filter.type == 'equals') {
        condition = filter.value.split(',').map((s: any) => {
          if (properties[filter.parameter]) {
            let fmName = properties[filter.parameter].toString();
            if (fmName.toLowerCase() == s.toString()) {
              result_val.push(true);
            }
          }
        });
      }
      if (filter.type == 'number') {
        condition = `${properties[filter.parameter]} > ${filter.value.split('-')[0]} && ${properties[filter.parameter]} < ${filter.value.split('-')[1]}`;
        if (condition) result_val.push(true);
      }
      if (filter.type == 'like') {
        let val_label = properties[filter.parameter];
        if (val_label) {
          condition = `${filter.value.split(',').map((s: any) => `val_label.includes('${s}')`).join(' || ')}`;
          if (eval(condition))
            result_val.push(true);
        } else
          result_val.push(true)
      }
      if (filter.type == 'date') {
        let val_label = properties[filter.parameter];
        if (val_label) {
          condition = Date.parse(val_label) >= Date.parse(filter.value[0]) && Date.parse(val_label) <= Date.parse(filter.value[1]);
          if (eval(condition))
            result_val.push(true);
        } else
          result_val.push(true)
      }
    })

    if (pbf_filter.length < 1) {
      return true;
    } else if (result_val.length == pbf_filter.length) {
      return result_val.includes(true);
    } else {
      return false;
    }
  }

  reportCountLayer(data: any) {
    let curr = this.translate.instant(this.sharedService.getStoreValue(GlobalContants.StoreKey.CurrencyCode));
    let unit = this.translate.instant(GlobalContants.keySymbolContants.squareMeterUnit);
    let curr_unit = `${curr}/${unit}`
    let that = this;
    let Marker_url = 'assets/images/Ellipse_dfdfblue.svg';
    let MarkerIcon = L.icon({
      iconUrl: Marker_url,
      iconSize: [40, 40]
    });
    if (that.report_countLayer)
      that.report_countLayer.remove();
    that.report_countLayer = new L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: (cluster: any) => {
        let cluster_count = 0;
        cluster.getAllChildMarkers().forEach((marker: any) => {
          // cluster_count += parseInt(marker.getTooltip().getContent())
          cluster_count += marker.customData.value.count;
        })
        let count = that._decimalPipe.transform(cluster_count, '1.0-0')
        let xy = ((count?.length ?? 0) * 6) + 8;
        let width = (xy > 30) ? xy : 30;
        return L.divIcon({
          className: 'clusterdiv-icon', html: '<b style="position:relative;top:9px">' + count + '</b>', iconSize: [width, 30]
        });
      }
      // avg price on marge map-cluster =====
      // iconCreateFunction: (cluster: any) => {
      //   let cluster_count:number = 0;
      //   let total_price:number = 0;
      //   let total_area_price:number = 0;
      //   cluster.getAllChildMarkers().forEach((marker: any) => {
      //     total_price += marker.customData.value.total_price;
      //     total_area_price += marker.customData.value.total_area_price;
      //   })
      //   cluster_count = (total_price/total_area_price)
      //   let cluster_count_dismal = cluster_count.toString()?.split('.')[1];
      //   let all_cluster_count = (cluster_count_dismal?.length > 1)? cluster_count.toFixed(1):cluster_count;
      //   // let xy = ((all_cluster_count.toString().length) * 6) + 8;
      //   let xy = ((all_cluster_count.toString().length) * 6) + 50;
      //   let width = (xy > 30) ? xy : 30;
      //   return L.divIcon({
      //     className: 'clusterdiv-icon', html: '<b style="position:relative;top:9px">' + all_cluster_count +' '+ curr_unit +'</b>', iconSize: [width, 30]
      //   });
      // }
    });
    if (data && data.length >= 1) {
      for (let i = 0; i < data.length; i++) {
        let count = that._decimalPipe.transform(data[i].count, '1.0-0')
        let xy = ((count?.length ?? 0) * 6) + 8;
        let width = (xy > 30) ? xy : 30;
        let marker: any = L.marker(new L.LatLng(data[i].lat, data[i].lon), {
          icon:
            L.divIcon({
              className: ["clusterdiv-icon cluster-icon-pos"],
              iconSize: [width, 30]
            })
        })
          .bindTooltip(`${count}`, {
            permanent: true,
            direction: 'center',
            className: "my-labels"
          }).on('click', () => {
            that.map.setView(new L.LatLng(data[i].lat, data[i].lon), 16);
          });
        marker.customData = {
          value: data[i]
        };
        that.report_countLayer.addLayer(marker);
      }
      // avg price on map-cluster =====
      // for (let i = 0; i < data.length; i++) {
      //   let lease_price = (data[i].total_price / data[i].total_area_price).toFixed(1);

      //   // let xy = ((lease_price.toString().length) * 6) + 8;
      //   let xy = ((lease_price.toString().length) * 6) + 50;
      //   let width = (xy > 30) ? xy : 30;
      //   let marker: any = L.marker(new L.LatLng(data[i].lat, data[i].lon), {
      //     icon:
      //       L.divIcon({
      //         className: [`clusterdiv-icon cluster-icon-pos`],
      //         iconSize: [width, 30]
      //       })
      //   })
      //     .bindTooltip(`${lease_price} ${curr_unit}`, {
      //       permanent: true,
      //       direction: 'center',
      //       className: "my-labels"
      //     }).on('click', () => {
      //       that.map.setView(new L.LatLng(data[i].lat, data[i].lon), 15);
      //     });
      //     marker.customData = {
      //       value: data[i]
      //   };
      //   that.report_countLayer.addLayer(marker);
      // }
      if (that.map.getZoom() < 16) that.map.addLayer(this.report_countLayer);
      // 'moveend' event
      const moveEndObservable = fromEvent(that.map, 'moveend');
      this.moveEndSubscription1$?.unsubscribe();
      this.moveEndSubscription1$ = moveEndObservable
        .pipe(debounceTime(30)) // 30 milliseconds debounce time
        .subscribe(event => {
          this.map.invalidateSize();
          if (!that.polygon_create) {
            if (that.map.getZoom() >= 16) that.map.removeLayer(that.report_countLayer);
            else that.map.addLayer(that.report_countLayer);
          } else that.map.removeLayer(that.report_countLayer);
          that.mapLayerZoomlimit();
        })
    }
  }

  drawPolygon(type?: any) {
    this.loadCatchmentShow = false;
    this.reLoadCatchmentShow = false
    let that = this;
    that.map.off(L.Draw.Event.CREATED);
    if (that.polygon_create && type == undefined && (that.polygon_WKT != null && that.polygon_WKT.length > 0)) {
      let _data = {
        parent_data: null,
        message: this.translate.instant('map.do_you_want_to_remove_the_catchment'),
        inputbox: true
      };
      that.dailog.open(ConfirmDialogBoxComponent, {
        width: '400px',
        height: 'auto',
        data: _data,
        disableClose: true
      }).afterClosed().subscribe((res: any) => {
        if (res) {
          if (that.editableLayers) that.editableLayers.remove();
          if (that.drawControl) that.drawControl.remove();
          if (that.catchment_Markers) that.catchment_Markers.remove();
          if (that.polygon_WKT && that.polygon_WKT.length > 1) that.polygon_WKT = null;
          if (that.drawnPolygons && that.drawnPolygons.length > 1) that.drawnPolygons = [];
          that.polygon_chip = false;
          that.mapZoom = that.map.getZoom();
          that.mapCenter = that.map.getCenter();
          that.polygon_create = false;
          that.mapService.drawPolyToggle.next(that.polygon_create);
          // this.sharedService.clearSession('filter');
          // Remove polygon query params
          that.router.navigate([], {
            queryParams: {
              'polygon': null,
              'youCanRemoveMultiple': null,
            },
            queryParamsHandling: 'merge'
          })
        }
      })
      return;
    } else if (that.polygon_create && (that.polygon_WKT == null || that.polygon_WKT == undefined)) {
      //that.drawControl?.remove();
      that.polygon_create = false;
      that.mapService.drawPolyToggle.next(that.polygon_create);
      return;
    }

    if (type == undefined) that.polygon_create = !that.polygon_create;

    if (that.editableLayers && type == undefined) {
      that.editableLayers.remove();
      that.drawnPolygons = [];
      that.editableLayers = new L.FeatureGroup({ interactive: false }).addTo(that.map);
      that.catchment_Markers = new L.layerGroup().addTo(that.map);
    } else if (!that.editableLayers) {
      that.editableLayers = new L.FeatureGroup({ interactive: false }).addTo(that.map);
      that.catchment_Markers = new L.layerGroup().addTo(that.map);
    }
    if (that.drawControl) that.drawControl.remove();
    that.map.createPane('poly_layer');
    that.map.createPane('pbf_layer');

    that.map.getPane('overlayPane').style.pointerEvents = 'none';
    if (this.polygon_create) {
      this.createDrawLayer();
    }
    this.map.on(L.Draw.Event.CREATED, (e: any) => {
      let layer = e.layer;
      let type = e.layerType;
      // if (!layer_created) {
      if (type == 'circle') {
        layer = that.circleToPolygon(layer);
      }
      let temp_layer = new L.FeatureGroup({ interactive: false }).addTo(that.map);
      temp_layer.addLayer(layer);
      temp_layer.remove();
      that.polygon_chip = false;
      // if (res[0]) {
      // let feature = layer.feature = layer.feature || {}; // Initialize feature
      // feature.type = feature.type || "Feature"; // Initialize feature.type
      // let props = feature.properties = feature.properties || {}; // Initialize feature.properties
      // props.label = res[1];
      that.editableLayers.addLayer(layer);
      that.drawnPolygons = that.editableLayers.toGeoJSON()?.features;
      if (that.drawnPolygons.length >= 4) {
        this.disableDrawLayer()
      } else {
        this.enableDrawLayer();
      }
      //if (that.drawControl) that.drawControl.remove();
      let viewMap = that.editableLayers.getBounds();
      if (viewMap.isValid()) {
        that.polygon_WKT = JSON.stringify(that.editableLayers.toGeoJSON());
        that.mapZoom = that.map.getZoom();
        that.mapCenter = that.map.getCenter();
        that.router.navigate([], {
          queryParams: { polygon: that.polygon_WKT, zoom: that.mapZoom, centroid: that.mapService.getCentroid() },
          queryParamsHandling: 'merge',
        });
      } else {
        that.polygon_create = false;
        that.mapService.drawPolyToggle.next(that.polygon_create);
      }

      // } else {
      //   if (that.drawControl) that.drawControl.remove();
      //   if (that.drawnPolygons.length == 0) {
      //     that.polygon_create = false;
      //     that.mapService.drawPolyToggle.next(that.polygon_create);
      //     that.map_spinner = false;
      //   } else {
      //     that.refreshPolygoncount();
      //   }
      // }
      // })
      //check draw polygon overlap Else====
      // }
      // else {
      //   temp_layer.remove();
      //   that.polygon_chip = false;
      //   layer_created = true;
      //   if (that.drawControl) that.drawControl.remove();
      //   that.alertService.warn("Catchments are overlapping. Could you please redraw?", {}, 5000);
      // }
      //}

    }).on('draw:drawstart', (e: any) => {
      that.polygon_chip = true;
      that.removeHighlightDrawCatchmentIcon()
      that.activeDrawCatchmentIcon(e.layerType)
    }).on('draw:drawstop', function (e: any) {
      that.polygon_chip = false;
      that.removeHighlightDrawCatchmentIcon()
    });


  }

  createDrawLayer() {

    let position = (this.current_lang == 'en') ? 'topleft' : 'topright';
    L.drawLocal.draw.toolbar.buttons.polygon = this.translate.instant('map.draw_polygon');
    L.drawLocal.draw.toolbar.buttons.circle = this.translate.instant('map.draw_circle');
    L.drawLocal.draw.toolbar.buttons.rectangle = this.translate.instant('map.draw_rectangle');
    var drawPluginOptions = {
      position: position,
      draw: {
        polygon: { shapeOptions: { fill: false } },
        marker: false,
        circlemarker: false,
        rectangle: { shapeOptions: { fill: false } },
        circle: { shapeOptions: { fill: false } },
        polyline: false
      },
      edit: {
        featureGroup: this.editableLayers, //REQUIRED!!
        remove: false,
        edit: false
      }
    }
    // if(this.polygon_create && !this.isCatchmentExpand){
    this.drawControl = new L.Control.Draw(drawPluginOptions);
    this.map?.addControl(this.drawControl);
    //}

  }


  refreshPolygoncount(catchment = '') {
    let lyr = this.editableLayers.getLayers()[0];
    this.polygon_create = true;
    this.map_spinner = true;
    if (this.editableLayers) this.editableLayers.remove();
    if (this.catchment_Markers) this.catchment_Markers.remove();
    this.editableLayers = new L.FeatureGroup().addTo(this.map);
    this.map.createPane('topPane');
    this.map.getPane('topPane').style.zIndex = 401;

    // Define an array of colors for polygons
    let colors: any = ['#FF6C16', '#3774E8', '#28B044', '#6D47E1'];
    let colorsClass: any = ['darw-chipone', 'darw-chiptwo', 'darw-chipthree', 'darw-chipfour'];

    this.drawnPolygons = this.drawnPolygons.map((polygon: any, index: any) => {
      if (!polygon['colorClassName']) {
        polygon['colorClassName'] = colorsClass[0]
        polygon['color'] = colors[0]
        const colorInd = colors.indexOf(polygon['color'])
        colorsClass.splice(colorInd, 1)
        colors.splice(colorInd, 1)
      } else {
        const colorInd = colors.indexOf(polygon['color'])
        colorsClass.splice(colorInd, 1)
        colors.splice(colorInd, 1)
      }
      L.geoJSON(polygon, { pane: 'topPane', style: { fill: false, color: polygon['color'] } }).addTo(this.editableLayers);
      return polygon
    });

    if (this.drawnPolygons.length > 0) {
      // 1 use to API call for each  polygon
      if (catchment == 'remove') return;

      if (this.map.hasLayer(this.layer1)) this.map?.removeLayer(this.layer1);
      if (this.map.hasLayer(this.layer2)) this.map?.removeLayer(this.layer2);
      if (this.map.hasLayer(this.layer3)) this.map?.removeLayer(this.layer3);
      if (this.map.hasLayer(this.layer4)) this.map?.removeLayer(this.layer4);
      this.editableLayers.getLayers().forEach((main_layer: any, i: number) => {
        main_layer.getLayers().forEach((layer: any, index: number) => {
          this.multipolgonReportCount(layer, i + 1, catchment);
        })
      })
      // 2 use to combine multiple polygon in payload to avoid multiple API call
      // this.multipolgonReportCount(this.editableLayers.toGeoJSON(), 1);// multiple polygon data
    } else {
      try {
        lyr.getLayers().forEach((layer: any, index: any) => {
          this.multipolgonReportCount(layer, index + 1);
        })
      } catch {
        this.editableLayers.getLayers().forEach((layer: any, index: any) => {
          this.multipolgonReportCount(layer, index + 1);
        })
      }
    }
  }

  multipolgonReportCount(layer: any, count: any, type: string = '') {
    if (this.catchment_Markers) this.catchment_Markers.remove();
    // this.catchment_Markers = new L.layerGroup().addTo(this.map);

    // to create map cluster Group after drow chacments
    this.catchment_Markers = new L.markerClusterGroup({
      showCoverageOnHover: false,
      iconCreateFunction: (cluster: any) => {
        let cluster_count = 0;
        cluster.getAllChildMarkers().forEach((marker: any) => {
          // cluster_count += parseInt(marker.getTooltip().getContent())
          cluster_count += marker.customData.value.count;
        })
        let count = this._decimalPipe.transform(cluster_count, '1.0-0')
        let xy = ((count?.length ?? 0) * 6) + 8;
        let width = (xy > 30) ? xy : 30;
        return L.divIcon({
          className: 'clusterdiv-icon', html: '<b style="position:relative;top:10px">' + count + '</b>', iconSize: [width, 30]
        });
      }
    });
    //Ends

    let layer_count = 0;
    // let geojsonlyr = layer;// combile multiple polygon in payload
    let geojsonlyr = {
      "type": "FeatureCollection",
      "features": [layer.toGeoJSON()]
    }
    // console.log(count, layer.toGeoJSON().geometry.coordinates[0])
    let cords = layer.toGeoJSON().geometry.coordinates[0];
    // console.log(L.polygon(layer.toGeoJSON().geometry.coordinates))
    const polygonString = `POLYGON((${cords.map((coord: any) => `${coord[0]} ${coord[1]}`).join(', ')}))`;

    if (this.activatedRoute.snapshot.queryParams['rid']) {
      if (type) {
        // console.log("MoveEnd events multipolgonReportCount >>>>")
        this.getJsonData(count.toString(), polygonString, '', 'polygon');
      }
      this.mapService.getReportCount(JSON.stringify(geojsonlyr)).subscribe({
        next: (layerdata: any) => {
          for (let k in layerdata.data) {
            let count = this._decimalPipe.transform(layerdata.data[k].count, '1.0-0')
            let xy = ((count?.length ?? 0) * 6) + 8;
            let width = (xy > 30) ? xy : 30;
            // map cluster Group for count cluster code starts ===
            let marker: any = L.marker(new L.LatLng(layerdata.data[k].lat, layerdata.data[k].lon), {
              icon:
                L.divIcon({
                  className: ["clusterdiv-icon cluster-icon-pos"],
                  iconSize: [width, 30]
                })
            })
              .bindTooltip(`${count}`, {
                permanent: true,
                direction: 'center',
                className: "my-labels"
              }).on('click', () => {
                this.map.setView(new L.LatLng(layerdata.data[k].lat, layerdata.data[k].lon), 15);
              });
            marker.customData = {
              value: layerdata.data[k]
            };
            this.catchment_Markers.addLayer(marker);
            //Ends
          }
          this.map.addLayer(this.catchment_Markers);
          // 'moveend' event
          this.moveEndSubscription2$?.unsubscribe();
          const moveEndObservable = fromEvent(this.map, 'moveend');
          this.moveEndSubscription2$ = moveEndObservable
            .pipe(debounceTime(30)) // 30 milliseconds debounce time
            .subscribe(event => {
              this.map.invalidateSize();
              if (this.polygon_create) {
                if (this.map.getZoom() >= 15) this.map.removeLayer(this.catchment_Markers);
                else this.map.addLayer(this.catchment_Markers);
              }
            })
          // single map count cluster code starts ===
          // L.marker(layer.getBounds().getCenter(), {
          //   icon: L.divIcon({
          //     className: "clusterdiv-icon polygon-count",
          //     html: '<b>' + `${layer_count}` + '</b>',
          //     iconSize: [55, 30]
          //   })
          // }).addTo(this.catchment_Markers);
          // if (!this.map.hasLayer(this.catchment_Markers)) this.map.addLayer(this.catchment_Markers);
        }, complete: () => { this.map_spinner = false; }
      })

      //
    }

  }

  polygonWKTRemove(polygon: any) {
    // let that = this;
    // let _data = {
    //   parent_data: null,
    //   message: this.translate.instant('map.do_you_want_to_remove_the_catchment'),
    //   inputbox: true
    // };
    // that.dailog.open(ConfirmDialogBoxComponent, {
    //   width: '400px',
    //   height: 'auto',
    //   data: _data,
    //   disableClose: true
    // }).afterClosed().subscribe((res: any) => {
    //   if (res) {

    //   }
    // })
    this.enableDrawLayer();


    const index = this.drawnPolygons.indexOf(polygon);
    if (index !== -1) {
      this.drawnPolygons.splice(index, 1);
    }

    //this.reportService.resetCatchmentFilter(this.drawnPolygons)

    // that.refreshPolygoncount();
    if (this.drawnPolygons.length == 0) {
      if (this.editableLayers) this.editableLayers.remove();
      if (this.catchment_Markers) this.catchment_Markers.remove();
      this.polygon_create = false;
      this.mapService.drawPolyToggle.next(this.polygon_create);
      this.polygon_WKT = '';
      this.router.navigate([], {
        queryParams: {
          'polygon': null,
          'youCanRemoveMultiple': null,
        },
        queryParamsHandling: 'merge'
      })
    } else {
      this.refreshPolygoncount('remove');
      this.polygon_WKT = JSON.stringify(this.editableLayers.toGeoJSON());


      this.router.navigate([], {
        queryParams: { polygon: this.polygon_WKT, zoom: this.mapZoom, centroid: this.mapService.getCentroid() },
        queryParamsHandling: 'merge',
      });
    }

    if (this.drawnPolygons.length === 0) {
      this.sharedService.clearSession('filter');
    }

  }

  ngOnDestroy(): void {
    this.removeScrollPropagationEvent();
    this.moveEndSubscriptionMap$?.unsubscribe();
    this.mapService.tenantName.set('');
    this.selectedReportSubscription$?.unsubscribe();
    this.moveEndSubscription1$?.unsubscribe();
    this.moveEndSubscription2$?.unsubscribe();
    this.moveEndSubscription3$?.unsubscribe();
    this.moveEndSubscription4$?.unsubscribe();
    this.resetFilterSub$.unsubscribe();
    this.selectedReportSub$?.unsubscribe();
    this.drawPolySub$?.unsubscribe();
    this.map.off('zoom');
    this.map.off('moveend');
    this.overlay_LayerSub$?.unsubscribe();
    this.mapCoordinatesSub$?.unsubscribe();
    this.routeSub$.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.NeighDetails$.unsubscribe();
    if (this.drawControl) {
      this.drawControl.remove()
    }
  }


  circleToPolygon(circle: any, sides = 60, withBearing = true) {
    const origin = circle.getLatLng();
    const radius = circle.getRadius();
    const polys = this.createGeodesicPolygon(origin, radius, sides, 0); // these are the points that make up the circle
    const polygon = [];
    for (let i = 0; i < polys.length; i += 1) {
      const geometry = [polys[i].lat, polys[i].lng];
      polygon.push(geometry);
    }
    return L.polygon(polygon, circle.options);
  }

  createGeodesicPolygon(origin: any, radius: any, sides: any, rotation: any) {
    let angle;
    let newLonlat;
    let geomPoint;
    const points = [];

    for (let i = 0; i < sides; i += 1) {
      angle = (i * 360 / sides) + rotation;
      newLonlat = this.destinationVincenty(origin, angle, radius);
      geomPoint = L.latLng(newLonlat.lng, newLonlat.lat);
      points.push(geomPoint);
    }

    return points;
  }

  destinationVincenty(lonlat: any, brng: any, dist: any) { // rewritten to work with leaflet
    const VincentyConstants = {
      a: 6378137,
      b: 6356752.3142,
      f: 1 / 298.257223563
    };

    const { a, b, f } = VincentyConstants;
    const lon1 = lonlat.lng;
    const lat1 = lonlat.lat;
    const s = dist;
    const pi = Math.PI;
    const alpha1 = brng * pi / 180; // converts brng degrees to radius
    const sinAlpha1 = Math.sin(alpha1);
    const cosAlpha1 = Math.cos(alpha1);
    const tanU1 = (1 - f) * Math.tan(lat1 * pi / 180 /* converts lat1 degrees to radius */);
    const cosU1 = 1 / Math.sqrt((1 + tanU1 * tanU1));
    const sinU1 = tanU1 * cosU1;
    const sigma1 = Math.atan2(tanU1, cosAlpha1);
    const sinAlpha = cosU1 * sinAlpha1;
    const cosSqAlpha = 1 - sinAlpha * sinAlpha;
    const uSq = cosSqAlpha * (a * a - b * b) / (b * b);
    const A = 1 + uSq / 16384 * (4096 + uSq * (-768 + uSq * (320 - 175 * uSq)));
    const B = uSq / 1024 * (256 + uSq * (-128 + uSq * (74 - 47 * uSq)));
    let sigma = s / (b * A);
    let sigmaP = 2 * Math.PI;

    let cos2SigmaM;
    let sinSigma;
    let cosSigma;
    while (Math.abs(sigma - sigmaP) > 1e-12) {
      cos2SigmaM = Math.cos(2 * sigma1 + sigma);
      sinSigma = Math.sin(sigma);
      cosSigma = Math.cos(sigma);
      const deltaSigma = B * sinSigma * (cos2SigmaM + B / 4 * (cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM) -
        B / 6 * cos2SigmaM * (-3 + 4 * sinSigma * sinSigma) * (-3 + 4 * cos2SigmaM * cos2SigmaM)));
      sigmaP = sigma;
      sigma = s / (b * A) + deltaSigma;
    }
    if (sinSigma && cos2SigmaM && cosSigma) {
      const tmp = sinU1 * sinSigma - cosU1 * cosSigma * cosAlpha1;
      const lat2 = Math.atan2(sinU1 * cosSigma + cosU1 * sinSigma * cosAlpha1,
        (1 - f) * Math.sqrt(sinAlpha * sinAlpha + tmp * tmp));
      const lambda = Math.atan2(sinSigma * sinAlpha1, cosU1 * cosSigma - sinU1 * sinSigma * cosAlpha1);
      const C = f / 16 * cosSqAlpha * (4 + f * (4 - 3 * cosSqAlpha));
      const lam = lambda - (1 - C) * f * sinAlpha *
        (sigma + C * sinSigma * (cos2SigmaM + C * cosSigma * (-1 + 2 * cos2SigmaM * cos2SigmaM)));
      // const revAz = Math.atan2(sinAlpha, -tmp);  // final bearing

      const lamFunc = lon1 + (lam * 180 / pi); // converts lam radius to degrees
      const lat2a = lat2 * 180 / pi; // converts lat2a radius to degrees

      return L.latLng(lamFunc, lat2a);
    }
  }

  catchmentExpand(value: any) {
    this.isCatchmentExpand = value;
  }

  disableDrawLayer() {
    const mainToolTip = document.querySelector('.leaflet-draw-toolbar') as HTMLElement;
    if (this.current_lang == 'ar') {
      mainToolTip.classList.add('mainToolTip-disabledAr');
    } else {
      mainToolTip.classList.add('mainToolTip-disabled');
    }
    const polygonButton = document.querySelector('.leaflet-draw-draw-polygon') as HTMLElement;
    if (polygonButton) {
      polygonButton.classList.add('leaflet-disabled'); // Add a disabled class for visual effect
      polygonButton.style.pointerEvents = 'none'; // Disable interaction
    }
    const circleButton = document.querySelector('.leaflet-draw-draw-circle') as HTMLElement;
    if (circleButton) {
      circleButton.classList.add('leaflet-disabled'); // Add a disabled class for visual effect
      circleButton.style.pointerEvents = 'none'; // Disable interaction
    }
    const rectangleButton = document.querySelector('.leaflet-draw-draw-rectangle') as HTMLElement;
    if (rectangleButton) {
      rectangleButton.classList.add('leaflet-disabled'); // Add a disabled class for visual effect
      rectangleButton.style.pointerEvents = 'none'; // Disable interaction
    }
  }

  activeDrawCatchmentIcon(type: string) {
    if (type === 'polygon') {
      const polygonButton = document.querySelector('.leaflet-draw-draw-polygon') as HTMLElement;
      if (polygonButton) {
        polygonButton.classList.add('active')

      }
    } else if (type === 'rectangle') {
      const rectangleButton = document.querySelector('.leaflet-draw-draw-rectangle') as HTMLElement;
      if (rectangleButton) {
        rectangleButton.classList.add('active'); // Add a disabled class for visual effect
      }
    } else if (type === 'circle') {
      const circleButton = document.querySelector('.leaflet-draw-draw-circle') as HTMLElement;
      if (circleButton) {
        circleButton.classList.add('active'); // Add a disabled class for visual effect
      }
    }
  }

  removeHighlightDrawCatchmentIcon() {
    const circleButton = document.querySelector('.leaflet-draw-draw-circle') as HTMLElement;
    if (circleButton) {
      circleButton.classList.remove('active'); // Add a disabled class for visual effect
    }
    const polygonButton = document.querySelector('.leaflet-draw-draw-polygon') as HTMLElement;
    if (polygonButton) {
      polygonButton.classList.remove('active')

    }
    const rectangleButton = document.querySelector('.leaflet-draw-draw-rectangle') as HTMLElement;
    if (rectangleButton) {
      rectangleButton.classList.remove('active'); // Add a disabled class for visual effect
    }
  }

  enableDrawLayer() {
    const mainToolTip = document.querySelector('.leaflet-draw-toolbar') as HTMLElement;
    if (this.current_lang == 'ar') {
      mainToolTip.classList.remove('mainToolTip-disabledAr');
    } else {
      mainToolTip.classList.remove('mainToolTip-disabled');
    }

    const polygonButton = document.querySelector('.leaflet-draw-draw-polygon') as HTMLElement;
    if (polygonButton) {
      polygonButton.classList.remove('leaflet-disabled'); // Add a disabled class for visual effect
      polygonButton.style.pointerEvents = 'auto'; // Disable interaction
    }
    const circleButton = document.querySelector('.leaflet-draw-draw-circle') as HTMLElement;
    if (circleButton) {
      circleButton.classList.remove('leaflet-disabled'); // Add a disabled class for visual effect
      circleButton.style.pointerEvents = 'auto'; // Disable interaction
    }
    const rectangleButton = document.querySelector('.leaflet-draw-draw-rectangle') as HTMLElement;
    if (rectangleButton) {
      rectangleButton.classList.remove('leaflet-disabled'); // Add a disabled class for visual effect
      rectangleButton.style.pointerEvents = 'auto'; // Disable interaction
    }
  }




  resetCatchmentConfirm(clearFlter: any = null) {
    if (this.editableLayers) this.editableLayers.remove();
    if (this.drawControl) this.drawControl.remove();
    if (this.catchment_Markers) this.catchment_Markers.remove();
    if (this.polygon_WKT && this.polygon_WKT.length > 1) this.polygon_WKT = null;
    if (this.drawnPolygons && this.drawnPolygons.length > 1) this.drawnPolygons = [];
    this.polygon_chip = false;
    this.mapZoom = this.map.getZoom();
    this.mapCenter = this.map.getCenter();
    this.polygon_create = false;
    this.mapService.drawPolyToggle.next(this.polygon_create);
    if (clearFlter) {
      this.sharedService.clearSession('filter');
    }

    // Remove polygon query params
    this.router.navigate([], {
      queryParams: {
        'polygon': null,
        'youCanRemoveMultiple': null,
      },
      queryParamsHandling: 'merge'
    })
  }
  closeChip(event: boolean) {
    this.isShowChip = false;
    this.showUniversalSearch();
  }
  closeMapSearch(event: boolean) {
    if (event) {
      this.searchpropertyDetail?.searchControl.patchValue("");
    }
  }
  
  preventScrollPropagation(event: WheelEvent) {
    event.stopPropagation();
  }
  removeScrollPropagationEvent(){
    if (this.searchOverlayDiv) {
      this.searchOverlayDiv.removeEventListener('wheel', this.preventScrollPropagation);
    }
}
  openMapSearch() {
    this.isOpenMapSearch = true;
    this.cdr.detectChanges();
      this.searchOverlayDiv = document.querySelector('.search_container');
      if (this.searchOverlayDiv) {
        this.searchOverlayDiv.addEventListener('wheel', this.preventScrollPropagation);
        this.searchOverlayDiv.addEventListener('mouseenter', ()=>{
          this.map.dragging.disable();
        });
        this.searchOverlayDiv.addEventListener('mouseleave', ()=>{
          this.map.dragging.enable();
        });
      }
  }
  layerToggle() {
    this.mapService.mapFiltertoggle.next(false);
    this.mapService.mapLayertoggle.next(true);
  }
  /**
   * This function is used to get the property detail based on universal search on map
   * @param searchedText : searched text used to get the filterd data based on user search.
   */
  getMapSearchedDetail(searchedText: string = '') {
    let queryParams = { ...this.reportService.queryParams };
    let year = new Date().getFullYear();
    if (queryParams?.year) {
      year = this.reportService.yearMaxValue(queryParams.year);
    }
    searchedText = searchedText.trim();
    if (searchedText.length >= 3)
      this.showRecentSearch = false;
    else {
      this.showRecentSearch = true
      return
    }
    this.reportService.getMapSearchedDetail(searchedText, year).subscribe({
      next: (res: any) => {
        if (res?.status == 200) {
          this.searchedData = res.data
        } else {
          this.searchedData = []
        }
      },
      error: (err: any) => {
        this.alertService.error(err)
      }
    })

  }
  /**
   *
   */
  getRecentSearchedParcelDetail() {
    this.reportService.getRecentSearchedParcelDetail().subscribe({
      next: (res: any) => {
        if (res && res.status == 200)
          this.recentSearchedData = res?.data
        else {
          this.recentSearchedData = [];
        }
      },
      error: (err) => {
        this.recentSearchedData = [];
      }
    })
  }
  addRecentSearchedParcelDetail(propertyData: any, searchedText: string = "") {
    let payload = {
      property_uid: propertyData.property_uid,
      neigh_name: propertyData.neigh_name,
      block_name: propertyData.block_name,
      property_name: propertyData.property_name ? propertyData.property_name : "",
      search_text: this.searchpropertyDetail.searchControl.value
    }
    this.reportService.addRecentSearchedParcelDetail(payload).subscribe({
      next: (res: any) => {
        if (res && res.status == 201)
          this.getRecentSearchedParcelDetail();
      },
      error: (err) => {

      }
    })
  }
  deleteRecentSearchedParcelDetail(action: string, id: number = 0) {
    this.reportService.deleteRecentSearchedParcelDetail(action, id).subscribe({
      next: (res: any) => {
        if (res && res.status == 201)
          this.getRecentSearchedParcelDetail();
      },
      error: (err: any) => { }
    })

  }

  async locateSearchedParcelOnMap(properties: any) {
    this.isShowChip = true;
    this.isOpenMapSearch = false
    this.showRecentSearch = true;
    this.removeScrollPropagationEvent();
    this.universalSearchPropertyDetail = "";
    this.reportService.transactionPropertyDetails.next(false);
    if (this.mapMarker)
      this.mapMarker.remove();
    if (properties.neigh_name)
      this.universalSearchPropertyDetail += properties.neigh_name
    if (properties.block_name) {
      this.universalSearchPropertyDetail += this.universalSearchPropertyDetail ? ' > ' + properties.block_name : properties.block_name;
    }
    if (properties.property_name) {
      this.universalSearchPropertyName = properties.property_name
      this.universalSearchPropertyDetail += this.universalSearchPropertyDetail ? ' > ' + properties.property_name : properties.property_name;
    }
    this.addRecentSearchedParcelDetail(properties);
    let content: any;
    this.mapWidth = this.mapIdentifier.nativeElement.offsetWidth;
    this.mapHeight = this.mapIdentifier.nativeElement.offsetHeight;
    let that = this;
    try {
      const propertyData: any = await that.mapService.getParcelInfo(properties?.property_uid);
      if (propertyData.status == 200 && propertyData?.data?.length) {
        properties = propertyData.data[0];
        // let options = that.mapService.popupOptions(properties , that.mapWidth, that.mapHeight);
        if (that.currentReportData && that.currentReportData?.name.includes('transaction')) {
          content = that.mapService.popup_content('transaction', properties, that.currentReportData?.allowed_attributes, '', '')
          that.popupContent = { type:'transaction', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes }
        } else {
          content = that.mapService.popup_content('property', properties, that.currentReportData?.allowed_attributes, '', '');
          that.popupContent = { type:'property', propertiesdata: properties, allowedAttributes: that.currentReportData?.allowed_attributes }
        }
        this.map.setView([properties.lat, properties.lon], 16, {
          "animate": true,
          "pan": {
            "duration": 0.5
          }
        });
        const customIcon = L.icon({
          iconUrl: 'assets/images/location.svg',
          iconSize: [27, 33],
          iconAnchor: [14, 23],
          className: ['tenant-icon_']

        });
        this.mapMarker = L.marker([properties.lat, properties.lon], { icon: customIcon }).addTo(this.map);
        this.mapMarker.on('click', (e:any) => {
          let options = that.mapService.popupOptions(e, that.mapWidth, that.mapHeight);
          this.universalSearchPopUp = L.popup({ autoPan: false, offset: [options.x, options.y], className: options.class_name})   
            .setContent(content)
            .setLatLng([properties.lat, properties.lon])
            // .openOn(that.map)
            this.universalSearchPopUp.openOn(that.map);
        })
        setTimeout(() => {
          this.universalSearchPopUp = L.popup({ autoPan: true, className: 'topCenterPopupCard' })
          .setContent(content)
          .setLatLng([properties.lat, properties.lon])
          // .openOn(that.map)
          this.universalSearchPopUp.openOn(that.map);
        }, 450);
      } else {
        console.error("No Data");
      }
    } catch (error) {
      console.error("Failed to fetch property data:", error);
    }
  }
  showUniversalSearch() {
    this.reportService.transactionPropertyDetails.next(false);
    if (this.universalSearchPopUp)
      this.universalSearchPopUp.remove();
    if (this.mapMarker)
      this.mapMarker.remove();
  }


  loadCatchment() {
    this.loadCatchmentShow = true;
  }

  closeLoadCatchment(event: any) {
    this.loadCatchmentShow = false;
    this.reLoadCatchmentShow = false
  }

  loadCatchmentModel() {
    this.resetCatchmentConfirm('clear')
    this.reLoadCatchmentShow = true;
    this.loadCatchmentShow = true;
  }

  getSavedCatchment(event: any = '') {
    this.reportService.savedFilterList(event).subscribe((data: any) => {
      if (data.data.length) {
        this.savedCatchment = data.data;
        this.reportService.savedCatchmentList = data.data;
      } else {
        this.savedCatchment = [];
        this.reportService.savedCatchmentList = [];
      }
    });
  }

  drawNewCatchmentLoad() {
    this.resetCatchmentConfirm('clear')
    setTimeout(() => {
      this.drawPolygon()
      this.map.removeLayer(this.report_countLayer);
      this.mapService.getReportCount(null).subscribe((layerdata: any) => {
        let data = layerdata.data;
        this.reportCountLayer(data);
      })
    }, 0)

  }

}

