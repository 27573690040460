import { ReportTableColumn, ReportTableDropdown } from "../../models/reports.model";
import { RetailRentalMenuEnum } from "./report-retail-rental.enum";
import { DataType } from "src/app/common/models/enums/common.enum";

export class RetailRentalReportConstant {

    static overViewMenu = [
        {
            label: 'reports.overview',
            name: RetailRentalMenuEnum.overview,
            count: null
        },
        {
            label: 'reports.rentUnitSizeWise',
            name: RetailRentalMenuEnum.retailUnitSize,
            count: null
        },
        {
            label: 'reports.unit',
            name: RetailRentalMenuEnum.retailUnit,
            count: null
        }
    ]

    static retailUnitsizeExpandColumns: any[] = [
        { field : 'category', convertNumber: false },
        { field : 'lower_ground_range', convertNumber: false },
        { field : 'ground_range', convertNumber: false },
        { field : 'first_floor_range', convertNumber: false },
        { field : 'second_floor_range', convertNumber: false },
        { field : 'third_floor_range', convertNumber: false }
    ]
    static retailUnitsizeExpandAverageColumns: any[] = [
        { field : 'category', convertNumber: false },
        { field : 'lower_ground_avg', convertNumber: true },
        { field : 'ground_avg', convertNumber: true },
        { field : 'first_floor_avg', convertNumber: true },
        { field : 'second_floor_avg', convertNumber: true },
        { field : 'third_floor_avg', convertNumber: true }]


    static retailUnitsizeAverageColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'lower_ground_avg',
            columnName: 'reports.lower_ground',
            field: 'lower_ground_avg',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'lower_ground_range',
        },

        {
            header: 'ground_avg',
            columnName: 'reports.ground',
            field: 'ground_avg',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'ground_range',
        },
        {
            header: 'first_floor_avg',
            columnName: 'reports.first_floor',
            field: 'first_floor_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'first_floor_range',
        },
        {
            header: 'second_floor_avg',
            columnName: 'reports.second_floor',
            field: 'second_floor_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'second_floor_range',
        },
        {
            header: 'third_floor_avg',
            columnName: 'reports.third_floor',
            field: 'third_floor_avg',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: true,
            toolTipField:'third_floor_range'
        }

    ]

    static retailUnitsizeColumns: ReportTableColumn[] = [
        {
            header: 'unit_size',
            columnName: 'reports.unit_size',
            field: 'unit_size',
            isSortable: false,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'lower_ground_range',
            columnName: 'reports.lower_ground',
            field: 'lower_ground_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
             toolTipField:'lower_ground_avg'
        },

        {
            header: 'ground_range',
            columnName: 'reports.ground',
            field: 'ground_range',
            symbol: DataType.currencyWithSquareMeter,
            isSortable: false,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
             toolTipField:'ground_avg'
        },
        {
            header: 'first_floor_range',
            columnName: 'reports.first_floor',
            field: 'first_floor_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'first_floor_avg'
        },
        {
            header: 'second_floor_range',
            columnName: 'reports.second_floor',
            field: 'second_floor_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'second_floor_avg'
        },
        {
            header: 'third_floor_range',
            columnName: 'reports.third_floor',
            field: 'third_floor_range',
            symbol: DataType.currencyWithSquareMeter,
            border: false,
            isSortable: false,
            isContainsHTML: false,
            convertNumber: false,
            toolTipField:'third_floor_avg'
        }

    ]

    static retailUnitColumns: ReportTableColumn[] = [
        {
            header: 'tenant_name_unit_number',
            columnName: 'reports.tentant_name_with_unit_number',
            field: 'tenant_name_unit_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            isTenent:true
        },
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            tenentShowOnMap:true
        },
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'gross_area',
            columnName: 'reports.gross_area',
            field: 'gross_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            border: false,
            symbol: DataType.currencyWithSquareMeter,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'monthly_rental',
            columnName: 'reports.monthly_rentals',
            field: 'monthly_rental',
            border: false,
            isSortable: true,
            symbol: DataType.currencyCode,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'is_anchor',
            columnName: 'reports.anchor',
            field: 'is_anchor',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'tenant_category',
            columnName: 'reports.tenant_category',
            field: 'tenant_category',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        // {
        //     header: 'unit_size',
        //     columnName: 'reports.unit_size',
        //     field: 'unit_size',
        //     border: false,
        //     isSortable: true,
        //     symbol: DataType.squareMeter,
        //     isContainsHTML: false,
        //     convertNumber: true
        // },
        // {
        //     header: 'tenant_name',
        //     columnName: 'reports.tenant_name',
        //     field: 'tenant_name',
        //     border: false,
        //     isSortable: true,
        //     isContainsHTML: false,
        //     convertNumber: false
        // },


        // {
        //     header: 'date',
        //     columnName: 'Date',
        //     field: 'date',
        //     border: false,
        //     isSortable: false,
        //     isContainsHTML: false,
        //     convertNumber: false
        // },
        // {
        //     header: 'property_type',
        //     columnName: 'reports.property_type',
        //     field: 'property_type',
        //     border: false,
        //     isSortable: true,
        //     isContainsHTML: false,
        //     convertNumber: false
        // },
        {
            header: 'property_uid',
            columnName: 'reports.property_uid',
            field: 'property_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_uid',
            columnName: 'reports.unit_uid',
            field: 'unit_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'categories',
            columnName: 'reports.categories',
            field: 'categories',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]
    static retailUnitColumnsYearly: ReportTableColumn[] = [
        {
            header: 'tenant_name_unit_number',
            columnName: 'reports.tentant_name_with_unit_number',
            field: 'tenant_name_unit_number',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            isTenent:true
        },
        {
            header: 'property_name',
            columnName: 'reports.PropertyName',
            field: 'property_name',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false,
            showOnMap:true,
            tenentShowOnMap:true
        },
        {
            header: 'floor',
            columnName: 'reports.floor',
            field: 'floor',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'gross_area',
            columnName: 'reports.gross_area',
            field: 'gross_area',
            isSortable: true,
            border: false,
            symbol: DataType.squareMeter,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'lease_rate',
            columnName: 'reports.lease_rate',
            field: 'lease_rate',
            border: false,
            symbol: DataType.currencyWithSquareMeter,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'yearly_rental',
            columnName: 'reports.yearly_rentals',
            field: 'yearly_rental',
            border: false,
            isSortable: true,
            symbol: DataType.currencyCode,
            isContainsHTML: false,
            convertNumber: true
        },
        {
            header: 'is_anchor',
            columnName: 'reports.anchor',
            field: 'is_anchor',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'neighborhood',
            columnName: 'reports.NEIGHBORHOODS',
            field: 'neighborhood',
            isSortable: true,
            border: false,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'tenant_category',
            columnName: 'reports.tenant_category',
            field: 'tenant_category',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'property_uid',
            columnName: 'reports.property_uid',
            field: 'property_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'unit_uid',
            columnName: 'reports.unit_uid',
            field: 'unit_uid',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        },
        {
            header: 'categories',
            columnName: 'reports.categories',
            field: 'categories',
            border: false,
            isSortable: true,
            isContainsHTML: false,
            convertNumber: false
        }
    ]

    static unitSizeDropdown: ReportTableDropdown = {
        label: 'reports.show_price_in',
        data: [{
            value: 'Range',
            id: 'Range',
        },{
            value: 'Average',
            id: 'Average',
        }],
        default: 'Range',
        maxSelect: 1,
        reportType : false
    }

}
