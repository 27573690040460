<div class="excel-download" [ngClass]="{ 'excel-ar-download': current_lang === 'ar'}" *ngIf="dataValue.length > 0 && isDownloadExcel">
    <button [disabled]="reportService.getLockReport()" (click)="downloadExcel()" matTooltip="{{'reports.download_excel' | translate}}">
        <img *ngIf="!reportService.getLockReport()" src="../../../../../../assets/images/excel-icon.svg">
        <img *ngIf="reportService.getLockReport()" src="../../../../../../assets/images/excel-icon-disable.svg">
    </button>
</div>  
<div class="table-expanded" *ngIf="!singleColumn">

    <div class="table-container">
        <div [class.combined_both]="!dataValue.length" style="position: relative;">
            <table>
                <thead class="table-clr">
                    <tr class="virtualScroll">
                        <th></th>
                        <th scope="col" *ngFor="let col of tableColumn">{{ col.header | translate }}</th>
                    </tr>
                </thead>
                <tbody class="table-body">
                    <ng-container *ngFor="let data of dataValue; let d = index">

                        <ng-container *ngIf="data.expand">

                            <tr *ngFor="let subData of data.subReport; let j = index" >
                                <td [ngClass]="{'blur-content': data['NODATA']}">{{ j === 0 ? d + 1 : ''}}</td>
                                <td scope="row" *ngFor="let col of tableColumn; let i = index" [ngClass]="{'blur-content': data['NODATA']}">
                                    <span class="expand">
                                        <span class="expandVal" *ngIf="subData[col.field]"> {{ data.expand && j === 0 || i !==0 ? col?.convertNumber ? (subData[col.field] | number:'1.0-1') :  subData[col.field] : '' }} {{ col?.cityCode ? (cityCurrencyCode | translate) : col?.symbol }} </span>
                                        <span *ngIf="!subData[col.field]"> {{ data.expand && j === 0 || i !==0 ? '--' : '' }} </span>

                                        <mat-icon *ngIf="j === 0 && i === 0 && data.expand" (click)="expandMinus(data)" class="icon-plus">remove</mat-icon>
                                    </span>
                                </td>
                            </tr>
                        </ng-container>
                        <tr>
                            <td [ngClass]="{'blur-content': data['NODATA']}">{{ !data.expand ? d + 1 : '' }}</td>
                            <td scope="row" *ngFor="let col of tableColumn; let i = index" [ngClass]="{'bg-border': col.border}" [ngClass]="{'blur-content': data['NODATA']}">
                                <span class="expand">
                                    <span class="expandVal" *ngIf="data[col.field]">{{ data.expand && i === 0 ? '' : col?.convertNumber ? (data[col.field] | number:'1.0-1') :  data[col.field] }} {{ data.expand && i === 0 ? '' : col?.cityCode ? (cityCurrencyCode | translate) : (col?.symbol | translate) }}</span>
                                    <span *ngIf="!data[col.field]">{{ data.expand && i === 0 ? '' : '--' }}</span>
                                    <mat-icon *ngIf="i === 0 && !data.expand && expandable" (click)="expandPlus(data)" class="icon-plus">add</mat-icon>
                                </span>
                            </td>
                        </tr>
                    </ng-container>

                </tbody>
            </table>
             <!-- Table content blur start -->
            <div class="lock-table-row" *ngIf="LockReport && dataValue.length>0">
                <img src="assets/images/Lock.png" alt="">
            </div>
            <!-- Table content blur end -->
            <div class="noRecord" *ngIf="dataValue.length === 0">{{"reports.dataComingSoon" | translate}}</div>
        </div>
        <p *ngIf="LockReport" style="margin: 10px 0 0; font-size: 12px;">
            <span class="contact-sale" (click)="openContactSales()">{{'contactSales' | translate }}</span>
            {{'to_unlock' | translate }} {{total}} {{'rows_more'| translate}}.
          </p>
    </div>  
   

</div>

<div class="single-column" *ngIf="singleColumn && dataValue.length > 0">
    <ng-container *ngFor="let data of dataValue; let d = index">
        <div class="column" *ngFor="let column of tableColumn">
            <span>{{ column.header | translate }}</span>
            <span *ngIf="data[column.field]">{{ data[column.field] | number:'1.0-1' }} {{ column?.cityCode ? (cityCurrencyCode | translate) : (column?.symbol | translate) }}</span>
            <span *ngIf="!data[column.field]">--</span>
        </div>
    </ng-container>


</div>
