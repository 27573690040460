import { Component, PLATFORM_ID, Inject, OnInit, ChangeDetectorRef, HostListener } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';

import { interval, Subject, Subscription, takeUntil, window } from 'rxjs';

import { TranslateService } from '@ngx-translate/core';
import { DatePipe, DOCUMENT, isPlatformBrowser , Location} from '@angular/common';
import { commonContants } from './common/constants/common.contants';
import { SharedService } from './common/services/shared.service';
import { LanguagesEnum, LoaderType } from './common/models/enums/common.enum';
import { CityModelComponent } from './common/components/city-model/city-model.component';
import { GlobalContants } from './common/constants/global.contants';
import { SpinnerService } from './common/components/spinner/spinner.service';
import { HotjarService } from './common/services/hotjar.service';
import { productFruits } from 'product-fruits';
import { UserService } from './common/services/user.service';
import { StoreService } from './common/services/store.service';
import { MobileViewPopUpComponent } from './common/components/mobile-view-pop-up/mobile-view-pop-up.component';
import { GoogleAnalyticsService } from './common/services/google-analytics.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  private unsubscribe$: Subject<void> = new Subject<void>();
  isLoggedIn: boolean = false;
  isProductInit: boolean = false;
  commonInfo = commonContants;
  isBrowser: boolean;
  eventSubs: Subscription[] = [];
  currentRoute: string = '';
  dialogRef: any;
  ip: any;
  user: any;
  ipCountry: any;

  loaderType = LoaderType;
  loader: string = LoaderType.skip;
  sidebarCollapse: any;
  cityList$ = this.sharedService.cityList$$;
  endTime: boolean = false;

  constructor(
    public datepipe: DatePipe,
    private storeService: StoreService,
    private hotjarService: HotjarService,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId: any,
    private sharedService: SharedService,
    private dialog: MatDialog,
    private router: Router,
    private spinnerService: SpinnerService,
    private activatedRoute : ActivatedRoute,
    private userService : UserService,
    private location : Location,
    private cdref: ChangeDetectorRef,
    private googleAnalytics: GoogleAnalyticsService,
    @Inject(DOCUMENT) private document: Document,
  ){
    this.isBrowser = isPlatformBrowser(this.platformId);
    if (this.isBrowser) {
      if(!this.sharedService.getCityList()?.length)this.sharedService.getCity();
      this.getLanguageList();
      this.checkLogin();
      this.checkRoute();
    }
    this.callLoader();
    // this.initFrouits(true);
    // this.addUserGuidingScript();

    //After Login user hit to login url to redirect dashboard
    if (this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)){
      const hrefPath = this.location.path().replace('/','');
      const authRoutes: any = [
        GlobalContants.Routes.Log_In,
        GlobalContants.Routes.Signup,
        GlobalContants.Routes.SignUp,
        GlobalContants.Routes.RequestDemo
      ]
      if(authRoutes.includes(hrefPath)){
        const lang = this.sharedService.getCurrentLang() || 'en';
        const city = this.sharedService.getStoreValue(GlobalContants.StoreKey.Slug);
        this.router.navigateByUrl(`${city}-${lang}/${GlobalContants.Routes.Reports}`);
      }
    }
  }

 

  ngOnInit(): void {
    // this.googleAnalytics.loadGoogleAnalytics();
    // this.router.events.subscribe(event => {
    //   if (event instanceof NavigationEnd) {
    //     this.googleAnalytics.setPage(event.urlAfterRedirects);
    //   }
    // });


    this.activatedRoute.queryParams.subscribe((res) => {
        if(res['verify'] === 'true') {
          this.userService.userLogout(false, true);
        }
    })
    this.hotjarService.loadHotjar();
    this.urlCityName();
  }
  ngAfterContentChecked(){
    this.cdref.detectChanges();
  }
  getCurrentLang(): string {
    if (this.isBrowser) {
      return this.storeService.get(GlobalContants.StoreKey.CurrentLang)
    }
    return '';
  }
  /**
   *
   * @returns
   * If type the city in the URL, so the city change
   */
  urlCityName(){
    if (this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)){
      //get current path
      const url = document.location.pathname;
      //If path '' & '/' so redirect page to reports
      if(url === '' || url === '/'){
        this.redirectLanding();
        return;
      }
      //get city & lang
      let splitUrl = url.split('/')[1]?.split('-') || []; 
      let extractedLang: string = splitUrl.pop() || '';
      let extractedCity: string = splitUrl.length ? splitUrl.join('-') : '';
      let lang = this.getCurrentLang() || 'en';
      //get city & lang not match redirect to 404 page
      if(!extractedCity || !extractedLang){
        this.router.navigate(['/page-not-found']);
        return;
      }
      if(extractedCity === this.sharedService.getStoreValue(GlobalContants.StoreKey.Slug)){
        return;
      }
      if(this.sharedService.getStoreValue(GlobalContants.StoreKey.cityList).includes(extractedCity)){
        this.router.navigateByUrl(`${extractedCity}-${lang}/${GlobalContants.Routes.Reports}`);
          this.sharedService.getCitylist().subscribe({
            next: (cityRes:any) => {
              if(cityRes.status == 200){
                let city = cityRes.data.find((x: any) => x.slug.toLowerCase() === extractedCity);
                this.sharedService.setCity(city);
              }
            }
          });
      }else{
        let citySlug = this.sharedService.getCurrentCitySlug();
        const pathOnly = this.location.path();
        if(pathOnly.includes(GlobalContants.Routes.RequestDemo)) {
          this.router.navigate([GlobalContants.Routes.RequestDemo]);
        } else if(!citySlug && pathOnly.includes(GlobalContants.Routes.Reports)) {
          this.router.navigate([GlobalContants.Routes.Reports]);
        } else {
          this.router.navigate(['/page-not-found']);
        }
      }
    } else {
      // this.dialog.closeAll();
    }
  }

  callLoader(){
    this.spinnerService.isLoader$.subscribe((res: any) => {
      this.loader = res;
    })
  }
    // To get list of language
    getLanguageList() {
      let languageLocal:any = this.sharedService.getLangList();
      if(languageLocal){
        this.setLang(languageLocal, false)
      }
      this.sharedService.getLanguageList().subscribe({
        next: (res: any) => {
          if (res && res.data) {
            let language = res.data.map((item:any) => {
              return item.language_code
            })
            this.storeService.add(GlobalContants.StoreKey.langList, language);
            if(!languageLocal) this.setLang(language, false)
          }
        },
        error: (err) => {
          console.error(err.error.message);
        },
      });
    }

    /**
     * setLang function used to set default language
     * @param language list
     */
  async setLang(language:any='', isDefault:boolean=false){
    const pathOnly = this.location.path();
    let cityList:any = this.sharedService.getCityList()? this.sharedService.getCityList():this.cityList$();
    let lang = this.sharedService.getCurrentLang();
    let citySlug = this.sharedService.getCurrentCitySlug();

    let currentLang = lang ? lang : 'en';
    let urlChanged:boolean = false;
    let urlSlug = (pathOnly != '/')?(pathOnly.split('/')[1]):null;
    const lastIndex = urlSlug? urlSlug.lastIndexOf('-'):1;
    const slugCity = urlSlug? urlSlug.slice(0, lastIndex):'';
    const slugLang = urlSlug? urlSlug.slice(lastIndex + 1):'';
    let outerRoute = (!pathOnly.includes(GlobalContants.Routes.RequestDemo) && !pathOnly.includes(GlobalContants.Routes.ForgotPassword) && !pathOnly.includes(GlobalContants.Routes.SignUp) && !pathOnly.includes(GlobalContants.Routes.Signup) && !pathOnly.includes('page-not-found') && !pathOnly.includes(GlobalContants.Routes.Log_In))?true:false;
    if(outerRoute && ((slugLang && slugLang != lang) || (slugCity && slugCity != citySlug))){
       if((language.indexOf(slugLang.toLowerCase()) === -1) || (cityList.indexOf(slugCity.toLowerCase()) === -1)) {
        urlChanged=true;
       } else {
         currentLang = slugLang;
        // await this.sharedService.setSelectedCity(slugCity, pathOnly, true)
       }
    }
    this.sharedService.languageList.set(language)
    this.translate.addLangs(language);
    this.translate.setDefaultLang(currentLang);
    let urlLangId = (currentLang == commonContants.englishCode)?LanguagesEnum.english:LanguagesEnum.arabic;
    let currenLangID = urlLangId ? urlLangId : LanguagesEnum.english;
    if(urlChanged) {
     if(pathOnly.includes(GlobalContants.Routes.notVerified)){
      this.router.navigate(['/'+GlobalContants.Routes.Log_In], { replaceUrl: true });
     }else{
      // this.sharedService.setLanguage(currenLangID, pathOnly);
      this.router.navigate(['/page-not-found']);
     }
      
      
    }
    else this.sharedService.setLanguage(currenLangID);
    this.sharedService.language$.subscribe(lang => {
      this.translate.use(lang);
    })
  }
  ngAfterViewInit(){
    if(this.isLoggedIn) document.body.classList.remove('logged-out');
    let country:any = this.storeService.get(GlobalContants.StoreKey.PlaceName);
    if(country && !this.isProductInit) this.initFrouits(true);
  }
/**
 * Initialize Products Fruits code (Product key owner webmaster)
 * @param init boolean
 * @param selectedLanguage selected language
 */
  async initFrouits(init:boolean=false, selectedLanguage:string='en'){
    this.isProductInit = true;
    this.user = this.sharedService.getUserName();
    let country:any = this.storeService.get(GlobalContants.StoreKey.PlaceName);
    if(!country){
      try {
        let x = await this.getUserLocation();
        country = this.ipCountry;
      } catch (error) {
        let x = await this.getIPAddress();
        country = this.ip;
      }
    }
    let created_date = this.user.created_date? this.datepipe.transform(this.user.created_date, 'yyyy-MM-dd'):this.user.createdDate;
    if(init){
      // productFruits.init('nUUmukv26yvtak0x', selectedLanguage, { username: userName, firstname: userName}, {disableLocationChangeDetection: true});
      productFruits.init('nUUmukv26yvtak0x', selectedLanguage, { username: this.user.uname, email: this.user.email, lastname: country, role: created_date, signUpAt: created_date , props: {
            country: country,
        }
      });
    }
  }
  checkLogin(){
    this.sharedService.isUserInfoLoaded.subscribe((res: boolean) => {
      if(res){
        document.body.classList.remove('logged-out');
         this.isLoggedIn = true;
         if(!this.isProductInit) this.initFrouits(true);
          this.openCityModel();
        } else {
          document.body.classList.add('logged-out');
          this.isLoggedIn = false;
          this.dialogRef = null;
        }
    })
  }

  async getUserLocation() {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          try {
            // Fetch the address by latitude and longitude
            const address$ = (await this.sharedService.getAddressByLatlon(
              position.coords.latitude,
              position.coords.longitude
            )).pipe(takeUntil(this.unsubscribe$));

            address$.subscribe((res: any) => {
              const results = res.results[0];
              if (results) {
                const fullAddr: any = results.address_components;
                // Extract the country from the address components
                fullAddr.forEach((element: any) => {
                  if (element.types.includes('country')) {
                    this.ipCountry = element.long_name;
                  }
                });

                // Resolve the promise with the required data
                resolve(results);
              } else {
                reject(new Error("No results found"));
              }
            });
          } catch (error) {
            reject(error);
          }
        },
        (error) => {
          reject(error);
        }
      );
    });
  }
  async getIPAddress() {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      this.ip = data.ip;
      return data.ip;
    } catch (error:any) {
      console.error("Error fetching IP address:", error.message);
      return { error: error.message };
    }
  }

  checkRoute(){
    this.eventSubs.push(this.router.events.subscribe((event: any) => {
      if(event?.url){
        const url = event?.url?.split('?')[0];
      }
      switch(true){
        case event instanceof NavigationStart:{
          break;
        }
        case event instanceof NavigationEnd: {
          this.currentRoute = event.url;
          this.authLogin();
        }
      }
    }))
  }

  redirectLanding(){
    const lang = this.sharedService.getCurrentLang() || 'en';
    const city = this.sharedService.getStoreValue(GlobalContants.StoreKey.Slug);
    if(city) {
      this.router.navigateByUrl(`${city}-${lang}/${GlobalContants.Routes.Reports}`);
    } else {
     this.router.navigateByUrl(`/${GlobalContants.Routes.Reports}`);
    }
  }

  authLogin(){
    if(this.sharedService.getStoreValue(GlobalContants.StoreKey.userToken)){
      this.sharedService.isUserInfoLoaded.next(true);
      this.checkRedirectRoute();
    }
  }

  checkRedirectRoute(){
    if(this.currentRoute === `/${GlobalContants.Routes.Signup}` || this.currentRoute === `/` || this.currentRoute === `/${GlobalContants.Routes.ForgotPassword}`){
      this.redirectLanding();
    }
  }
  checkIfMobile(): boolean {
    const userAgent = navigator.userAgent || navigator.vendor || (window as any).opera;
    // Check if the user agent indicates a mobile device
    if (/android/i.test(userAgent)) return true;
    if (/iPhone|iPad|iPod/i.test(userAgent)) return true;
    return false;
  }
  openCityModel(): void{
    if(this.checkIfMobile() && this.sharedService.canOpenPopUp) {
      this.sharedService.canOpenPopUp = false;
      this.dialog.open(MobileViewPopUpComponent, {
        minWidth: '95vw',
        maxWidth: '95vw',
        height: 'auto',
        panelClass: 'custom-dialog-container'
      }).afterClosed().subscribe(() => {
          this.openCityModelPopUp();
      })
    }else {
      this.openCityModelPopUp();
    }

  }
  openCityModelPopUp() {
    if(this.sharedService.getStoreValue(GlobalContants.StoreKey.PlaceId) || this.dialogRef){
      return;
    }
    this.dialogRef = this.dialog.open(CityModelComponent, {
      maxWidth: '95vw',
      maxHeight: '97vh',
      width:'618px',
      panelClass: 'custom-model-container',
      disableClose: true,
      closeOnNavigation: false
    });

    this.dialogRef.afterClosed().subscribe((result: any) => {
      this.initFrouits(true);
    //   productFruits.updateUserData({
    //     username: this.user.email,
    //     props: {
    //       country: result.selectedCity.place_name
    //     },
    // })
    });
  }
  get nativeWindow(): Window {
    return this.document.defaultView as Window;
  }

  get currentUrl(): string {
    return this.nativeWindow.location.href;
  }
  addUserGuidingScript() {
    
    //  if(this.currentUrl.includes('markets.dev10.in')){
      const scriptId = 'user-guiding-script';

      // Check if the script already exists in the document
      if (!document.getElementById(scriptId)) {
          const script = document.createElement('script');
          script.id = scriptId;
          script.async = true;
          script.src = 'https://static.userguiding.com/media/user-guiding-CEX87184F1TID-embedded.js';

          const head = document.head || document.getElementsByTagName('head')[0];
          head.appendChild(script);

          (window as any).userGuidingLayer = (window as any).userGuidingLayer || [];
          const ug:any = (window as any).userGuiding = { q: [] };

          const methods = [
              'previewGuide',
              'finishPreview',
              'track',
              'identify',
              'hideChecklist',
              'launchChecklist'
          ];

          methods.forEach(method => {
              ug[method] = function() {
                  ug.q.push([method, arguments]);
              };
          });
      //}
    }
  }
  collapseToggle(e: any){
    this.sidebarCollapse = e;
  }
}
