<ng-container *ngIf="loading">
    <app-skeleton-loader [loaderSize]="1" [reportType]="chartView.overView"></app-skeleton-loader>
</ng-container>

<div class="prop-info detail-info" *ngIf="PropertyDetails && !loading">

    <div class="prop-detail">

        <h2>{{(PropertyDetails?.property_name && PropertyDetails?.property_uid )?  PropertyDetails.property_name + ' (' + PropertyDetails.property_uid + ')' : PropertyDetails.property_uid}}</h2>

        <div class="prop-list">
            <ul>
                <li><span>{{'reports.address' | translate}} : </span> {{ PropertyDetails.address ? PropertyDetails.address : '-' }}</li>
                <li><span>{{'reports.constructionStatus' | translate}} : </span>  {{ PropertyDetails.construction_status  ? (PropertyDetails.construction_status | translate) : '-' }}</li>
                <li><span>{{'reports.landNumber' | translate}} : </span>  {{ PropertyDetails.land_number ? PropertyDetails.land_number : '-' }}</li>
                <li><span>{{'reports.yearOfBuild' | translate}}: </span>  {{ PropertyDetails.year_of_build ? PropertyDetails.year_of_build : '-' }} <ng-container *ngIf="PropertyDetails?.property_age">({{PropertyDetails?.property_age}})</ng-container></li>
                <li *ngIf="PropertyDetails.developer_name"><span>{{'reports.developer' | translate}} : </span>  {{ PropertyDetails.developer_name ? PropertyDetails.developer_name : '-' }}</li>
                <li><span>{{'reports.Municipal_land_zone' | translate}}: </span>  {{ PropertyDetails.zoning ? PropertyDetails.zoning : '-' }}</li>
            </ul>


        </div>

        <div class="land-value" *ngIf="PropertyDetails.land_value">
            <span>{{'reports.land_value_estimate' | translate}} &nbsp;&nbsp;&nbsp; <img *ngIf="PropertyDetails?.land_value" src="../../../../../../../../../assets/images/push-color.svg" alt=""> {{ PropertyDetails.land_value | number : "1.0-1" }} {{CurrencyCode}}</span>
        </div>

        <div class="property-cat"  *ngIf="PropertyDetails.property_categories">
            <h2>{{'reports.property_categories' | translate}}</h2>
            <ul>
                <li>{{ PropertyDetails.property_categories  ? PropertyDetails.property_categories : '-' }}</li>
            </ul>
        </div>


        <div class="land-info">
            <h2>{{'reports.land_information' | translate}} <i class="contant-icon" matTooltipPosition="above" matTooltipClass="custom-tooltip" [matTooltip]="'reports.thisSectionProvidesBasicDetails' | translate"></i></h2>
            <div class="info-land">
                <div class="p-info">
                    <h6>{{'reports.land_area' | translate}}</h6>
                    <h3>{{ PropertyDetails.land_area  ? PropertyDetails.land_area : '-' }}  <sup *ngIf="PropertyDetails.land_area">{{unitType | translate}}</sup></h3>

                </div>
                <div class="p-info" *ngIf="!multi_story_residential">
                    <h6>{{'reports.frontage' | translate}}</h6>
                    <h3>{{ PropertyDetails.frontage  ? (PropertyDetails.frontage) : '-' }} <sup *ngIf="PropertyDetails.frontage">{{'meter' | translate}}</sup></h3>

                </div>
                <div class="p-info">
                    <h6>{{'reports.setback' | translate}}</h6>
                    <h3>{{ PropertyDetails.setback  ? (PropertyDetails.setback) : '-' }} <sup *ngIf="PropertyDetails.setback">{{unitType | translate}}</sup></h3>

                </div>
                <div class="p-info">
                    <h6>{{'reports.corners' | translate}}</h6>
                    <h3 [ngClass]="{'nocorner-txt': !PropertyDetails?.corners}">{{ PropertyDetails.corners  ? PropertyDetails.corners : ('reports.no_corner' | translate)  }}</h3>
                </div>
                <div class="p-info" *ngIf="multi_story_residential">
                    <h6>{{'Permissible' | translate}}</h6>
                    <h3>{{ PropertyDetails.far  ? (PropertyDetails.far | number : '1.0-1') : '-' }} <sup *ngIf="PropertyDetails.far">%</sup></h3>
                </div>
            </div>
        </div>

        <div class="property-zone property-builtup" *ngIf="PropertyDetails?.total_buildup_area">
            <h4>{{'reports.builtup_information' | translate}}</h4>
            <div class="zoning-info builtup-info">
                <span *ngIf="PropertyDetails?.total_buildup_area">{{'reports.builtup_area' | translate}}: <strong>{{PropertyDetails?.total_buildup_area ?
                      (PropertyDetails?.total_buildup_area | number : '1.0-1') +' '+ ('reports.sqm_unit' | translate): ('na' | translate)}}</strong></span>
                <!-- <span>{{'reports.year_of_build' | translate}}:<strong>{{propertyDetail?.build_year || ('na' | translate)}}</strong></span> -->
                <span *ngIf="PropertyDetails?.floors"><strong>{{PropertyDetails?.floors }} {{'reports.floor' | translate}}</strong></span>
                <span *ngIf="PropertyDetails?.furnishing_status">{{'reports.finish' | translate}}: <strong>{{PropertyDetails?.furnishing_status || ('na' | translate)}}</strong></span>
                <span *ngIf="PropertyDetails?.bed_rooms"><strong>{{PropertyDetails?.bed_rooms}}</strong></span>
                <span *ngIf="PropertyDetails?.balconies"><strong>{{PropertyDetails?.balconies}}</strong></span>
                <span *ngIf="PropertyDetails?.parking"><strong>{{(PropertyDetails?.parking | number : '1.0-0')}} {{'reports.parking' | translate}}</strong></span>
            </div>
        </div>
    </div>

    <div class="prop-img">
        <img *ngIf="PropertyDetails.corners == '0'" src="../../../../../../../../../assets/images/corner_0.svg" alt="Image">
        <img *ngIf="PropertyDetails.corners == '1'" src="../../../../../../../../../assets/images/corner_1.svg" alt="Image">
        <img *ngIf="PropertyDetails.corners == '2'" src="../../../../../../../../../assets/images/corner_2.svg" alt="Image">
        <img *ngIf="PropertyDetails.corners != '0' && PropertyDetails.corners != '1' && PropertyDetails.corners != '2'" src="../../../../../../../../../assets/images/corner_4.svg" alt="Image">
    </div>

</div>
