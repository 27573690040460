import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AlertService } from 'src/app/common/components/alert/alert.service';
import { SharedService } from 'src/app/common/services/shared.service';
import { ReportService } from 'src/app/reports/services/report.service';

@Component({
  selector: 'app-catchment-model',
  templateUrl: './catchment-model.component.html',
  styleUrls: ['./catchment-model.component.scss']
})
export class CatchmentModelComponent {

  catchmentData: any = null;
  constructor(
    private dialogRef: MatDialogRef<CatchmentModelComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private reportService: ReportService,
    private sharedService: SharedService,
    private alertService : AlertService
  ){
    this.catchmentData = data?.data;
  }

  
  closePopup(type: boolean){
    this.dialogRef?.close(type);
  }

  updateCatchment(){
    let payload = {
      filter_type: "filter",
      filter_data: this.catchmentData.data,
      filter_name: this.catchmentData.name
    }
    this.reportService.updateSavedFilter(payload, this.catchmentData).subscribe({
      next: (res: any) => {
        if (res && res?.status == 200) {
          this.alertService.success(res.message)
          this.dialogRef?.close(false);
        } else {
          this.alertService.warn(res.message)
        }
      },
      error: (err: any) => {
        this.alertService.error(err?.error?.message)
      }
    });
  }

  removeCatchment(){
    this.reportService.deleteFilter(this.catchmentData.id).subscribe({
      next: (res:any) => {
        if(res && res.status == 200) {
          this.alertService.success(res.message);
          this.sharedService.savedFilter = this.sharedService.savedFilter.filter((id: any) => id != this.catchmentData.id.id);
          this.dialogRef?.close(false);
          if(this.reportService.savedCatchmentList.length === 1){
            this.reportService.loadCatchementDataapi$.next(true)
          }
        }else{
          this.alertService.warn(res.message);
        }
      },
      error: (err: any) => {
        this.alertService.error(err?.error?.message)
        this.dialogRef?.close(false);
       }
    });
  }

  checTextValue(value: any){
      return value.trim();
  }

}
