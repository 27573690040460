<div class="report-container">

    <ng-container *ngIf="!showProjectDetails && !showPropertyDetails">

        <app-report-sub-menu [menuList]="overViewMenu"
            (menuChangeValue)="menuChangeValue($event)"></app-report-sub-menu>
            <ng-container *ngIf="houseVillaOverviewLoading">
                <app-skeleton-loader [reportType]="chartView.overView"
                    ></app-skeleton-loader>
            </ng-container>
        <div class="report-tabsection"  id="{{ houseVillasMenuEnum.overview}}"
            [ngClass]="{ 'scroll': currentScroll === houseVillasMenuEnum.overview}">
            <div class="report-overview" *ngIf="!houseVillaOverviewLoading">
                <app-overview-card
                [card_title]="'reports.totalLandsForProperties'" 
                [total_current]="overViewSummary?.total_land_current"
                [untillDate]="overViewSummary?.untillDate"
                [total_prev]="overViewSummary?.total_land_prev"
                [total_percentage]="overViewSummary?.total_percentage"></app-overview-card>
                <!-- <div class="overview housevilla">
                    <div class="overview-data">
                        <h2>{{"reports.totalLandVillas" | translate}}</h2>
                        <h1>{{ overViewSummary?.total_land_current ? ( overViewSummary?.total_land_current|
                            number:'1.0-1')  : '-'}}</h1>
                        <div class="untill">
                            <span>{{"reports.forYear" | translate}}</span>
                            <span class="directionLtr"> {{ overViewSummary?.untillDate }}</span>
                        </div>


                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}}
                            </span>
                            <span>{{ overViewSummary?.total_land_prev ? ( overViewSummary?.total_land_prev|
                                number:'1.0-1')  : '-'}}</span>

                        </div>
                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.total_percentage == 0">{{
                            overViewSummary?.total_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.total_percentage > 0">+ {{
                                overViewSummary?.total_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.total_percentage < 0">{{
                                overViewSummary?.total_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.total_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.total_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div> -->
                <app-overview-card
                    [card_title]="'reports.averageLandArea'" 
                    [total_current]="overViewSummary?.average_land_current"
                    [untillDate]="overViewSummary?.untillDate"
                    [total_prev]="overViewSummary?.average_land_prev"
                    [total_percentage]="overViewSummary?.average_total_percentage"
                    [uniType]="UnitTypeEnum.sqm"></app-overview-card>
                
                <!-- <div class="overview housevilla">                    
                    
                    <div class="overview-data">
                        <h2>{{"reports.averageLandArea" | translate}} </h2>
                        <h1 class="directionLtr">

                            <ng-container *ngIf="overViewSummary?.average_land_current; else otheroverViewSummary">
                                {{overViewSummary?.average_land_current | number:'1.0-1'}} <sup class="sup-font">sqm</sup>
                            </ng-container>
                            <ng-template #otheroverViewSummary>-</ng-template>
                        </h1>

                        <div class="untill">
                            <span>{{"reports.forYear" | translate}}</span>
                            <span class="directionLtr"> {{ overViewSummary?.untillDate }}</span>
                        </div>

                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}}
                            </span>
                            <span class="directionLtr">{{ overViewSummary?.average_land_prev ?
                                (overViewSummary?.average_land_prev | number:'1.0-1') + ' sqm' : '-' }}</span>

                        </div>

                        <div class="overview-prev">

                          <h5 class="normal-price directionLtr" *ngIf="overViewSummary?.average_total_percentage == 0">
                            {{
                            overViewSummary?.average_total_percentage }} %</h5>
                            <h5 class="high-price directionLtr" *ngIf="overViewSummary?.average_total_percentage > 0">+
                                {{
                                overViewSummary?.average_total_percentage }} %</h5>
                            <h5 class="low-price directionLtr" *ngIf="overViewSummary?.average_total_percentage < 0">{{
                                overViewSummary?.average_total_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.average_total_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.average_total_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div> -->
                <app-overview-card
                    [card_title]="'reports.landDevelopmentRate'" 
                    [total_current]="overViewSummary?.land_development_current"
                    [untillDate]="overViewSummary?.untillDate"
                    [total_prev]="overViewSummary?.land_development_prev"
                    [total_percentage]="overViewSummary?.landDevlopment_total_percentage"
                    [uniType]="UnitTypeEnum.percentage"></app-overview-card>
                <!-- <div class="overview housevilla">
                    <div class="overview-data">
                        <h2>{{"reports.landDevelopmentRate" | translate}}</h2>
                        <h1 class="directionLtr">
                            <ng-container *ngIf="overViewSummary?.land_development_current; else otherLandDevelopmentCurrent">
                                {{overViewSummary?.land_development_current | number:'1.0-1'}} <sup class="sup-font">%</sup>
                            </ng-container>
                            <ng-template #otherLandDevelopmentCurrent>-</ng-template>
                        </h1>

                        <div class="untill">
                            <span>{{"reports.forYear" | translate}}</span>
                            <span class="directionLtr">{{ overViewSummary?.untillDate }}</span>
                        </div>

                    </div>
                    <div class="overview-v">
                        <div class="overview-prev-year">
                            <span>{{"reports.was" | translate}}
                            </span>
                            <span class="directionLtr">{{ overViewSummary?.land_development_prev ?
                                (overViewSummary?.land_development_prev | number:'1.0-1') + '%' : '-' }}</span>
                        </div>


                        <div class="overview-prev">
                          <h5 class="normal-price directionLtr"
                          *ngIf="overViewSummary?.landDevlopment_total_percentage == 0"> {{
                          overViewSummary?.landDevlopment_total_percentage }} %</h5>
                            <h5 class="high-price directionLtr"
                                *ngIf="overViewSummary?.landDevlopment_total_percentage > 0">+ {{
                                overViewSummary?.landDevlopment_total_percentage }} %</h5>
                            <h5 class="low-price directionLtr"
                                *ngIf="overViewSummary?.landDevlopment_total_percentage < 0">
                                {{
                                overViewSummary?.landDevlopment_total_percentage }} %</h5>
                            <span>{{"reports.fromLastYear" | translate}}</span>
                            <mat-icon *ngIf="overViewSummary?.landDevlopment_total_percentage > 0" class="high-price">
                                call_made</mat-icon>
                            <mat-icon *ngIf="overViewSummary?.landDevlopment_total_percentage < 0" class="low-price">
                                call_received</mat-icon>
                        </div>
                    </div>
                </div> -->
            </div>
        </div>

        <div class="report-model">
            <div class="report-model-header">
                <div class="info">
                    <h3>{{"reports.theSupplyOfResidentialLands" | translate}} {{houseVillaChart.length ? houseVillaChart.length
                        :''}} {{'reports.years'| translate}}</h3>
                </div>

            </div>
            <ng-container *ngIf="houseVillaChartLoading">
                <app-skeleton-loader [reportType]="chartView.barChart"
                ></app-skeleton-loader>
            </ng-container>
            <ng-container *ngIf="!houseVillaChartLoading">
                <app-report-charts
                [data]="houseVillaChart"
                [chartType]="chartView.barChart"
                [name]="'houseVillasbarChart'"
            ></app-report-charts>

            </ng-container>




        </div>


         <!-- Independents villas -->
        <div  class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === houseVillasMenuEnum.independent_villas}"
            id="{{ houseVillasMenuEnum.independent_villas }}">

            <app-report-year-dropdown
            [reportType]="'independent'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>

            <div class="report-model" >

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{"reports.independentProperties" | translate}}</h3>
                        <!-- <span class="totalTransaction">({{ independentVillasCount }} {{"reports.total_villas" |
                            translate}})</span> -->
                    </div>

                </div>
                <!-- <ng-container *ngIf="independentVillasdataLoading">
                    <app-skeleton-loader [reportType]="chartView.barChart"></app-skeleton-loader>
                </ng-container> -->
                    <app-report-datatable [showLoader]="!independentVillasdataLoading"
                    [tableColumns]="independentVillasColumns"
                    [dataLength]="independentVillasCount"
                    [tableData]="independentVillasdata"
                    (pageEvent)="onPageEvent($event, houseVillasMenuEnum.independent_villas)"
                    (sort)="sortData($event, houseVillasMenuEnum.independent_villas)"
                    [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'independent', 'a')" [downloadLoading]="downloadLoading['a']"
                    [showEstaterText]="true"
                >
                </app-report-datatable>


            </div>
        </div>

        <!-- Governmental Villas -->
        <div  class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === houseVillasMenuEnum.governmental_villas}"
            id="{{ houseVillasMenuEnum.governmental_villas }}">

            <app-report-year-dropdown
            [reportType]="'governor'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>


            <div class="report-model">

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{"reports.governmentalProperties" | translate}}</h3>
                        <!-- <span class="totalTransaction">({{ governmentalVillasCount }} {{"reports.total_villas" |
                            translate}})</span> -->
                    </div>

                </div>
                <!-- <ng-container *ngIf="governmentalVillasdataLoading">
                    <app-skeleton-loader
                    ></app-skeleton-loader>
                </ng-container> -->
                    <app-report-datatable [showLoader]="!governmentalVillasdataLoading"
                    [tableColumns]="governmentalVillasColumns"
                    [dataLength]="governmentalVillasCount"
                    [tableData]="governmentalVillasdata"
                    (pageEvent)="onPageEvent($event, houseVillasMenuEnum.governmental_villas)"
                    (sort)="sortData($event, houseVillasMenuEnum.governmental_villas)"
                    [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'govt', 'b')" [downloadLoading]="downloadLoading['b']"
                    [showEstaterText]="true">
                </app-report-datatable>


            </div>
        </div>

        <!-- Projects villas -->
        <div  class="report-tabsection" [ngClass]="{ 'scroll': currentScroll === houseVillasMenuEnum.projects}"
            id="{{ houseVillasMenuEnum.projects}}">

            <app-report-year-dropdown
            [reportType]="'projects'"
            (selectedYear)="selectedYear($event)"
        ></app-report-year-dropdown>

            <div class="report-model" >

                <div class="report-model-header">
                    <div class="info">
                        <h3>{{"reports.projects" | translate}} </h3>
                        <span class="totalTransaction">({{ projectsCount }} {{"reports.totalProjects" |
                            translate}})</span>
                    </div>


                </div>
                <!-- <ng-container *ngIf="projectsdataLoading">
                    <app-skeleton-loader
                    ></app-skeleton-loader>
                </ng-container> -->


                    <app-report-datatable [showLoader]="!projectsdataLoading"
                    [tableColumns]="projectsColumns"
                    [dataLength]="projectsCount"
                    [tableData]="projectsdata"
                    (pageEvent)="onPageEvent($event, houseVillasMenuEnum.projects)"
                    (sort)="sortData($event, houseVillasMenuEnum.projects)"
                    [allowExcelDownload]="true"
                    (excelDownload)="downloadExcel($event, 'project', 'c')" [downloadLoading]="downloadLoading['c']"
                    [showEstaterText]="true">
                </app-report-datatable>


            </div>
        </div>
    </ng-container>

<!-- 
    <ng-container *ngIf="showProjectDetails">
        <app-report-housevilla-project-details [propertyInfo]="propertyInfo"></app-report-housevilla-project-details>
    </ng-container> -->

    <!-- <ng-container *ngIf="showPropertyDetails">
        <ng-container *ngIf="multi_story_residential; else elseTemplate">
            <app-report-property-detail [propertyInfo]="propertyInfo"></app-report-property-detail>
        </ng-container>
        <ng-template #elseTemplate>
            <app-report-housevilla-property-details [propertyInfo]="propertyInfo"></app-report-housevilla-property-details>
        </ng-template>
    </ng-container> -->

    <!-- <ng-container *ngIf="showAreaDetails">
        <app-report-housevilla-area-details [propertyInfo]="propertyInfo"></app-report-housevilla-area-details>
    </ng-container> -->
</div>
